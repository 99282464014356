import { makeStyles, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, Button, Grid } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { forEach } from 'lodash';
import { printLabel,getInvoice,cancelOrderByTno } from '../../../actions/postalOutletActions';
import { showAlertSnackbar } from "../../../reducers/uiSlice";

const tableWidth = 1400;

const useStyles = makeStyles((theme) => ({
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    minWidth: tableWidth
  },
  framedButton: {
    color: " #2a3848",
    borderRadius: 4,
    border: "solid 1px #2a3848",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 600,
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  table: {
    width: '100%',
    height: 670,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    fontWeight: 600,
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  cancelButton: {
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    fontWeight: 600,
  },
  buttonText: {
    color: "#1976d2",
    width: 53,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  printLabel: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
    float: 'center'
  },
  infoDipslay: {
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    whiteSpace: "pre-wrap "
  },
  subtitle: {
    color: '#616161',
  }
}));

export default function PostalOutletOrderDisplay(props) {
  const { orderList, currentPage, setCurrentPage, perPage, setPerPage, paging, loading, handleSearch} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('postalOutlet');
  const [orderDetails, openOrderDetails] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [loadingBackdrop, setLoading] = useState(false);
  const [labelURL, setLabelURL] = useState({});
  const [invoiceURL, setInvoiceURL] = useState({});
  const [labels, setLabelOpen] = useState(false);
  const [invoice, setInvoiceOpen] = useState(false);
  const [cancelOrderDialog,openCancelOrder] = useState(false);

  const postalOutletColumns = [
    {
      field: 'order_sn',
      headerName: "Invoice No.",
      width: 200,
    },
    {
      field: 'add_time',
      headerName: "Invoice Time",
      width: 200,

    },
    {
      field: 'tno',
      headerName: "Tracking No.",
      width: 200,
    },
    {
      field: 'package_type',
      headerName: "Package Type",
      width: 170,
      valueGetter: (params) => 'Parcel'
    },
    {
      field: 'city',
      headerName: "Ship To",
      width: 142,
    },
    {
      field: 'package_weight',
      headerName: "Package Weight",
      width: 220,
      valueGetter: (params) => `${params.row.total_weight} ${params.row.weight_uom}`
    },
    {
      field: 'dimensions',
      headerName: "Dimensions",
      width: 220,
      valueGetter: (params) => `${parseFloat(params.row.length).toFixed(2)}${params.row.dimension_uom}/${parseFloat(params.row.width).toFixed(2)}${params.row.dimension_uom}/${parseFloat(params.row.height).toFixed(2)}${params.row.dimension_uom}`
    },
    {
      field: 'total_before_tax',
      headerName: "Freight Fee",
      width: 192,
      valueGetter: (params) => `$${parseFloat(params.row.total_before_tax).toFixed(2)}`
    }, {
      field: 'tax',
      headerName: "Taxes",
      width: 192,
      valueGetter: (params) => `$${parseFloat(params.row.tax).toFixed(2)}`
    },
  ]

  function createOrderRows() {
    let orders = [];
    if (orderList.length > 0) {
      orderList.forEach(forEach((row) => {
          const { order_id, ...rest } = row
          orders.push({ id: order_id, order_id: order_id, ...rest })
        })
      )
    }
    return orders;
  }

  const handleRowClick = (params) => {
    setOrderInfo(params.row);
  }

  function getOrderInvoice() {
    setLoading(true);
    dispatch(getInvoice({ invoiceId: orderInfo.tno, invoiceType: 1, invoiceFormat: 'pdf' }))
      .then((res) => {
        var byteCharacters = Buffer.from(res.data[0].invoiceContent, 'base64');
        var file = new Blob([byteCharacters], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        setInvoiceURL(fileURL);
      }).catch((err) => {
      setLoading(false);
      dispatch(showAlertSnackbar({ type: "error", message: err.message }));
    })

  }

  function getOrderLabel() {
    setLoading(true);
    dispatch(printLabel({ packageId: orderInfo.tno, labelType: 6, labelFormat: 'pdf'}))
      .then((res) => {
        var byteCharacters = Buffer.from(res.data[0].labelContent, 'base64');
        var file = new Blob([byteCharacters], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        setLabelURL(fileURL);
      }).catch((err) => {
      setLoading(false);
      dispatch(showAlertSnackbar({ type: "error", message: err.message  }));
    })
  }

  function cancelOrder(){
    setLoading(true);
    dispatch(cancelOrderByTno(orderInfo.tno))
      .then((res) => {
        setLoading(false);
        openCancelOrder(false);
        dispatch(showAlertSnackbar({ type: "success", message: res.ret_msg }));
        handleSearch();

      }).catch((err) => {
      setLoading(false);
      dispatch(showAlertSnackbar({ type: "error", message: err.message }));
    })
  }

  function isEmpty(obj){
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
  }

  useEffect(() => {
    if (!isEmpty(orderInfo)){
      openOrderDetails(true);
    }
  }, [orderInfo]);

  useEffect(() => {
    if (!isEmpty(labelURL)) {
      setLoading(false);
      setLabelOpen(true);
    }
  }, [labelURL]);

  useEffect(() => {
    if (!isEmpty(invoiceURL)) {
      setLoading(false);
      setInvoiceOpen(true);
    }
  }, [invoiceURL]);

  return (
    <div className={classes.tableArea}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', color: "#577699", fontSize: 18, fontWeight: 600, }}>Order List</div>
      </div>
      <DataGrid
        className={classes.table}
        loading={loading}
        rows={loading ? [] : createOrderRows()}
        columns={postalOutletColumns}
        pagination
        paginationMode="server"
        rowCount={paging.totalCount}
        page={currentPage}
        pageSize={perPage}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
        rowsPerPageOptions={[10, 20, 30]}
        onRowClick={handleRowClick}
      />
      <Dialog id="orders.orderDetails" maxWidth='md' fullWidth={true} open={orderDetails} onClose={() => openOrderDetails(false)}>
        <DialogTitle style={{paddingBottom: 0}}><b>{t('order_detail')}</b></DialogTitle>
        <DialogContent style={{marginBottom: 20}}>
          <div>
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <h4 className={classes.subtitle}>{t('order_info')}</h4>

                  <Grid container spacing={0} className={classes.infoDipslay}>
                    <Grid container item xs={12} spacing={0}>
                      <Grid item xs={3} className={classes.subtitle}>Tracking No:</Grid>
                      <Grid item xs={3}>{orderInfo.tno}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Invoice No:</Grid>
                      <Grid item xs={5}>{orderInfo.order_sn}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Invoice Date:</Grid>
                      <Grid item xs={5}>{orderInfo.add_time?(orderInfo.add_time).split(" ", 1):""}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Invoice Time:</Grid>
                      <Grid item xs={5}>{orderInfo.add_time?(orderInfo.add_time).split(" ")[1]:""}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Freight Fee:</Grid>
                      <Grid item xs={5}>${orderInfo.shipping_fee}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Taxes:</Grid>
                      <Grid item xs={5}>${orderInfo.tax}</Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <h4 className={classes.subtitle}>{t('parcel_info')}</h4>
                  <Grid container spacing={0} className={classes.infoDipslay}>
                    <Grid container item xs={12} spacing={0}>
                      <Grid item xs={3} className={classes.subtitle}>Weight:</Grid>
                      <Grid item xs={3}>{orderInfo.total_weight}kg</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Height:</Grid>
                      <Grid item xs={5}>{orderInfo.height}cm</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Width:</Grid>
                      <Grid item xs={5}>{orderInfo.width}cm</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Length:</Grid>
                      <Grid item xs={5}>{orderInfo.length}cm</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>


            <div>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <h4 className={classes.subtitle}>{t('sender_info')}</h4>
                  <Grid container spacing={0} className={classes.infoDipslay}>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Name:</Grid>
                      <Grid item xs={3}>{orderInfo.start_consignee}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Phone:</Grid>
                      <Grid item xs={5}>{orderInfo.start_mobile}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>E-mail:</Grid>
                      <Grid item xs={5}>{orderInfo.start_email}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Address:</Grid>
                      <Grid item xs={8}>{orderInfo.start_address}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Postal Code:</Grid>
                      <Grid item xs={5}>{orderInfo.start_zipcode}</Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <h4 className={classes.subtitle}>{t('consignee_info')}</h4>
                  <Grid container spacing={0} className={classes.infoDipslay}>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Name:</Grid>
                      <Grid item xs={3}>{orderInfo.consignee}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Phone:</Grid>
                      <Grid item xs={5}>{orderInfo.mobile}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>E-mail:</Grid>
                      <Grid item xs={5}>{orderInfo.email}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Address:</Grid>
                      <Grid item xs={9}>{orderInfo.address}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Province:</Grid>
                      <Grid item xs={5}>{orderInfo.province_code}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Postal Code:</Grid>
                      <Grid item xs={5}>{orderInfo.zipcode}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Buzz#:</Grid>
                      <Grid item xs={5}>{orderInfo.buzz_code}</Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} className={classes.subtitle}>Notes:</Grid>
                      <Grid item xs={5}>{orderInfo.driver_notes}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button id="orders.orderDetails.back" className={classes.buttonText} style={{ marginRight: 24}} autoFocus onClick={() => openOrderDetails(false)}>BACK</Button>
          <Button id="orders.orderDetails.printLabel" className={classes.buttonSystem} style={{ marginRight: 24 ,width:150}} autoFocus onClick={() => { getOrderLabel() }}>PRINT LABEL</Button>
          <Button id="orders.orderDetails.printInvoice" className={classes.buttonSystem} style={{ marginRight: 24,width:150}} autoFocus onClick={() => { getOrderInvoice() }}>PRINT INVOICE</Button>
          <Button id="orders.orderDetails.cancelOrder" className={classes.cancelButton} variant='contained' style={{ marginRight: 24,width:160 }} autoFocus onClick={() => {openOrderDetails(false);openCancelOrder(true);}} color="secondary">CANCEL ORDER</Button>
        </DialogActions>
      </Dialog>
      <Dialog id="orders.printLabel" maxWidth='sm' open={labels} onClose={() => setLabelOpen(false)}>
        <DialogTitle><b>{t('print_label')}</b></DialogTitle>
        <DialogContent>
          <object id="orders.printLabel.label" width="400" height="600" type="application/pdf" data={labelURL}>
            <p>Cannot Render Label, Please click print to view label</p>
          </object>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button id="orders.printLabel.back" className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setLabelOpen(false)}>BACK</Button>
          <Button id="orders.printLabel.printButton" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={(e) => { window.open(labelURL, '_blank'); getOrderInvoice(); }}>PRINT</Button>
        </DialogActions>
      </Dialog>
      <Dialog id="orders.printInvoice" maxWidth='sm' open={invoice} onClose={() => setInvoiceOpen(false)}>
        <DialogTitle><b>{t('print_invoice')}</b></DialogTitle>
        <DialogContent>
          <object id="orders.printInvoice.invoice" width="400" height="600" type="application/pdf" data={invoiceURL}>
            <p>Cannot Render Invoice, Please click print to view Invoice</p>
          </object>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button id="orders.printInvoice.back" className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setInvoiceOpen(false)}>BACK</Button>
          <Button id="orders.printInvoice.printButton" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={(e) => { window.open(invoiceURL, '_blank'); setInvoiceOpen(false); }}>PRINT</Button>
        </DialogActions>
      </Dialog>
      <Dialog id="orders.cancelOrder" maxWidth='sm' fullWidth={true} open={cancelOrderDialog} onClose={() => openCancelOrder(false)}>
        <DialogTitle><b>{t('cancel_order')}</b></DialogTitle>
        <DialogContent>
          {t('cancel_order_info')}
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button id="orders.cancelOrder.cancel" className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => openCancelOrder(false)}>CANCEL</Button>
          <Button id="orders.printLabel.yes" className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={(e) => {cancelOrder()}}>YES</Button>
        </DialogActions>
      </Dialog>
      <Backdrop id="loadingBackdrop" sx={{ color: '#fff', zIndex: 3000 }} open={loadingBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  );
}
