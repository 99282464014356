import { useRef, useEffect, useContext } from "react"
import { AlertContext } from "../components/UniAlert/context";
import {TokenContext} from "../route/protectedRoute";
import {CustomerContext} from "../components/PrmAppBar/CustomerContext";

export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export function useAlert(){
    return useContext(AlertContext)
}

export function useToken() {
    return useContext(TokenContext);
}

export function useCustomer() {
    return useContext(CustomerContext);
}