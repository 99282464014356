import React, { useState } from 'react';
import { useDispatch, } from 'react-redux';
import { makeStyles, } from '@material-ui/core/styles';
// import { useToken } from '../../../utilities/hooks';
import { useTranslation } from 'react-i18next';
import {
  Box, Divider, List, ListItem, ListItemText,
  Checkbox, ListItemIcon, Button, TextField,
  Dialog, DialogActions, DialogContent, DialogContentText
} from '@material-ui/core';

import {
  getUserListByRole,
  createNewRole,
  deleteRole,
  updateRole, updateRoleAbilities,
} from '../../../actions/userManagementActions';

const useStyles = makeStyles(
  (theme) => ({

    contents: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1 0 auto',
      height: '85vh',
      backgroundColor: theme.palette.background.default,
    },
    mainSection: {
      padding: 16,
      width: '60%',
      overflow: 'auto',
    },
    sideSection: {
      padding: 16,
      width: '20%',
      overflow: 'auto',
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '8px 0 8px 0',
    },
    inputLabel: {
      // color: '#e1e1e1',
      width: 'auto',
      textAlign: 'right',
      margin: '0 8px 0 0',
    },
    inputField: {
      margin: '0 0 0 8px'
    },
    paper: {
      height: '100%',
      overflow: 'auto',
    },
    denseList: {
      padding: 0,
    },
    listButton: {
      padding: '0 7px 0 7px',
    },
    mainForm: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    bottomButtonBar: {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto 0 0 0',
    },
    deleteDialog: {
      display: 'flex',
      alignItems: 'center'
    }
  })
);

// const SYSTEM_ADMINISTRATOR = 'System Administrator';

export default function Roles(props) {
  const {
    rolesList, abilitiesList, usersList,
    fetchData, setLoading, showSnack,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  // const tokenContents = useToken();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [selectedRoleAbilities, setSelectedRoleAbilities] = useState([]);
  const [selectedRoleUsers, setSelectedRoleUsers] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  // const userIsSystemAdministrator = tokenContents.roles.includes(SYSTEM_ADMINISTRATOR);
  const {t} = useTranslation('userManagement');

  // useEffect(() => {
  //   handleRoleListClick(rolesList[selectedIndex], 0);
  // }, []);

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  }
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  }

  const handleRoleListClick = async (role, index) => {
    setSelectedIndex(index);
    setSelectedRoleName(role.name || '');
    setSelectedRoleAbilities(role.abilities.map(
      (ability) => (ability.ability_id)
    ));

    try {
      setLoading(true);
      const res = await dispatch(getUserListByRole(role.id));
      setSelectedRoleUsers(Object.entries(res).map(
        (user) => (user[1].id)
      ));
    } catch (error) {
      showSnack('error')("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const handleAbilityListClick = (id) => {
    if (selectedRoleAbilities.indexOf(id) === -1) {
      setSelectedRoleAbilities([...selectedRoleAbilities, id]);
    } else {
      setSelectedRoleAbilities(
        selectedRoleAbilities.filter((ability) => (ability !== id))
      );
    }
  };

  const handleCreateRoleClick = async (name) => {
    try {
      setLoading(true);

      await Promise.all([
        dispatch(createNewRole(name)),
      ]);

      showSnack('success')("Create new role success!");
    } catch (error) {
      showSnack('error')("Create new role failed.");
    } finally {
      await fetchData();
      setLoading(false);
    }
  };

  const handleSaveRoleClick = async (id, name) => {
    try {
      setLoading(true);

      await Promise.all([
        dispatch(updateRole(id, name)),
        dispatch(updateRoleAbilities(id, selectedRoleAbilities.toString())),
      ]);

      showSnack('success')(t('save_role_setting_success'));
    } catch (error) {
      showSnack('error')(t('save_role_setting_failed'));
    } finally {
      await fetchData();
      setLoading(false);
    }
  };

  const handleDeleteRole = async (roleId) => {
    try {
      setLoading(true);

      await Promise.all([
        dispatch(deleteRole(roleId)),
      ]);

      showSnack('success')(t('delete_role_success'));
    } catch (error) {
      showSnack('error')(t('delete_role_failed'));
    } finally {
      await fetchData();
      setDeleteOpen(false);
      setLoading(false);
    }
  };

  return (<>
    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      classes={classes.deleteDialog}
    >
      <DialogContent>
        <DialogContentText>
          {t('sure_to_delete_role')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={'cancel-role-delete'}
          onClick={handleDeleteClose}
          color="primary"
        >
          {t('cancel')}
        </Button>
        <Button
          id={'confirm-role-delete'}
          onClick={() => handleDeleteRole(rolesList[selectedIndex].id)}
          color="primary"
          autoFocus
        >
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
    <Box className={classes.contents}>

      <Box className={classes.sideSection}>
        <List className={classes.denseList} aria-label="role list">
          {
            rolesList &&
            rolesList.map(
              (role, index) => (
                <ListItem
                  button
                  className={classes.listButton}
                  key={role.id}
                  selected={selectedIndex === index}
                  onClick={() => { handleRoleListClick(role, index) }}
                >
                  <ListItemText
                    id={'role-select-'+role.id}
                    primary={role.name}
                  />
                </ListItem>
              )
            )
          }
        </List>
      </Box>

      <Divider orientation='vertical' flexItem />

      <Box className={classes.mainSection}>

        <div className={classes.mainForm}>

          <div className={classes.inputGroup} style={{ marginTop: 0 }}>
            <div className={classes.inputLabel}>
              {t('name')}
            </div>
            <TextField
              className={classes.inputField}
              id={'role-name-text'}
              variant="outlined"
              size="small"
              value={selectedRoleName}
              onChange={(e) => { setSelectedRoleName(e.target.value) }}
              fullWidth
            />
          </div>

          <div style={{ height: 'auto', overflow: 'auto', margin: '0 0 8px 0' }}>
            <List className={classes.denseList} aria-label="ability list">
              {
                abilitiesList.filter(
                  (ability) => (ability.type === 'ui')
                ).map(
                  (ability) => (
                    <ListItem
                      className={classes.denseList}
                      key={ability.id}
                    >
                      <ListItemIcon>
                        <Checkbox
                          id={'ability-checkbox-'+ability.id}
                          checked={selectedRoleAbilities.indexOf(ability.id) !== -1}
                          onClick={() => { handleAbilityListClick(ability.id) }}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${ability.id} - ${ability.title}`}
                      />
                    </ListItem>
                  )
                )
              }
            </List>
          </div>

          <div className={classes.bottomButtonBar}>
            <Button
              style={{ margin: '0 auto 0 0' }}
              id={'create-role-button'}
              variant='contained'
              color='primary'
              onClick={() => (handleCreateRoleClick(selectedRoleName))}
            >
              {t('create_role')}
            </Button>

            <Button
              style={{ margin: '0 4px 0 auto' }}
              id={'delete-role-button'}
              variant='contained'
              color='secondary'
              // disabled={!userIsSystemAdministrator}
              onClick={handleDeleteOpen}
            >
              {t('delete_role')}
            </Button>

            <Button
              style={{ margin: '0 0 0 4px', color: '#616161' }}
              id={'save-role-changes'}
              variant='contained'
              onClick={() => { handleSaveRoleClick(rolesList[selectedIndex].id, selectedRoleName) }}
            >
              {t('save_change')}
            </Button>
          </div>
        </div>
      </Box>

      <Divider orientation='vertical' flexItem />

      <Box className={classes.sideSection}>
        <List className={classes.denseList} aria-label="user list">
          {
            usersList.filter(
              (user) => (selectedRoleUsers.indexOf(user.id) !== -1)
            ).map(
              (user) => (
                <ListItem
                  className={classes.denseList}
                  key={user.id}
                >
                  <ListItemText
                    primary={user.username}
                  />
                </ListItem>
              )
            )
          }
        </List>
      </Box>

    </Box>

  </>);

}