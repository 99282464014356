import Axios from "axios";
import {userLogout} from "../actions/userActions";
import {ErrorTypes} from "../constants/general";
import NetworkError from "../models/error";
import {getUserPreference} from "../utilities/common";

console.log(process)
console.log(process.env)
console.log(process.env.REACT_APP_SJ_SERVER_URL)

// shangjia server dev
export const createOrderServerInstanceDev = Axios.create({
  baseURL: process.env.REACT_APP_SJ_SERVER_URL,
  timeout: 60000,
  headers: {common: {
    'Authorization': `Bearer ${getUserPreference().authToken}`,
    "Accept": "application/json",
    "Content-Type": "application/json"
  }}
});

createOrderServerInstanceDev.interceptors.response.use(response=>{
  //2xx
  if (response.data.status === "SUCCESS" || response.data.data.succ === 1){
    if (response.data.data) {
      if(response.data.data.original) {
        if (response.data.data.original.error_code === "invalid_access_token") {
          console.log("Login Token Expired! " + getUserPreference().authToken);
          userLogout();
        }
      } else {
        return response
      }
    } else {
      return response
    }
  } else {
    throw response.data
  }
}, err => {
  //outside 2xx
  let error
  if(!err.response){
    throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
  }
  if(err.response.status === 401){
    console.log("Login Token Expired! " + getUserPreference().authToken)
    userLogout()
  }
  if(typeof err.response.data === 'object'){
    error = err.response.data;
  } else {
    error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
  }

  throw error
})

// shangjia server dev
export const shangjiaServerInstanceDev = Axios.create({
  baseURL: process.env.REACT_APP_SJ_SERVER_URL,
  timeout: 60000,
  headers: {common: {
    'Authorization': `Bearer ${getUserPreference().authToken}`,
    "Accept": "application/json",
    "Content-Type": "application/json"
  }}
});

shangjiaServerInstanceDev.interceptors.response.use(response=>{
  //2xx
  if (response.data.status === "SUCCESS"){
    if ((response.data.data && response.data.data.original && response.data.data.original.error_code)) {
      if (response.data.data.original.error_code === "invalid_access_token") {
        console.log("Login Token Expired! " + getUserPreference().authToken);
        userLogout();
      }
    } else {
      return response
    }
  } else {
    throw new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg)
  }
}, err => {
  //outside 2xx
  let error
  if(!err.response){
    throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
  }
  if(err.response.status === 401){
    console.log("Login Token Expired! " + getUserPreference().authToken)
    userLogout()
  }
  if(typeof err.response.data === 'object'){
    let filePath = err.response.data.file
    let line = err.response.data.line
    error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
  } else {
    error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
  }
  throw error
})

// shangjia server
export const createOrderServerInstance = Axios.create({
  baseURL: process.env.REACT_APP_SJ_SERVER_URL,
  timeout: 60000,
  headers: {common: {
    'Authorization': `Bearer ${getUserPreference().authToken}`,
    "Accept": "application/json",
    "Content-Type": "application/json"
  }}
});

createOrderServerInstance.interceptors.response.use(response=>{
  //2xx
  if (response.data.status === "SUCCESS" || response.data.data.succ === 1){
    if ((response.data.data !== undefined && response.data.data.original !== undefined && response.data.data.original.error_code !== undefined)) {
      if (response.data.data.original.error_code === "invalid_access_token") {
        console.log("Login Token Expired! " + getUserPreference().authToken);
        userLogout();
      }
    } else {
      return response
    }
  } else {
    throw new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg)
  }
}, err => {
  //outside 2xx
  let error
  if(!err.response){
    throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
  }
  if(err.response.status === 401){
    console.log("Login Token Expired! " + getUserPreference().authToken)
    userLogout()
  }
  if(typeof err.response.data === 'object'){
    let filePath = err.response.data.file
    let line = err.response.data.line
    error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
  } else {
    error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
  }
  throw error
})

// shangjia server
export const shangjiaServerInstance = Axios.create({
  baseURL: process.env.REACT_APP_SJ_SERVER_URL,
  timeout: 60000,
  headers: {common: {
    'Authorization': `Bearer ${getUserPreference().authToken}`,
    "Accept": "application/json",
    "Content-Type": "application/json"
  }}
});

shangjiaServerInstance.interceptors.response.use(response=>{
  //2xx
  if (response.data.status === "SUCCESS"){
    if ((response.data.data && response.data.data.original && response.data.data.original.error_code)) {
      if (response.data.data.original.error_code === "invalid_access_token") {
        console.log("Login Token Expired! " + getUserPreference().authToken);
        userLogout();
      }
    } else {
      return response
    }
  } else { // error
    throw new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg)
  }
}, err => {
  //outside 2xx
  let error
  if(!err.response){
    throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
  }
  if(err.response.status === 401){
    console.log("Login Token Expired! " + getUserPreference().authToken)
    userLogout()
  }
  if(typeof err.response.data === 'object'){
    let filePath = err.response.data.file
    let line = err.response.data.line
    error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
  } else {
    error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
  }
  throw error
})

// map server
export const adminServerInstance = Axios.create({
  baseURL: process.env.REACT_APP_MAP_SERVER_URL,
  timeout: 60000,
  headers: {common: {
    'Authorization': `Bearer ${getUserPreference().authToken}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, origin, content-type, accept",
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    "Accept": "application/json",
    "Content-Type": "application/json"
  }}
});

adminServerInstance.interceptors.response.use(response=>{
  //2xx
  if (response.data.status === "SUCCESS" || response.data.data.succ === 1){
    if ((response.data.data !== undefined && response.data.data.original !== undefined && response.data.data.original.error_code !== undefined)) {
      if (response.data.data.original.error_code === "invalid_access_token") {
        console.log("Login Token Expired! " + getUserPreference().authToken);
        userLogout();
      }
    } else {
      return response
    }
  } else {
    throw new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg)
  }
}, err => {
  //outside 2xx
  let error
  if(!err.response){
    throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
  }
  if(err.response.status === 401){
    console.log("Login Token Expired! " + getUserPreference().authToken)
    userLogout()
  }
  if(typeof err.response.data === 'object'){
    let filePath = err.response.data.file
    let line = err.response.data.line
    error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
  } else {
    error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
  }

  throw error
})

// app server
export const appMainServerInstance = Axios.create({
  baseURL: process.env.REACT_APP_MAIN_SERVER_URL,
  timeout: 60000,
  headers: {common: {
    'Authorization': `Bearer ${getUserPreference().authToken}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, origin, content-type, accept",
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    "Accept": "application/json",
    "Content-Type": "application/json"
  }}
});

appMainServerInstance.interceptors.response.use(response=>{
  //2xx
  if (response.data.status === "SUCCESS" || response.data.data.succ === 1){
    if ((response.data.data !== undefined && response.data.data.original !== undefined && response.data.data.original.error_code !== undefined)) {
      if (response.data.data.original.error_code === "invalid_access_token") {
        console.log("Login Token Expired! " + getUserPreference().authToken);
        userLogout();
      }
    } else {
      return response
    }
  } else {
    throw new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg)
  }
}, err => {
  //outside 2xx
  let error
  if(err.response.status === 401){
    console.log("Login Token Expired! " + getUserPreference().authToken)
    userLogout()
  }
  if(typeof err.response.data === 'object'){
    let filePath = err.response.data.file
    let line = err.response.data.line
    error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
  } else {
    error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for mre info" , err.response.data)
  }

  throw error
})


//for settlement
export const UniDWServerInstance = Axios.create({
  baseURL: process.env.REACT_APP_UNI_DW_SERVER_URL,
  timeout: 60000,
  headers: {common: {
    'Authorization': `Bearer ${getUserPreference().authToken}`,
    "Accept": "application/json",
    "Content-Type": "application/json"
  }}
});

UniDWServerInstance.interceptors.response.use(response=>{
  //2xx
  if (response.data.status === "SUCCESS"){
    if ((response.data.data && response.data.data.original && response.data.data.original.error_code)) {
      if (response.data.data.original.error_code === "invalid_access_token") {
        console.log("Login Token Expired! " + getUserPreference().authToken);
        userLogout();
      }
    } else {
      return response
    }
  } else { // error
    throw new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg,response.data.data)
  }
}, err => {
  //outside 2xx
  let error
  if(!err.response){
    throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
  }
  if(err.response.status === 401){
    console.log("Login Token Expired! " + getUserPreference().authToken)
    userLogout()
  }
  if(typeof err.response.data === 'object'){
    let filePath = err.response.data.file
    let line = err.response.data.line
    error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
  } else {
    error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
  }
  throw error
})
