import { Button, Dialog, DialogTitle, DialogActions, DialogContent, IconButton, makeStyles, Grid, CircularProgress } from '@material-ui/core';

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ClearRounded } from '@material-ui/icons';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteAdjustFile, downloadAdjustFile, downloadPreauditFile, downloadPriceCardFile, searchSettlementProcedures, downloadAdjustTemplate, uploadAdjustFile, downloadQCPostAuditFile, qcApproval, getInvoice, resetSettlementStatus } from '../../../actions/billingManangementActions';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import DragDropFileUpload from '../../DragDropFileUpload'
import DocumentDetailDisplay from '../../DocumentDetailDisplay'
import FullscreenLoading from "../../FullscreenLoading";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyle = makeStyles((theme) => ({
  subtitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '27px',
    letterSpacing: '0.4px',
    paddingRight: '24px',
    paddingLeft: '24PX',
    marginBottom: '20px'
  },
  infoTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#616161',
  },
  infoValue: {
    fontSize: '14px'
  },
  dialogTitle: {
    fontSize: '18px',
    marginBottom: '15px',
    paddingBottom: 0

  },
  dialogContent: {
    paddingRight: '24px',
    paddingLeft: '24PX',
    marginTop: '10px'
  },
  hr: {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  button: {
    fontSize: '14px',
    color: '#2196F3'
  },
  redText: {
    color: '#D32F2F',
  },
}));



function GridItem({ title, value, setDeleteAdjustSuccess }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  function getAction(fileType, filename, billingNum) {
    if (fileType === 'preaudit')
      return downloadPreauditFile({ file_name: filename, billing_num: billingNum });
    if (fileType === 'price_card')
      return downloadPriceCardFile({ file_name: filename });
    if (fileType === 'adjust')
      return downloadAdjustFile({ file_name: filename, billing_num: billingNum });
    if (fileType === 'qcpostaudit')
      return downloadQCPostAuditFile({ file_name: filename, billing_num: billingNum });
    if (fileType === 'invoice')
      return getInvoice({ file_name: filename, billing_num: billingNum });

  }

  async function handleDownload(filename, billingNum, fileType) {
    try {
      setLoading(true);
      const action = getAction(fileType, filename, billingNum);
      if (fileType === 'invoice') {

        await dispatch(action)
          .then((res) => {
            const link = document.createElement('a');
            link.href = res;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(res);
            document.body.removeChild(link);

          })
          .catch((err) => {
            showAlertSnackbar({ message: err.message, type: 'error' })
          })
      } else {
        await dispatch(action);
      }
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteAdjustFile(billingNum) {
    try {
      setLoading(true);
      await dispatch(deleteAdjustFile({ billing_num: billingNum }));
      dispatch(showAlertSnackbar({ message: 'Adjust file deleted', type: 'success' }));
      setDeleteAdjustSuccess(true);
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container item xs={12} spacing={0} style={{ marginBottom: '14px' }}>
      <Grid item xs={5} className={classes.infoTitle}>{title}</Grid>
      <Grid item xs={7} >
        {typeof value === 'string' ? (
          <span className={classes.infoValue}>{value}</span>
        ) : (
          value.text ? (
            <div>
              <Button
                size="small"
                className={classes.button}
                endIcon={<SaveAltIcon />}
                style={{
                  textAlign: 'center',
                  fontSize: '12px',
                  padding: 0,
                  textTransform: 'none',
                }}
                disabled={loading}
                onClick={() => handleDownload(value.text, value.billingNum, value.type)}
              >
                <span className={classes.infoValue}>{value.text}</span>
              </Button>
              {value.type === 'adjust' && (
                <IconButton
                  className={classes.button}
                  size="small"
                  aria-label="delete"
                  style={{
                    marginLeft: '30px'
                  }}
                  onClick={() => handleDeleteAdjustFile(value.billingNum)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>


          ) : null // Render nothing when value.text is null
        )}
      </Grid>
    </Grid>
  );
}

function BillingManagementSettlementDetail(props) {
  const dispatch = useDispatch();
  const classes = useStyle();

  const { rowData, setDialogOpen, dialog, status, search } = props;

  const [loading, setLoading] = useState(false);
  const [preauditFile, setPreauditFile] = useState('');
  const [priceCardFile, setPriceCardFile] = useState('');
  const [preauditAnalysisFile, setPreauditAnalysisFile] = useState('');
  const [qcAdjustFile, setQcAdjustFile] = useState('');
  const [qcPostAuditFile, setQcPostAuditFile] = useState('');
  const [deleteAdjustSuccess, setDeleteAdjustSuccess] = useState(false);
  const [uploadAdjustment, setUploadOpen] = useState(false);
  const [uploadFailed, setUploadFailedOpen] = useState(false);
  const [failedMsg, setFailedMsg] = useState([]);
  const [qcApprovalClick, setQcApprovalClick] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [operator, setOperator] = useState('');
  const [resetOpen, setResetOpen] = useState(false);
  const [resetCompleted, setResetCompleted] = useState(false);

  const billingNum = rowData.billing_num;

  useEffect(() => {
    if (dialog || deleteAdjustSuccess || currentStatus || qcApprovalClick || resetCompleted) {
      setLoading(true);
      dispatch(searchSettlementProcedures({ billing_num: billingNum }))
        .then((res) => {
          if (res && res.length > 0) {
            const settlement = res[0];
            const foundStatus = status.find(item => item.code === settlement['settlement_status_code']);
            setOperator(settlement['operator_username'] ?? '')
            setCurrentStatus(foundStatus ? foundStatus.name : "Unknown");
            setPreauditFile(settlement['preaudit_file'] ?? '');
            setPriceCardFile(settlement['price_card_file'] ?? '');
            setPreauditAnalysisFile(settlement['preaudit_analysis_file'] ?? '');
            setQcAdjustFile(settlement['qc_adjust_file'] ?? '');
            setQcPostAuditFile(settlement['qc_postaudit_file'] ?? '');
          }
        })
        .catch((err) => {
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        })
        .finally(() => {
          setLoading(false);
          setResetCompleted(false);
        });
    } else {
      setPreauditFile('');
      setPriceCardFile('');
      setPreauditAnalysisFile('');
      setQcAdjustFile('');
      setQcPostAuditFile('');
    }
  }, [dialog, status, billingNum, deleteAdjustSuccess, currentStatus, qcApprovalClick, resetCompleted, dispatch]);

  let basicInfoData = [
    { title: 'Billing ID:', value: rowData['billing_num'] ?? '' },
    { title: 'Billing Periods:', value: rowData['billing_start'] ?? '' },
    { title: 'Invoice Date:', value: rowData['invoice_ts'] ?? '' },
    { title: 'Invoice Number:', value: rowData['invoice_number'] ?? '' },
    { title: 'Operator:', value: operator },
  ];
  const auditContentsData = [
    { title: 'Price Card Version:', value: { text: priceCardFile, type: 'price_card' } },
    { title: 'Statement Quality:', value: { text: preauditAnalysisFile, type: 'preaudit', billingNum: rowData['billing_num'] } },
    { title: 'Adjustment File:', value: { text: qcAdjustFile, type: 'adjust', billingNum: rowData['billing_num'] } },
  ];
  const billedContentsData = [
    { title: 'Generated Invoice:', value: { text: 'Invoice_'+rowData['invoice_number']+'.pdf', type: 'invoice', billingNum: rowData['billing_num'] } },
  ];

  const isPendingReconciliation = currentStatus === 'Pending for Reconciliation';
  const isBilled = currentStatus === 'Billed';
  const allowRest = currentStatus === 'QC Reconciled' || currentStatus === 'Billed';


  function handleDialogClose() {
    setDialogOpen(false);
    resetDialog();
    search();
  }

  function resetDialog() {
    setPreauditFile('');
    setPriceCardFile('');
    setPreauditAnalysisFile('');
    setQcAdjustFile('');
    setQcPostAuditFile('');
    setDeleteAdjustSuccess(false);
    setCurrentStatus('');
    setQcApprovalClick(false);
  }
  async function downloadTemplate() {
    try {
      setLoading(true);
      await dispatch(downloadAdjustTemplate());
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  async function handleUploadAdjustFile() {
    try {
      setLoading(true);
      setUploadOpen(false);
      setLoading(true);
      let formData = new FormData();
      formData.append('file', qcAdjustFile);
      formData.append('billing_num', billingNum);
      await dispatch(uploadAdjustFile(formData));
      setLoading(false);
      setDialogOpen(true);
    } catch (error) {
      setLoading(false);
      setFailedMsg(error.additional);
      setUploadFailedOpen(true);
    }
  }

  async function handleQcReconcile() {
    try {
      await dispatch(qcApproval({ billing_num: billingNum }));
      dispatch(showAlertSnackbar({ message: 'QC arrpoval start processing.', type: 'success' }));
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setQcApprovalClick(true);
    }
  }

  async function handleResetStatus() {
    try {
      await dispatch(resetSettlementStatus({ billing_num: billingNum }));
      dispatch(showAlertSnackbar({ message: 'Status reset success', type: 'success' }));
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
      setResetOpen(false);
      setResetCompleted(true);
    }
  }

  return (
    <div>
      <div >
        <FullscreenLoading open={loading} />
        <Dialog PaperProps={{ style: { width: '600px' } }} open={dialog} onClose={handleDialogClose}>

          <DialogTitle className={classes.dialogTitle}>
            <div style={{ position: 'relative' }}>
              <span>Billing Statement Detail</span>
              <IconButton
                className={classes.button}
                style={{ position: 'absolute', top: 0, right: 0, bottom: 5 }}
                onClick={handleDialogClose}>
                <ClearRounded />
              </IconButton>
            </div>
          </DialogTitle>

          {loading ? (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000, // Ensure the loading indicator is on top of other elements
            }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <DialogContent style={{ padding: 0 }}>
                <span className={classes.subtitle}>
                  Billing Status: <span className={isPendingReconciliation ? classes.redText : ''}>{currentStatus}</span>
                  {allowRest && (
                    <Button variant='outlined' size='small' style={{marginLeft: '15px', color: 'rgba(211, 47, 47)', borderColor: 'rgba(211, 47, 47, 0.5)'}} onClick={() => { setResetOpen(true); }}>
                      RESET STATUS
                  </Button>
                  )}
                  {resetOpen && (
                    <Dialog open={resetOpen}>
                      <DialogTitle>
                        <span style={{color:'rgba(211, 47, 47, 1)', fontSize: '20px', fontWeight: '500'}}>Reset Status?</span>
                      </DialogTitle>
                      <DialogContent style={{ padding: 0 }}>
                        <div style={{paddingLeft: '24px', paddingRight: '24px'}}>
                          <div style={{ fontSize: '16px', color: 'rgba(211, 47, 47, 1)', lineHeight: '26.56px', marginBottom:'20px'}}>
                            Only authorized administrators can reset the status.
                          </div>
                          <div style={{fontSize: '15px', fontWeight: '400', lineHeight: '22.5px', marginBottom:'15px'}}>
                            Are you sure you want to reset the billing status to "Pending for reconciliation" and delete all related invoice information? The billing statement will remain in its latest version.
                          </div>
                        </div>
                        <hr className={classes.hr} />
                      </DialogContent>

                      <DialogActions style={{ marginBottom: '30px', marginRight: '20px'}}>
                        <Button className={classes.button} style={{fontWeight: '500'}} autoFocus onClick={() => { setResetOpen(false)}}>
                          Cancel
                        </Button>
                        <Button className={classes.button} style={{fontWeight: '500'}} onClick={handleResetStatus}>
                          YES
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </span>
                <hr className={classes.hr} />
                <div style={{ paddingTop: '18px' }}>
                  <span className={classes.subtitle}>Basic Information</span>
                  <div style={{ display: 'none' }}>
                    {
                      isPendingReconciliation ?
                        basicInfoData.push({ title: 'Billing Statement:', value: { text: preauditFile, type: 'preaudit', billingNum: rowData['billing_num'] } })
                        :
                        basicInfoData.push({ title: 'Billing Statement:', value: { text: qcPostAuditFile, type: 'qcpostaudit', billingNum: rowData['billing_num'] } })

                    }
                  </div>
                  <Grid container spacing={0} className={classes.dialogContent}>
                    {basicInfoData.map((item, index) => (
                      <GridItem key={index} title={item.title} value={item.value} />
                    ))}
                  </Grid>
                </div>

                {
                  isPendingReconciliation &&
                  <div style={{ backgroundColor: '#EDF6FF' }}>
                    <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
                    <div style={{ paddingTop: '18px' }}>
                      <span className={classes.subtitle}>Audit Contents</span>

                      <Grid container spacing={0} className={classes.dialogContent}>
                        {auditContentsData.map((item, index) => (
                          <GridItem key={index} title={item.title} value={item.value} setDeleteAdjustSuccess={setDeleteAdjustSuccess} />
                        ))}
                      </Grid>
                      <div className={classes.dialogContent}>
                        Upload adjustment file if you want to make any changes.
                        <div><Button variant="outlined" size="small" className={classes.button} style={{ borderColor: "#2196F3", marginTop: '20px', marginBottom: '20px' }} onClick={() => { setDialogOpen(false); resetDialog(); setUploadOpen(true) }}>UPLOAD ADJUSTMENT FILE</Button></div>
                      </div>
                    </div>

                    <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />

                  </div>
                }

                {
                  isBilled &&
                  <div>
                    <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
                    <div style={{ paddingTop: '18px' }}>
                      <span className={classes.subtitle}>Invoice</span>

                      <Grid container spacing={0} className={classes.dialogContent}>
                        {billedContentsData.map((item, index) => (
                          <GridItem key={index} title={item.title} value={item.value} />
                        ))}
                      </Grid>

                    </div>

                    <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />

                  </div>
                }

              </DialogContent>
              <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
                {
                  isPendingReconciliation ?
                    <div>
                      <Button className={classes.button} autoFocus color="primary" onClick={handleDialogClose}>
                        Cancel
                      </Button>
                      <Button variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '500' }} onClick={handleQcReconcile}>
                        Reconcile
                      </Button>
                    </div>
                    :
                    <div>
                      <Button className={classes.button} autoFocus color="primary" onClick={handleDialogClose}>
                        Close
                      </Button>
                    </div>

                }
              </DialogActions>
            </>
          )}
        </Dialog>

        <Dialog maxWidth={"sm"} fullWidth open={uploadAdjustment}>

          <DialogTitle className={classes.dialogTitle}>
            <div style={{ position: 'relative' }}>
              <span>Upload Adjustment File</span>
            </div>
          </DialogTitle>

          <DialogContent>
            <div style={{ position: 'relative' }}>
              <span>Please utilize the provided template for any required modifications</span>
            </div>
            <Button
              size="small"
              className={classes.button}
              endIcon={<SaveAltIcon />}
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '10px 0px',
                textTransform: 'none',
              }}
              onClick={() => downloadTemplate()}
            >
              <span className={classes.infoValue}>{"template.xlsx"}</span>
            </Button>
            <DragDropFileUpload fileType={".xlsx"} onFileUpload={setQcAdjustFile} file={qcAdjustFile} />
            <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
          </DialogContent>
          <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
            <Button className={classes.button} style={{ fontWeight: '00' }} autoFocus color="primary" onClick={() => { setUploadOpen(false); setDialogOpen(true) }}>
              Back
            </Button>
            <Button variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '500' }} onClick={handleUploadAdjustFile}>
              Upload
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth={"sm"} fullWidth open={uploadFailed}>

          <DialogTitle className={classes.dialogTitle}>
            <div style={{ position: 'relative' }}>
              <span>Upload Failed</span>
            </div>
          </DialogTitle>

          <DialogContent>
            <div className={classes.redText} style={{ fontSize: 12, display: 'flex', flexDirection: "row", alignItems: "center", }}>
              <InfoOutlinedIcon style={{ fontSize: 15, marginRight: 12, marginBottom: 3 }} />
              <div>Please correct the rows listed below and upload the adjustment file again</div>
            </div>
            <div style={{ padding: 10 }}> <DocumentDetailDisplay file={qcAdjustFile} state={'Complete'} /> </div>
            {
              failedMsg &&
              Object.keys(failedMsg).map((key) => {
                return <div style={{  paddingTop: 10,paddingBottom: 10 }}>
                  <div style={{ paddingRight: 15, paddingBottom: 10}}>{key}: </div>
                  {failedMsg[key] && failedMsg[key].map((item) => {
                    return <div style={{ paddingRgiht: 15, display: "flex", flexDirection: "row",}}>
                      <div style={{ paddingRight: 15 }}>Row {item.row}: </div>
                      <div>
                        {
                          item.errors &&
                          item.errors.map((item) => {
                            return <div>{item} </div>
                          })
                        }
                      </div>
                    </div>

                  })}
                </div>
              })
            }

            <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
          </DialogContent>
          <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
            <Button className={classes.button} style={{ fontWeight: '00' }} autoFocus color="primary" onClick={() => { setUploadFailedOpen(false); setDialogOpen(true) }}>
              Back
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >

  );
}


export default BillingManagementSettlementDetail;