import {useDispatch} from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DataGrid} from '@material-ui/data-grid';
import React, {useEffect, useState} from 'react';
import {
  FAULTY_PACKAGE_RECORD_INFO,
  FAULTY_PACKAGE_STATUS,
  FaultyPackageActionTaken,
  FaultyPackageClientDecision,
  FaultyPackageIssueType,
  FaultyPackagePackageStatus,
  FaultyPackageRecordStatus
} from '../../../constants/general.js'
import {getFileById, getImagesById, saveFileById, updateRecordInformation} from "../../../actions/faultyPackageActions";
import {useAlert} from "../../../utilities/hooks";
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import * as XLSX from 'xlsx';


const useStyles = makeStyles((theme) => ({
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
  },
  framedButton: {
    color: " #2a3848",
    borderRadius: 4,
    border: "solid 1px #2a3848",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 600,
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  table: {
    height: 670,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 13,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.6)'
    },
    '& .MuiDataGrid-columnHeaderTitle' :{
      fontWeight: 600,
    },
    '& .MuiDataGrid-row': {
      height: 60,
      padding: '0 8px 0'
    },
    '& .ActionRequired': {
      color: '#D32F2FDE',
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    minWidth: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonText: {
    backgroundColor: "white",
    boxShadow: " 0 0px 0px 0 rgba(0, 0, 0, 0)",
    color: "#1976d2",
    minWidth: 60,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)',
      boxShadow: " 0 0px 0px 0 rgba(0, 0, 0, 0)",
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  dialogTextBox: {
    flex: 1,
    margin: "16px 12px",
  },
  deleteButton: {
    backgroundColor: "#D32F2F",
    color: " #fff",
    minWidth: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
  },
  accordionSection: {
    '&.MuiAccordion-root.Mui-disabled': {
      backgroundColor: "#FFFFFF",
    }
  },
  uploadImageButton: {
    color: "#1976d2",
    borderRadius: 4,
    border: "solid 1px #1976d2",
    fontSize: "12px",
    textAlign: "center",
    padding: "10px",
    fontWeight: 600,
    background: "rgba(0, 0, 0, 0)",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
  },
  uploadImageButtonDisabled: {
    color: "#717578",
    opacity: 0.6,
    borderRadius: 4,
    border: "solid 1px #717578",
    fontSize: "12px",
    textAlign: "center",
    padding: "10px",
    fontWeight: 600,
    background: "rgba(0, 0, 0, 0)",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
  },
  imageThumbnail: {
    color: "#00000033",
    fontSize: "12px",
  },
  disabledAccordion: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    opacity: 0.6,
    transition: 'opacity 0.3s ease',
  },
  hiddenInput: {
    display: 'none',
  },
  statistics:{
    color:"#55A6FF",
    fontSize: "12px",
    fontWeight:"600"
  }
}));

const ALERT = {
  SEARCH_SUCCESS : "FETCH DATA SUCCESS",
  SEARCH_FAILED  : "FETCH DATA FAILED",
  UPDATE_SUCCESS : "UPDATE SUCCESS",
  UPDATE_FAILED  : "UPDATE FAILED",
  FILE_SUCCESS   : "SUBMIT FILE SUCCESS",
  FILE_FAILED    : "SUBMIT FILE FAILED",
};

export default function FaultyPackageDisplayGV(props) {
  const {
    records, stats,
    warehouseDropDown, refresh,setloading
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const alertDialog = useAlert();

  const [editRecordOpen, setEditRecordOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [recordInfo, setRecordInfo] = useState(FAULTY_PACKAGE_RECORD_INFO);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null);
  const [imageDetail, setImageDetail] = useState(null);

  // useEffect(() => {
  //   console.log('AAA', recordInfo);
  // }, [recordInfo]);

  const faultyPackageColumns = [
    {
      field: 'created_at',
      headerName: "Creation Time",
      width: 200,
    },
    {
      field: 'warehouse',
      headerName: "Warehouse",
      width: 200,
      valueGetter: (params) => {
        return warehouseDropDown[params.row.warehouse];
      },
    },
    {
      field: 'tracking_number',
      headerName: "Tracking No.",
      width: 200,
    },
    {
      field: 'sender_reference',
      headerName: "Sender Ref",
      width: 200,
    },
    {
      field: 'item_number',
      headerName: "Item No.",
      width: 200,
    },
    {
      field: 'issue_type',
      headerName: "Issue Type",
      width: 200,
      valueGetter: (params) => {
        return FaultyPackageIssueType[params.row.issue_type];
      },
    },
    {
      field: 'package_status',
      headerName: "Package Status",
      width: 200,
      valueGetter: (params) => {
        return FaultyPackagePackageStatus[params.row.package_status];
      },
    },
    {
      field: 'record_status',
      headerName: "Record Status",
      width: 200,
      valueGetter: (params) => {
        if (params.row.record_status === FAULTY_PACKAGE_STATUS.NEW_RECORD_STATUS) {
          return "Action Required";
        } else {
          return FaultyPackageRecordStatus[params.row.record_status];
        }
      },
    },
  ];

  const handleRecordChange = (key) => (e) => {
    const newValue = e.target.value;
    setRecordInfo((prevInfo) => ({
      ...prevInfo,
      [key]: newValue,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleGetImages = async (row) => {
    try {
      const res = await dispatch(getImagesById(row.id));
      setImages(res);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = () => {
    if (file) {
      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(file);
      downloadLink.href = url;
      downloadLink.download = 'faulty_package_record.xlsx';
      downloadLink.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleGetFile = async (row) => {
    try {
      const res = await dispatch(getFileById(row.id));
      // Decode the Base64 string to binary data
      const binaryData = atob(res);

      // Create a Uint8Array from the binary data
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      // Create a Blob object with the correct MIME type
      const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      // Create a File object (optional, but useful if you want to set a file name)
      const file = new File([blob], "faulty_package_record.xlsx", { type: blob.type });

      setFile(file);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenRecord = async (e) => {
    setloading(true);
    setRecordInfo(e.row);
    await handleGetImages(e.row);
    await handleGetFile(e.row);
    setloading(false);
    setEditRecordOpen(true);
  };

  const getAirportCode = (warehouseId) => {
    return warehouseDropDown[warehouseId] || "";
  };

  const handleRecordInfo = async (event) => {
    event.preventDefault();

    // Check if recordInfo.client_decision is null
    if (recordInfo.client_decision === null) {
      alertDialog.addAlertDialog(ALERT.UPDATE_FAILED, "Please enter a decision.");
      alertDialog.showAlert();
      return;
    }

    try {
      if (recordInfo.record_status === FAULTY_PACKAGE_STATUS.CLIENT_REVIEWED) {
        await dispatch(updateRecordInformation(recordInfo.id, {...recordInfo}));
      } else {
        await dispatch(updateRecordInformation(recordInfo.id, {...recordInfo, update_values_only:false}));
      }
      dispatch(showAlertSnackbar({ title: ALERT.UPDATE_SUCCESS, type: 'success' }));
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.UPDATE_FAILED, e.message);
      alertDialog.showAlert();
    }
  };

  const handleFileSubmit = async (event) => {
    event.preventDefault();
    try {
      let formData = new FormData();
      formData.append('file', file);
      await dispatch(saveFileById(recordInfo.id, formData));
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.FILE_FAILED, e.message);
      alertDialog.showAlert();
    } finally {
      setFile(null);
    }
  };

  const handleSubmit = async (event) => {
    setEditRecordOpen(false);
    event.preventDefault();
    try {
      await handleRecordInfo(event);
      if (file) {
        await handleFileSubmit(event);
      }
    } catch (e) {
      console.log(e);
    } finally {
      refresh();
    }
  };

  useEffect(() => {
    if (!editRecordOpen) {
      setFile(null);
      setImages([]);
    }
  }, [editRecordOpen]);

  function downloadAllRecords() {
    var filtered =records;
    filtered = filtered.map(({created_at, warehouse, tracking_number,sender_reference,item_number,issue_type,package_status,record_status, ...rest})=>({"Created At": created_at, "Warehouse":warehouseDropDown[warehouse]," Tracking Number":tracking_number, "Sender Reference": sender_reference,"Item Number":item_number, "Issue Type":FaultyPackageIssueType[issue_type],"Package Status":FaultyPackagePackageStatus[package_status],"Record Status":FaultyPackageRecordStatus[record_status]}));

    filtered.reverse();
    var workbook = XLSX.utils.book_new();
    var workSheet = XLSX.utils.json_to_sheet(filtered);
    workbook.SheetNames.push("Faulty_Package_Records");
    workbook.Sheets["Faulty_Package_Records"] = workSheet;
    XLSX.writeFile(workbook, "Faulty_Package_Records.xlsx");
  }


  return (
    <div className={classes.tableArea}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', color: "#577699", fontSize: 18, fontWeight: 600 }}>Faulty Package List</div>
        <div style = {{ paddingTop:"8px", paddingRight:"10px" }}>
          {
            stats && stats.length ?
            <div>
                <span className={classes.statistics} style={{ color:"red" }}>{stats.reduce((count, obj) => obj.record_status === FAULTY_PACKAGE_STATUS.NEW_RECORD_STATUS? obj.count: count,0)+" Action Required"}</span>
                <span className={classes.statistics} >{"/ "+stats.reduce((count, obj) => obj.record_status === FAULTY_PACKAGE_STATUS.UNI_CONFIRMED? obj.count: count,0)+" UniUni Confirmed"}</span>
            </div>
            :
            <span></span>
          }
        </div>
        <Button className={classes.framedButton} onClick={downloadAllRecords} >DOWNLOAD ALL</Button>
      </div>
      <DataGrid
        className={classes.table}
        rows={records}
        columns={faultyPackageColumns}
        pageSize={pageSize}
        onRowClick={handleOpenRecord}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 30]}
        pagination
        getCellClassName={(params) => {
          if (params.field === 'record_status' && params.value === "Action Required") {
            return 'ActionRequired';
          }
        }}
      />

      {/* gv */}
      <Dialog fullWidth maxWidth='sm' classes={{ paper: classes.dialogPaper }} open={editRecordOpen} onClose={() => setEditRecordOpen(false)}>
        <DialogTitle>Faulty Package Record</DialogTitle>
        <DialogContent style={{ height: 800 }}>
          {/*step 1*/}
          <Accordion elevation={0} className={classes.accordionSection} expanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                {/* row 1 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Record Date"
                    type="date"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="created_date"
                    value={recordInfo.created_at}
                    disabled={true}
                  />
                  <TextField
                    name='warehouse'
                    label="Warehouse"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={warehouseDropDown ? getAirportCode(recordInfo.warehouse) : ""}
                    disabled={true}
                  />
                </div>
                {/* row 2 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Tracking No."
                    name="tracking_number"
                    className={classes.dialogTextBox}
                    variant="outlined"
                    value={recordInfo.tracking_number}
                    InputLabelProps={{shrink: true}}
                    disabled={true}
                  />
                </div>
                {/* row 3 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Sender Ref"
                    name="sender_reference"
                    className={classes.dialogTextBox}
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    value={recordInfo.sender_reference}
                    disabled={true}
                  />
                </div>
                {/* row 4 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Item No."
                    name="item_number"
                    className={classes.dialogTextBox}
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    value={recordInfo.item_number}
                    disabled={true}
                  />
                </div>
                {/* row 5 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Issue Type"
                    name="issue_type"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={FaultyPackageIssueType[recordInfo.issue_type] || ''}
                    disabled={true}
                  />
                </div>
                {/* row 6, images*/}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', marginLeft: '10px' }}>
                  {images?.map((image) => (
                    <img
                      src={`data:image/jpeg;base64,${image}`}
                      alt=''
                      style={{
                        width: 100,
                        height: 100,
                        zIndex: 1000,
                        borderRadius: 4,
                        cursor: 'pointer',
                        margin: '5px',
                      }}
                      onClick={() => setImageDetail(image)}
                    />
                  ))}
                </div>
                {/* row 7 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Notes"
                    multiline
                    minRows={4}
                    defaultValue="Add notes here"
                    className={classes.dialogTextBox}
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    value={recordInfo.uni_notes}
                    disabled={true}
                  />
                </div>
                {/* row 8 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <div className={classes.dialogTextBox}>{`Current Package Status: ${FaultyPackagePackageStatus[recordInfo.package_status]}`}</div>
                </div>

              </div>
            </AccordionDetails>
          </Accordion>

          {/*step 2*/}
          <Accordion elevation={0}  expanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Client Review</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                {/* row 1 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Verify Tracking No."
                    name="verified_tracking_number"
                    className={classes.dialogTextBox}
                    variant="outlined"
                    value={recordInfo.verified_tracking_number}
                    InputLabelProps={{shrink: true}}
                    onChange={handleRecordChange('verified_tracking_number')}
                  />
                </div>
                {/* row 2 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    select
                    label="Decision*"
                    name="client_decision"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={recordInfo.client_decision}
                    onChange={handleRecordChange('client_decision')}
                  >
                    {Object.entries(FaultyPackageClientDecision).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* row 3 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Notes"
                    name="client_notes"
                    multiline
                    minRows={4}
                    placeholder={"Add notes here"}
                    className={classes.dialogTextBox}
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    value={recordInfo.client_notes}
                    onChange={handleRecordChange('client_notes')}
                  />
                </div>
                {/* row 4  file */}
                <div>
                  {/* File Upload Input */}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', marginLeft: '11px' }}>
                    <input
                      hidden
                      accept=".xlsx, .xls"
                      type="file"
                      id="file-upload"
                      onChange={handleFileChange}
                    />
                    <label className={classes.uploadImageButton} htmlFor="file-upload">
                      UPLOAD FILE
                    </label>
                    <p style={{ color: " #1976d2", fontSize: "10px", paddingLeft: "10px" }}>*Accept .xlsx, .xls files only</p>
                    {/* Download Link */}
                    {file && file.size>0 && (
                      <p
                        style={{ color: '#1976d2', fontSize: '10px', paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={handleDownload}
                      >
                        Download File
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* step 3, disabled */}
          <Accordion elevation={0} className={classes.disabledAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Confirmation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                {/* row 1 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Close Date*"
                    type="closed_at"
                    className={classes.dialogTextBox}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.closed_at}
                  />
                </div>
                {/* row 2 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    select
                    label="Action*"
                    name="action_taken"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={recordInfo.action_taken}
                  >
                    {Object.entries(FaultyPackageActionTaken).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* row 3 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Notes"
                    name="closing_notes"
                    multiline
                    minRows={4}
                    defaultValue="Add notes here"
                    className={classes.dialogTextBox}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.closing_notes}
                  />
                </div>
                {/* row 4 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <div className={classes.dialogTextBox}>{"Current Package Status: " + FaultyPackagePackageStatus[recordInfo.package_status]}</div>
                  {console.log(recordInfo.package_status)}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

        </DialogContent>
        <DialogActions style={{ marginBottom: 24 }}>
          {recordInfo.record_status === 3 || recordInfo.record_status === 4 ? (
            <Button
              variant='contained'
              className={classes.buttonSystem}
              style={{ marginRight: 24 }}
              onClick={() => setEditRecordOpen(false)}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                variant='contained'
                autoFocus
                className={classes.buttonText}
                onClick={() => setEditRecordOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className={classes.buttonSystem}
                onClick={handleSubmit}
                style={{ marginRight: 24 }}
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
            {/* image detail pop up */}
            <Dialog maxWidth='md' fullWidth={true} open={imageDetail !== null} onClose={() => setImageDetail(null)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <img
            src={`data:image/jpeg;base64,${imageDetail}`}
            alt=''
            style={{
              zIndex: 1000,
              borderRadius: 4,
              cursor: 'pointer',
              margin: '5px'
            }}
          />
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button variant='contained' className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setImageDetail(null)}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
