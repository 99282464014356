import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormControlLabel, Radio, Button, Typography, Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress, RadioGroup, InputAdornment, Checkbox, MenuItem } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import InfoIcon from '@material-ui/icons/Info';
import { createBusinessOrder, estimateShipping, getInvoice, printLabel } from '../../../actions/postalOutletActions.js';
import { showAlertSnackbar } from "../../../reducers/uiSlice";
import {useCustomer} from "../../../utilities/hooks";


const useStyles = makeStyles((theme) => ({
    newOrder: {
        background: "#ffffff",
        borderRadius: "4px",
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        margin: "25px 0px 25px",
        padding: "25px 25px 25px",
        width: "80%",
        display: "block",
        justifyContent: "center"
    },
    newOrderInput: {
        height: 56,
        margin: "18px 50px 18px 0px",
    },
    subheading: {
        fontSize: 14,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: '0.12px',
        color: "#616161",
    },
    radioButton: {
        color: "rgba(0, 0, 0, 0.6)",
        '&.Mui-checked': {
            color: "#1976d2"
        },
        '& .MuiSvgIcon-root': {
            fontSize: 16,
        },

    },
    radioTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: '0.12px',
        color: "#616161",
        marginRight: 12,
        marginTop: 19,
    },
    radioText: {
        margin: '2px 0 1px',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    confirmText: {
        margin: "12px 0 5px 2px",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        textAlign: "left",
        color: "#1976d2",
    },
    infoIcon: {
        color: "rgba(0, 0, 0, 0.2)",
        verticalAlign: "middle",
        margin: 0
    },
    checkBox: {
        margin: "8px 0 5px 0px",
        '&.Mui-checked': {
            color: "#1976d2"
        }
    },
    clearButton: {
        margin: "5px 10px 5px",
        fontSize: "12px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        color: "#2a3848"
    },
    quoteButton: {
        height: "36px",
        padding: "6px 16px",
        borderRadius: " 4px",
        margin: "5px 10px 5px",
        fontSize: "12px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        backgroundColor: "#2a3848",
        color: "#fff",
        '&:disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)"
        }

    },
    quote: {
        color: "#ff9d00",
        fontSize: 46,
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
    },
    eta: {
        fontSize: 18,
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        color: "rgba(0, 0, 0, 0.87)",
    },
    buttonSystem: {
        backgroundColor: "#1976d2",
        color: " #fff",
        width: 108,
        height: 36,
        padding: "6px 16px",
        borderRadius: 4,
        boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
        fontSize: "12px",
        '&:hover': {
            background: "#1565c0",

        },
        '&:disabled': {
            background: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)"
        }


    },
    buttonText: {
        color: "#1976d2",
        width: 53,
        height: 19,
        margin: '2px 0 3px',
        fontSize: "14px",
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        '&:hover': {
            borderRadius: 4,
            backgroundColor: 'rgba(2, 136, 209, 0.04)'

        },
        '&:disabled': {
            color: "rgba(0, 0, 0, 0.38)"
        }


    },
    infoDipslay: {
        fontSize: "14px",
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
    }


}));

const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const POSTAL_CODE_PATTERN = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

function validateAll(orderInfo) {
    if (orderInfo.parcelWeight !== "" && isNaN(orderInfo.parcelWeight))
        return false;
    if (orderInfo.parcelLength !== "" && isNaN(orderInfo.parcelLength))
        return false;
    if (orderInfo.parcelWidth !== "" && isNaN(orderInfo.parcelWidth))
        return false;
    if (orderInfo.parcelHeight !== "" && isNaN(orderInfo.parcelHeight))
        return false;
    if (orderInfo.senderPhone !== "" && orderInfo.senderPhone.length < 10)
        return false;
    if (orderInfo.consigneePhone !== "" && orderInfo.consigneePhone.length < 10)
        return false;
    if (orderInfo.senderEmail !== "" && !EMAIL_PATTERN.test(orderInfo.senderEmail))
        return false;
    if (orderInfo.consigneeEmail !== "" && !EMAIL_PATTERN.test(orderInfo.consigneeEmail))
        return false;
    if (orderInfo.consigneePostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.consigneePostalCode))
        return false;
    if (orderInfo.senderPostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.senderPostalCode))
        return false;

    return true;
}

export default function NewOrder() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation('postalOutlet');

    const { customerData } = useCustomer();
    const [weightUnit, toggleWeightUnit] = useState('lb');
    const [dimUnit, toggleDimUnit] = useState('in');
    const [unitType, setUnitType] = useState('imperial');
    const [noProhibited, setNoProhibited] = useState(false);
    const [submitEnabled, setEnabled] = useState(false);
    const [confirmOrder, setConfirmOrder] = useState(false);
    const [paymentCollection, setPaymentCollection] = useState(false);
    const [price, setPrice] = useState("");
    const [eta, setEta] = useState("");
    const [loading, setLoading] = useState(false);
    const [labels, setLabelOpen] = useState(false);
    const [labelUrl, setLabelUrl] = useState({});
    const [infoOpen, setInfoOpen] = useState(false);
    const [invoice, setInvoiceOpen] = useState(false);
    const [packageInfo, setPackage] = useState({});
    const [invoiceURL, setInvoiceUrl] = useState({});
    const [orderInfo, setOrderInfo] = useState({
        parcelWeight: "",
        parcelLength: "",
        parcelWidth: "",
        parcelHeight: "",
        senderName: "",
        senderPhone: "",
        senderEmail: "",
        senderUnit: "",
        senderAddress: "",
        senderCity: "",
        senderProvince: "",
        senderPostalCode: "",
        consigneeName: "",
        consigneePhone: "",
        consigneeEmail: "",
        consigneeUnit: "",
        consigneeAddr: "",
        consigneeCity: "",
        consigneeProvince: "",
        consigneePostalCode: "",
        consigneeBuzzNo: "",
        Notes: "",
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrderInfo({
            ...orderInfo,
            [name]: value,
        });
    };
    const provinceList = {
        AB: "Alberta",
        BC: "British Columbia",
        MB: "Manitoba",
        NB: "New Brunswick",
        NL: "Newfoundland and Labrador",
        NT: "Northwest Territories",
        NS: "Nova Scotia",
        NU: "Nunavut",
        ON: "Ontario",
        PE: "Prince Edward Island",
        QC: "Quebec",
        SK: "Saskatchewan",
        YT: "Yukon",
        " ": " ",
    };
    function createOrder() {
        setLoading(true);
        let params = {
            "customer_name": customerData.alias,
            "customer_no": customerData.id,
            "sender": orderInfo.senderName,
            "start_phone": orderInfo.senderPhone,
            "start_email": orderInfo.senderEmail,
            "pickup_unit_no": orderInfo.senderUnit,
            "pickup_address": (orderInfo.senderUnit ? orderInfo.senderUnit + "-" : "") + orderInfo.senderAddress + " " + orderInfo.senderCity + " " + orderInfo.senderProvince + " " + orderInfo.senderPostalCode,
            "receiver": orderInfo.consigneeName,
            "postal_code": orderInfo.consigneePostalCode,
            "delivery_address": orderInfo.consigneeAddr + " " + orderInfo.consigneeCity + " " + orderInfo.consigneeProvince,
            "delivery_unit_no": orderInfo.consigneeUnit,
            "buzz_code": orderInfo.consigneeBuzzNo,
            "receiver_phone": orderInfo.consigneePhone,
            "receiver_email": orderInfo.consigneeEmail,
            "dimension_uom": "CM",
            "weight": orderInfo.parcelWeight,
            "weight_uom": "KGS",
            "driver_notes": orderInfo.Notes,
        }
        if (dimUnit === 'in') {
            params["length"] = orderInfo.parcelLength * 2.54;
            params["width"] = orderInfo.parcelWidth * 2.54;
            params["height"] = orderInfo.parcelHeight * 2.54;
            params["weight"] = orderInfo.parcelWeight / 2.205;
        } else {
            params["length"] = orderInfo.parcelLength;
            params["width"] = orderInfo.parcelWidth;
            params["height"] = orderInfo.parcelHeight;
            params["weight"] = orderInfo.parcelWeight;
        }
        dispatch(createBusinessOrder(params))
            .then((res) => {
                setPackage(res.data.tno);
                clearForm();
                dispatch(printLabel({ packageId: res.data.tno, labelType: 6, labelFormat: 'pdf' }))
                    .then((res) => {
                        var byteCharacters = Buffer.from(res.data[0].labelContent, 'base64');
                        var file = new Blob([byteCharacters], { type: 'application/pdf;base64' });
                        var fileURL = URL.createObjectURL(file);
                        setLabelUrl(fileURL);
                        setLabelOpen(true);
                        setPaymentCollection(false);
                        setLoading(false);
                    }).catch((err) => {
                        dispatch(showAlertSnackbar({ type: "error", message: err.message }));
                        setPaymentCollection(false);
                        setLoading(false);
                    })

            })
            .catch((err) => {
                setLoading(false);
                dispatch(showAlertSnackbar({ type: "error", message: err.message }));
            })

    }
    function getOrderInvoice() {
        setLoading(true);
        dispatch(getInvoice({ invoiceId: packageInfo, invoiceType: 1, invoiceFormat: 'pdf' }))
            .then((res) => {
                var byteCharacters = Buffer.from(res.data[0].invoiceContent, 'base64');
                var file = new Blob([byteCharacters], { type: 'application/pdf;base64' });
                var invoiceUrl = URL.createObjectURL(file);
                setInvoiceUrl(invoiceUrl);
                setLabelOpen(false);
                setInvoiceOpen(true);
                setLoading(false);
            }).catch((err) => {
                dispatch(showAlertSnackbar({ type: "error", message: err.message }));
                setLabelOpen(false);
                setLoading(false);
            })

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let params = {
            "start_postal_code": window.localStorage.getItem("start_postal_code"),
            "postal_code": orderInfo.consigneePostalCode
        };
        if (dimUnit === 'in') {
            params["length"] = orderInfo.parcelLength * 2.54;
            params["width"] = orderInfo.parcelWidth * 2.54;
            params["height"] = orderInfo.parcelHeight * 2.54;
            params["weight"] = orderInfo.parcelWeight / 2.205;
        } else {
            params["length"] = orderInfo.parcelLength;
            params["width"] = orderInfo.parcelWidth;
            params["height"] = orderInfo.parcelHeight;
            params["weight"] = orderInfo.parcelWeight;
        }
        dispatch(estimateShipping(params))
            .then((res) => {
                setPrice("$" + res.data.totalAfterTax);
                setEta("ETA In " + res.data.eta + " days");
                setConfirmOrder(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                dispatch(showAlertSnackbar({ type: "error", message: err.message }));

            })

    };

    const handleUnits = (e) => {
        if (e.target.value === 'imperial') {
            setUnitType(e.target.value);
            toggleWeightUnit('lb');
            toggleDimUnit('in');
        }
        if (e.target.value === 'metric') {
            setUnitType(e.target.value);
            toggleWeightUnit('kg');
            toggleDimUnit('cm');
        }
    }
    function clearForm(){
        setNoProhibited(false);
        setOrderInfo({
            parcelWeight: "",
            parcelLength: "",
            parcelWidth: "",
            parcelHeight: "",
            senderName: "",
            senderPhone: "",
            senderEmail: "",
            senderUnit: "",
            senderAddress: "",
            senderCity: "",
            senderProvince: "",
            senderPostalCode: "",
            consigneeName: "",
            consigneePhone: "",
            consigneeEmail: "",
            consigneeUnit: "",
            consigneeAddr: "",
            consigneeCity: "",
            consigneeProvince: "",
            consigneePostalCode: "",
            consigneeBuzzNo: "",
            Notes: "",
        });
    }
    useEffect(() => {
        if (orderInfo.parcelWeight && orderInfo.parcelHeight && orderInfo.parcelLength && orderInfo.parcelWidth
            && orderInfo.senderName && orderInfo.senderEmail && orderInfo.senderAddress && orderInfo.senderCity && orderInfo.senderProvince && orderInfo.senderPostalCode
            && orderInfo.consigneeName && orderInfo.consigneePhone && orderInfo.consigneeAddr && orderInfo.consigneeCity && orderInfo.consigneeProvince && orderInfo.consigneePostalCode && validateAll()) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }

    }, [orderInfo]);
    return (

        <div className={classes.newOrder}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex' ,verticalAlign:"center"}}>
                    <p style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>{t('new_order')}</p>
                    <Button id="newOrder.clearButton" className={classes.clearButton} onClick={clearForm}>{t('clear')}</Button>
                    <Button id="newOrder.submitButton" className={classes.quoteButton} type="submit" disabled={!(submitEnabled && noProhibited)}>{t('place_order').toUpperCase()}</Button>
                </div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    {/* row 1 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <p style={{ flex: 1 }} className={classes.subheading}>{t('parcel_info')}</p>
                        <RadioGroup id="newOrder.unitToggle" style={{ flex: 2 }} row value={unitType} onChange={handleUnits}>
                            <p className={classes.radioTitle}>{t('unit')}</p>
                            <FormControlLabel  id="newOrder.unitToggle.imperial" value="imperial"
                                control={<Radio className={classes.radioButton} />}
                                label={<Typography className={classes.radioText}>{t('imperial_units')}</Typography>} />
                            <FormControlLabel  id="newOrder.unitToggle.metric" value="metric"
                                control={<Radio className={classes.radioButton} />}
                                label={<Typography className={classes.radioText}>{t('metric_system')}</Typography>} />
                        </RadioGroup>

                        <div style={{ flex: 3 }}>
                            <FormControlLabel id="newOrder.prohibitedCheck" control={<Checkbox className={classes.checkBox} checked={noProhibited} onClick={() => setNoProhibited(!noProhibited)} size="small" />}
                                label={<Typography className={classes.confirmText}>{t('prohibited_items_check')}</Typography>} />
                            <InfoIcon id="newOrder.prohibitedInfo" className={classes.infoIcon} onClick={() => setInfoOpen(true)} fontSize="small" />
                        </div>
                    </div>
                    {/* row2 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <TextField
                            style={{ flex: 1 }}
                            label={t('parcel_weight') + "*"}
                            className={classes.newOrderInput}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {weightUnit}
                                    </InputAdornment>
                            }}
                            variant="outlined"
                            id="parcelWeight"
                            name="parcelWeight"
                            value={orderInfo.parcelWeight}
                            onChange={handleInputChange}
                            error={orderInfo.parcelWeight !== "" && isNaN(orderInfo.parcelWeight)}
                            helperText={orderInfo.parcelWeight !== "" && isNaN(orderInfo.parcelWeight)?"Invalid Format":""}
                        />
                        <TextField
                            style={{ flex: 1 }}
                            label={t('parcel_length') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {dimUnit}
                                    </InputAdornment>
                            }}
                            id="parcelLength"
                            name="parcelLength"
                            value={orderInfo.parcelLength}
                            onChange={handleInputChange}
                            error={orderInfo.parcelLength !== "" && isNaN(orderInfo.parcelLength)}
                            helperText={orderInfo.parcelLength !== "" && isNaN(orderInfo.parcelLength)?"Invalid Format":""}
                        />
                        <TextField
                            style={{ flex: 1 }}
                            label={t('parcel_width') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {dimUnit}
                                    </InputAdornment>
                            }}
                            id="parcelWidth"
                            name="parcelWidth"
                            value={orderInfo.parcelWidth}
                            onChange={handleInputChange}
                            error={orderInfo.parcelWidth !== "" && isNaN(orderInfo.parcelWidth)}
                            helperText={orderInfo.parcelWidth !== "" && isNaN(orderInfo.parcelWidth)?"Invalid Format":""}
                        />
                        <TextField
                            style={{ flex: 1 }}
                            label={t('parcel_height') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {dimUnit}
                                    </InputAdornment>
                            }}
                            id="parcelHeight"
                            name="parcelHeight"
                            value={orderInfo.parcelHeight}
                            onChange={handleInputChange}
                            error={orderInfo.parcelHeight !== "" && isNaN(orderInfo.parcelHeight)}
                            helperText={orderInfo.parcelHeight !== "" && isNaN(orderInfo.parcelHeight)?"Invalid Format":""}
                        />
                    </div>
                    {/* row3 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <p className={classes.subheading}>{t('sender_info')}</p>
                    </div>
                    {/* row4 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <TextField
                            style={{ flex: 1 }}
                            label={t('name') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderName"
                            name="senderName"
                            value={orderInfo.senderName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            style={{ flex: 1 }}
                            label={t('phone')}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderPhone"
                            name="senderPhone"
                            value={orderInfo.senderPhone}
                            onChange={handleInputChange}
                            error={orderInfo.senderPhone !== "" && orderInfo.senderPhone.length < 10}
                            helperText={orderInfo.senderPhone !== "" && orderInfo.senderPhone.length < 10?"Invalid Format":""}
                        />
                        <TextField
                            style={{ flex: 2.12 }}
                            label={t('email') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderEmail"
                            name="senderEmail"
                            value={orderInfo.senderEmail}
                            onChange={handleInputChange}
                            error={orderInfo.senderEmail !== "" && !EMAIL_PATTERN.test(orderInfo.senderEmail)}
                            helperText={orderInfo.senderEmail !== "" && !EMAIL_PATTERN.test(orderInfo.senderEmail)?"Invalid Format":""}
                        />
                    </div>
                    {/* row5 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <TextField
                            style={{ flex: 0.65, marginRight: 15 }}
                            label={t('unitNo')}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderUnit"
                            name="senderUnit"
                            value={orderInfo.senderUnit}
                            onChange={handleInputChange}
                        />
                        <p style={{ marginTop: 30, marginRight: 15 }}>__</p>
                        <TextField
                            style={{ flex: 3.3 }}
                            label={t('street_addr') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderAddress"
                            name="senderAddress"
                            value={orderInfo.senderAddress}
                            onChange={handleInputChange}
                        />
                        <TextField
                            style={{ flex: 1.2 }}
                            label={t('city') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderCity"
                            name="senderCity"
                            value={orderInfo.senderCity}
                            onChange={handleInputChange}
                        />
                        <TextField
                            select
                            style={{ flex: 1.2 }}
                            label={t('province') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderProvince"
                            name="senderProvince"
                            value={orderInfo.senderProvince ? orderInfo.senderProvince : " "}
                            onChange={handleInputChange}
                        >
                            {Object.keys(provinceList).map((province) => {
                                return <MenuItem value={province} key={province}>{provinceList[province]}</MenuItem>
                            })}
                        </TextField>
                        <TextField
                            style={{ flex: 1.2 }}
                            label={t('postal_code') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="senderPostalCode"
                            name="senderPostalCode"
                            value={orderInfo.senderPostalCode}
                            onChange={handleInputChange}
                            error={orderInfo.senderPostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.senderPostalCode)}
                            helperText={orderInfo.senderPostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.senderPostalCode)?"Invalid Format":""}
                        />
                    </div>
                    {/* row6 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <p className={classes.subheading}>{t('consignee_info')}</p>
                    </div>
                    {/* row7 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <TextField
                            style={{ flex: 1 }}
                            label={t('name') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneeName"
                            name="consigneeName"
                            value={orderInfo.consigneeName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            style={{ flex: 1 }}
                            label={t('phone') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneePhone"
                            name="consigneePhone"
                            value={orderInfo.consigneePhone}
                            onChange={handleInputChange}
                            error={orderInfo.consigneePhone !== "" && orderInfo.consigneePhone.length < 10}
                            helperText={orderInfo.consigneePhone !== "" && orderInfo.consigneePhone.length < 10?"Invalid Format":""}
                        />
                        <TextField
                            style={{ flex: 2.12 }}
                            label={t('email')}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneeEmail"
                            name="consigneeEmail"
                            value={orderInfo.consigneeEmail}
                            onChange={handleInputChange}
                            error={orderInfo.consigneeEmail !== "" && !EMAIL_PATTERN.test(orderInfo.consigneeEmail)}
                            helperText={orderInfo.consigneeEmail !== "" && !EMAIL_PATTERN.test(orderInfo.consigneeEmail)?"Invalid Format":""}
                        />
                    </div>
                    {/* row8 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <TextField
                            style={{ flex: 0.65, marginRight: 15 }}
                            label={t('unitNo')}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneeUnit"
                            name="consigneeUnit"
                            value={orderInfo.consigneeUnit}
                            onChange={handleInputChange}
                        />
                        <p style={{ marginTop: 30, marginRight: 15 }}>__</p>
                        <TextField
                            style={{ flex: 3.3 }}
                            label={t('street_addr') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneeAddr"
                            name="consigneeAddr"
                            value={orderInfo.consigneeAddr}
                            onChange={handleInputChange}
                        />
                        <TextField
                            style={{ flex: 1.2 }}
                            label={t('city') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneeCity"
                            name="consigneeCity"
                            value={orderInfo.consigneeCity}
                            onChange={handleInputChange}
                        />
                        <TextField
                            select
                            style={{ flex: 1.2 }}
                            label={t('province') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneeProvince"
                            name="consigneeProvince"
                            value={orderInfo.consigneeProvince ? orderInfo.consigneeProvince : " "}
                            onChange={handleInputChange}
                        >
                            {Object.keys(provinceList).map((province) => {
                                return <MenuItem value={province} key={province}>{provinceList[province]}</MenuItem>
                            })}
                        </TextField>
                        <TextField
                            style={{ flex: 1.2 }}
                            label={t('postal_code') + "*"}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneePostalCode"
                            name="consigneePostalCode"
                            value={orderInfo.consigneePostalCode}
                            onChange={handleInputChange}
                            error={orderInfo.consigneePostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.consigneePostalCode)}
                            helperText={orderInfo.consigneePostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.consigneePostalCode)?"Invalid Format":""}
                        />
                    </div>
                    {/* row9 */}
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                        <TextField
                            style={{ flex: 0.65 }}
                            label={t('buzz_no')}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="consigneeBuzzNo"
                            name="consigneeBuzzNo"
                            value={orderInfo.consigneeBuzzNo}
                            onChange={handleInputChange}
                        />
                        <TextField
                            style={{ flex: 8 }}
                            label={t('notes')}
                            className={classes.newOrderInput}
                            variant="outlined"
                            id="Notes"
                            name="Notes"
                            value={orderInfo.Notes}
                            onChange={handleInputChange}
                        />
                    </div>


                </div>
            </form>
            <Dialog id="newOrder.confirmOrder" maxWidth='sm' fullWidth={true} open={confirmOrder}>
                <DialogTitle><b>{t('confirm_order')}</b></DialogTitle>
                <DialogContent>
                    <div>
                        <div style={{ float: "left", width: '60%' }}>
                            <h4>{t('parcel_info')}</h4>
                            <pre className={classes.infoDipslay}>
                                Weight:         {orderInfo.parcelWeight}{weightUnit}<br />
                                Height:         {orderInfo.parcelHeight}{dimUnit}<br />
                                Width:          {orderInfo.parcelWidth}{dimUnit}<br />
                                Length:         {orderInfo.parcelLength}{dimUnit}<br />
                            </pre>
                            <h4>{t('sender_info')}</h4>
                            <pre className={classes.infoDipslay}>
                                Name:           {orderInfo.senderName}<br />
                                Phone:          {orderInfo.senderPhone}<br />
                                E-mail:         {orderInfo.senderEmail}<br />
                                Unit:           {orderInfo.senderUnit}<br />
                                Address:        {orderInfo.senderAddress}<br />
                                City:           {orderInfo.senderCity}<br />
                                Province:       {orderInfo.senderProvince}<br />
                                Postal Code:    {orderInfo.senderPostalCode}<br />
                            </pre>
                            <h4>{t('consignee_info')}</h4>
                            <pre className={classes.infoDipslay}>
                                Name:           {orderInfo.consigneeName}<br />
                                Phone:          {orderInfo.consigneePhone}<br />
                                E-mail:         {orderInfo.consigneeEmail}<br />
                                Unit:           {orderInfo.consigneeUnit}<br />
                                Address:        {orderInfo.consigneeAddr}<br />
                                City:           {orderInfo.consigneeCity}<br />
                                Province:       {orderInfo.consigneeProvince}<br />
                                Postal Code:    {orderInfo.consigneePostalCode}<br />
                                Buzz#:          {orderInfo.consigneeBuzzNo}<br />
                                Notes:          {orderInfo.Notes}<br />
                            </pre>
                        </div>
                        <div style={{ float: "left", width: '40%' }}>
                            <h4>{t('freight_fee')}</h4>
                            <Typography id="newOrder.confirmOrder.price" className={classes.quote}>{price}</Typography>
                            <Typography id="newOrder.confirmOrder.eta" className={classes.eta}>{eta}</Typography>

                        </div>
                    </div>
                </DialogContent>

                <DialogActions style={{ marginBottom: 24 }}>
                    <Button id="newOrder.confirmOrder.cancelButton" className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setConfirmOrder(false)}>Cancel</Button>
                    <Button id="newOrder.confirmOrder.nextButton" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={() => { setPaymentCollection(true); setConfirmOrder(false); }}>NEXT</Button>
                </DialogActions>
            </Dialog>
            <Dialog id="newOrder.paymentCollection" maxWidth='sm' fullWidth={true} open={paymentCollection}>
                <DialogTitle><b>{t('payment_collection')}</b></DialogTitle>
                <DialogContent>
                    <div>
                        <b>{t('collect_fee')}</b>
                        <Typography id="newOrder.paymentCollection.price" className={classes.quote}>{price}</Typography>
                        <Typography>{t('collect_fee_notice')}</Typography>
                    </div>
                </DialogContent>

                <DialogActions style={{ marginBottom: 24 }}>
                    <Button id="newOrder.paymentCollection.cancelButton" className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setPaymentCollection(false)}>Cancel</Button>
                    <Button id="newOrder.paymentCollection.confirmButton" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={() => createOrder()}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog id="newOrder.printLabel" maxWidth='sm' open={labels}>
                <DialogTitle><b>{t('print_label')}</b></DialogTitle>
                <DialogContent>
                    <object id="newOrder.printLabel.label" width="400" height="600" type="application/pdf" data={labelUrl}>
                        <p>Cannot Render Label, Please click print to view label</p>
                    </object>
                </DialogContent>

                <DialogActions style={{ marginBottom: 24 }}>
                    <Button id="newOrder.printLabel.printButton" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={(e) => { window.open(labelUrl, '_blank'); getOrderInvoice(); }}>PRINT</Button>
                    <Button id="newOrder.printLabel.nextButon" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={() => { getOrderInvoice(); }}>NEXT</Button>
                </DialogActions>
            </Dialog>
            <Dialog id="newOrder.printInvoice" maxWidth='sm' open={invoice} >
                <DialogTitle><b>{t('print_invoice')}</b></DialogTitle>
                <DialogContent>
                    <object id="newOrder.printInvoice.invoice" width="400" height="600" type="application/pdf" data={invoiceURL}>
                        <p>Cannot Render Invoice, Please click print to view Invoice</p>
                    </object>
                </DialogContent>

                <DialogActions style={{ marginBottom: 24 }}>
                    <Button id="newOrder.printInvoice.printButton" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={(e) => { window.open(invoiceURL, '_blank'); setInvoiceOpen(false); }}>PRINT</Button>
                    <Button id="newOrder.printInvoice.completeButton" className={classes.buttonSystem} style={{ marginRight: 24 }} autoFocus onClick={() => setInvoiceOpen(false)}>COMPLETE</Button>
                </DialogActions>
            </Dialog>

            <Dialog id="newOrder.prohibitedItems" maxWidth='sm' fullWidth={true} open={infoOpen} onClose={() => setInfoOpen(false)}>
                <DialogTitle><b>{t('prohibited_items')}</b></DialogTitle>
                <DialogContent>
                    <Typography>{t('prohibited_notes_1')}</Typography>
                    <ul>
                        {t('prohibited_list', { returnObjects: true }).map((val) => (<li key={val}>{val}</li>))}
                    </ul>
                    <Typography>{t('prohibited_notes_2')}</Typography>
                </DialogContent>

                <DialogActions style={{ marginBottom: 24 }}>
                    <Button id="newOrder.prohibitedItems.ok" className={classes.buttonSystem} style={{ marginRight: 24 }} onClick={() => setInfoOpen(false)}>OK</Button>
                </DialogActions>
            </Dialog>
            <Backdrop id="loadingBackdrop" sx={{ color: '#fff', zIndex: 3000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>



        </div>

    );
}