import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const componentWidth = 1400;
const inputWidth = 260;
const buttonWidth = 59;

const useStyles = makeStyles((theme) => ({
  searchBar: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex',
    minWidth: componentWidth,
  },
  inputField: {
    minWidth: inputWidth,
    width: '19%',
    height: 56,
    margin: "16px 12px",
  },
  datePicker: {
    minWidth: inputWidth,
    width: '19%',
    height: 56,
    margin: "16px 12px",
  },
  iconButton: {
    minWidth: buttonWidth,
    height: 59,
    margin: "11px 0 0 36px",
    padding: 12,
    color: "#1976d2",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.04)",
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.26)",
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  header: {
    display: "flex",
    minWidth: componentWidth,
    marginLeft: 30,
  },
  breadCrumbs: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  }
}));


export default function PostalOutletOrderSearch(props) {
  const {trackingNo, setTrackingNo, fromDate, setFromDate, toDate, setToDate, handleSearch} = props;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "10px 0 1px" }}>Orders</p>

          {/*<Breadcrumbs aria-label="breadcrumb">*/}
          {/*  <Link*/}
          {/*    underline="hover"*/}
          {/*    color="inherit"*/}
          {/*    to="/"*/}
          {/*  >*/}
          {/*    Home*/}
          {/*  </Link>*/}
          {/*  <Typography className={classes.breadCrumbText} color="text.primary">Orders</Typography>*/}
          {/*</Breadcrumbs>*/}

        </div>

      </div>
      <div className={classes.searchBar}>
        <TextField
          label="Tracking Number"
          id="postaloutlet_orders_search_tno_input"
          className={classes.inputField}
          InputLabelProps={{ shrink: true }}
          value={trackingNo}
          onChange={(e) => setTrackingNo(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Order Created From"
          type="date"
          id="postaloutlet_orders_search_fromtime_input"
          className={classes.datePicker}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <div style={{ marginTop: 20, display: 'inline-block' }}>_</div>
        <TextField
          label="Order Created To"
          type="date"
          id="postaloutlet_orders_search_endtime_input"
          className={classes.datePicker}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <IconButton id="postaloutlet_orders_search_button" className={classes.iconButton} component="span" onClick={handleSearch }>
          <SearchIcon />
        </IconButton>

      </div>
    </div >
  );
}