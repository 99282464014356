import { Button, Dialog, DialogContent, DialogTitle, makeStyles, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useDispatch } from "react-redux";
import { deleteDriver, editDriver, generateSendT4a } from "../../../actions/t4aActions";
import { showAlertSnackbar } from '../../../reducers/uiSlice';

const FixedTextField = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '10px !important',
        }
    },
}))(TextField);
const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 20,
        marginRight: 20
    },
    infoTitle: {
        fontSize: 19,
        color: '#8a8b8a'
    },
    sendStyle: {
        fontSize: 16,
        fontWeight: 500,
        color: '#262626',
        marginLeft: 6
    },
    table: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 16,
        marginBottom: 60
    },
    tableHeaderRow: {
        backgroundColor: "#ebebeb"
    },
    headerTitle: {
        fontWeight: 'bold',
        padding: '0px !important',
        width: 100
    },
    bodyCell: {
        paddingTop: '10px !important',
        paddingBottom: '5px !important',
    },
    deleteGroup: {
        marginLeft: 20,
        marginRight: 20,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    delete: {
        fontSize: 18,
        fontWeight: 500,
        color: '#ff453a',
        maginLeft: 5,
        cursor: 'pointer'
    }
}))

function DriverEdit(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [driver, setDriver] = useState({});

    const [driverId, setDriverId] = useState();
    const [lastName, setLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [sin, setSin] = useState();
    const [amount, setAmount] = useState();
    const [province, setProvince] = useState();
    const [company, setCompany] = useState();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [id, setId] = useState();

    function handleClose() {
        setOpenModal(false);
        props.handleClose();
        window.location.reload();
    }
    function handleDeleteConfirm() {
        setDeleteConfirmOpen(true);
    }
    function handleDelete() {
        dispatch(deleteDriver(driver.driver_id))
        .then(() => {
            dispatch(showAlertSnackbar({message: "Deleted successfully!", type: 'success'}));
            setDeleteConfirmOpen(false);
            handleClose();
            setTimeout(() => {
                window.location.reload();
            }, 500);
        })
    }
    function handleSave() {
        let params = {
            id:id,
            driver_id: driverId,
            last_name: lastName,
            first_name: firstName,
            sin: sin,
            amount: amount,
            province: province,
            company: company
        }
        dispatch(editDriver(id, params))
        .then(() => {
            dispatch(showAlertSnackbar({message: 'Driver information updated successsfully!', type: 'success'}))
        })
        .catch(() => {
            dispatch(showAlertSnackbar({message: 'Failed to update Driver Information', type: 'error'}))
        })
    }
    function handleGenerateSend() {
        dispatch(generateSendT4a(id))
        .then(() => {
            dispatch(showAlertSnackbar({message: 'Generate and send T4A successfully!', type: 'success'}))
        })
        .catch(() => {
            dispatch(showAlertSnackbar({message: 'Failed to generate and send T4A!', type: 'error'}))
        })
    }

    useEffect(() => {
        setOpenModal(props.open)
    }, [props.open])
    useEffect(() => {
        setDriver(props.driverInfo)
    }, [props.driverInfo])
    useEffect(() => {
        if(openModal) {
            if(driver) {
                setId(driver.id);
                setDriverId(driver.driver_id);
                setLastName(driver.last_name);
                setFirstName(driver.first_name);
                setSin(driver.sin);
                setAmount(driver.amount);
                setProvince(driver.province);
                setCompany(driver.company);
            }
        }
    }, [openModal, driver])

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle>
                <CloseIcon onClick={handleClose} style={{float: 'right'}}></CloseIcon>
            </DialogTitle>
            <DialogContent>
                <div className={classes.title}>
                    <div className={classes.infoTitle}>
                        Driver information
                    </div>
                    {
                        !enableEdit &&
                        <div style={{display: 'flex', textAlign: 'center'}}>
                            <div style={{display: 'flex', textAlign: 'baseline', cursor: 'pointer'}}>
                                <MailOutlineIcon 
                                    style={{height: 21, width: 21}}
                                />
                                <span 
                                    className={classes.sendStyle}
                                    onClick={handleGenerateSend}
                                >
                                    Generate and send T4A
                                </span>
                            </div>
                            <div 
                                style={{display: 'flex', textAlign: 'baseline', marginLeft: 52, cursor: 'pointer'}}
                                onClick={() => {
                                    setEnableEdit(true)
                                }}
                            >
                                <EditIcon 
                                    style={{height: 21, width: 21}}
                                />
                                <span className={classes.sendStyle}>Edit</span>
                            </div>
                        </div>
                    }
                    {
                        enableEdit &&
                        <div style={{display: 'flex', textAlign: 'center'}}>
                            <div>
                                <Button
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        width: 90,
                                        height: 30
                                    }}
                                    onClick={() => {
                                        setEnableEdit(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div style={{marginLeft: 51}}>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        width: 90,
                                        height: 30
                                    }}
                                    onClick={() => {
                                        handleSave();
                                        setEnableEdit(false);
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    }
                </div>
                <div className={classes.table}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tableHeaderRow}>
                                    <TableCell align="center" className={classes.headerTitle}>Driver ID</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>Last Name</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>First Name</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>SIN</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>Province</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>Company</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>Amount</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>T4A</TableCell>
                                    <TableCell align="center" className={classes.headerTitle}>Confirm</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        <FixedTextField
                                            variant='outlined'
                                            value={driverId}
                                            disabled={!enableEdit}
                                            onChange={(e)=>setDriverId(e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        <FixedTextField
                                            variant='outlined'
                                            value={lastName}
                                            disabled={!enableEdit}
                                            onChange={(e)=>setLastName(e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        <FixedTextField
                                            variant='outlined'
                                            value={firstName}
                                            disabled={!enableEdit}
                                            onChange={(e)=>setFirstName(e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        <FixedTextField
                                            variant='outlined'
                                            value={sin}
                                            disabled={!enableEdit}
                                            onChange={(e)=>setSin(e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        <FixedTextField
                                            style={{width: 80}}
                                            select
                                            variant='outlined'
                                            defaultValue={driver.province}
                                            value={province}
                                            disabled={!enableEdit}
                                            onChange={(e)=>setProvince(e.target.value)}
                                        >
                                            {
                                                props.provinceList.map((province, index) => {
                                                    if (index !== 0) {
                                                        return (
                                                            <MenuItem value={province.code}>{province.name}</MenuItem>
                                                        )
                                                    }
                                                    else return null
                                                })
                                            }
                                        </FixedTextField>
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        <FixedTextField
                                            style={{width: 110}}
                                            select
                                            variant='outlined'
                                            defaultValue={driver.company}
                                            value={company}
                                            disabled={!enableEdit}
                                            onChange={(e)=>setCompany(e.target.value)}
                                        >
                                            {
                                                props.companyList.map((company, index) => {
                                                    if (index !== 0) {
                                                        return (
                                                            <MenuItem value={company.code}>{company.name}</MenuItem>
                                                        )
                                                    }
                                                    else return null
                                                })
                                            }
                                        </FixedTextField>
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        <FixedTextField
                                            style={{width: 140}}
                                            variant='outlined'
                                            value={amount}
                                            disabled={!enableEdit}
                                            onChange={(e)=>setAmount(e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        {
                                            driver.message_sent === 0 ?
                                            <div>Unsent</div> :
                                            <div>Sent</div>
                                        }
                                    </TableCell>
                                    <TableCell align="center" className={classes.bodyCell}>
                                        {
                                            driver.confirmed === 0 ?
                                            <div></div> :
                                            <CheckCircleIcon style={{color:'#bbdefb'}}/>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.deleteGroup}>
                    <DeleteForeverIcon 
                        style={{color: '#ff453a'}}
                    />
                    <div className={classes.delete} onClick={handleDeleteConfirm}>
                        Delete this driver
                    </div>
                </div>
            </DialogContent>
            <Dialog
                open={deleteConfirmOpen}
                maxWidth='sm'
            >
                <DialogContent>
                    <div>Are you sure to delete this driver record?</div>
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
                        <Button
                            onClick={()=>setDeleteConfirmOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color='secondary'
                            onClick={handleDelete}
                        >
                            Confirm
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </Dialog>
    )
}
export default DriverEdit;