import { useDispatch } from 'react-redux';
import {
  TextField, makeStyles, Button
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {DataGrid} from "@material-ui/data-grid";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import {getCustomers, getEcomMerchantNames, getPartners} from "../../../actions/storeActions";
import { useAlert } from "../../../utilities/hooks";
import EditCustomer from "../OrganizationEditCustomer/EditCustomer";

const useStyle = makeStyles(() => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex'
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  header: {
    display: "flex"
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  dialogButton: {
    width: '260px',
    height: '56px'
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 630,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const ALERT = {
  SEARCH_SUCCESS  : "FETCH DATA SUCCESS",
  SEARCH_FAILED   : "FETCH DATA FAILED",
  MODIFY_SUCCESS  : "MODIFY CUSTOMER SUCCESS",
  MODIFY_FAILED   : "MODIFY CUSTOMER FAILED",
  CREATE_SUCCESS  : "CREATE CUSTOMER SUCCESS",
  CREATE_FAILED   : "CREATE CUSTOMER FAILED",
};

function Customer(props) {
  const {customerOpen} = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const alertDialog = useAlert();

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  const [ecom, setEcom] = useState([]);
  const [merchant, setMerchant] = useState([]);
  const [partners, setPartners] = useState([]);

  const [customer, setCustomer] = useState([]);
  const [customerID, setCustomerID] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [partnerID, setPartnerID] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'client_id',
      headerName: 'Client ID',
      flex: 1,
    },
    {
      field: 'partner_name',
      headerName: 'Partner Name',
      flex: 1,
    },
    {
      field: 'partner_status',
      headerName: 'Partner Status',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'customer_id',
      headerName: 'Customer ID',
      flex: 1,
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      flex: 1,
    },
    {
      field: 'customer_status',
      headerName: 'Customer Status',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'email',
      headerName: 'Contact Email',
      flex: 1,
    },
    {
      field: 'need_pickup',
      headerName: 'Need Pickup',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'No') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
  ];

  const handleKeyPress = (e) => {
    if(e.key === 'Enter') {
      handleSearch(customerID, customerName, partnerID, partnerName);
    }
  }

  const handleSetPartnerId = (e) => {
    let id = e.target.value;
    if (!isNaN(id)) {
      setPartnerID(id);
    }
  }

  const handleSetCustomerId = (e) => {
    let id = e.target.value;
    if (!isNaN(id)) {
      setCustomerID(id);
    }
  }

  const handleRowClick = async (selectedRow) => {
    setLoading(true);
    try {
      const customerInfo = (await dispatch(getCustomers(selectedRow.row['customer_id'])))[0];

      setCustomer(customerInfo);
      setDialogOpen(true);
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    } finally {
      setLoading(false);
    }
  }

  const handleUpdateRow = (res) => {
    const updatedRows = res.map((row) => ({
      id: row.id,
      client_id: row.client_id,
      partner_name: row.partner_name,
      partner_status: row.partner_status ? 'Active' : 'Pending',
      customer_id: row.id,
      customer_name: row.alias,
      customer_status: row.is_active ? 'Active' : 'Pending',
      email: row.email,
      need_pickup: row.need_pickup ? 'Yes' : 'No',
    }));

    setRows(updatedRows);
  };

  const handleFetchPartners = useCallback(async () => {
    setLoading(true);
    try {
      let res = await dispatch(getPartners());
      setPartners(res);
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
    }
  }, [dispatch])

  const handleFetchEcomMerchantNames = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dispatch(getEcomMerchantNames());

      if (res?.ecom_name && res?.merchant) {
        setEcom(res.ecom_name);
        setMerchant(res.merchant);
      }
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    }
  },[dispatch])

  const handleSearch = useCallback(async (customerID='', customerName='', partnerID='', partnerName='') => {
    setLoading(true);
    try {
      const customers = await dispatch(getCustomers(customerID, customerName, partnerID, partnerName));

      handleUpdateRow(customers);

      dispatch(showAlertSnackbar({message: ALERT.SEARCH_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
      setCustomerID('');
      setCustomerName('');
      setPartnerID('');
      setPartnerName('');
    }
  }, [dispatch])

  function handleEditClose() {
    setDialogOpen(false);
  }

  useEffect(() => {
    if(customerOpen) {
      handleSearch();
      handleFetchPartners();
      handleFetchEcomMerchantNames();
    }
  }, [customerOpen, handleFetchEcomMerchantNames, handleFetchPartners, handleSearch])

  useEffect(() => {
    if(reloadPage) {
      handleSearch();
      handleFetchPartners();
      handleFetchEcomMerchantNames();
    }
  }, [handleFetchEcomMerchantNames, handleFetchPartners, handleSearch, reloadPage])

  useEffect(() => {
    if (!dialogOpen) {
      setCustomer([]);
      setReloadPage(false);
    }
  }, [dialogOpen])

  // useEffect(() => {
  //   console.log('AAA: ', reloadPage);
  // }, [reloadPage]);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Customer</p>
        </div>
        <Button
          id={'create-new-customer'}
          className={classes.button}
          style={{ marginRight: 30, }}
          startIcon={<AddIcon />}
          onClick={() => setDialogOpen(true)}
        >
          New Customer
        </Button>
      </div>

      <div className={classes.searchBar}>
        <TextField
          id={'customer_id_text'}
          label={'Customer ID'}
          variant='outlined'
          onChange={handleSetCustomerId}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={customerID}
          style={{width: '14vw', marginLeft: 16}}
        />

        <TextField
          id={'customer_name_search'}
          label={'Customer Name'}
          variant='outlined'
          onChange={e => setCustomerName(e.target.value)}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={customerName}
          style={{width: '14vw', marginLeft: 36}}
        />

        <TextField
          id={'partner_id_text'}
          label={'Partner ID'}
          variant='outlined'
          onChange={handleSetPartnerId}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={partnerID}
          style={{width: '14vw', marginLeft: 36}}
        />

        <TextField
          id={'partner_name_search'}
          label={'Partner Name'}
          variant='outlined'
          onChange={e => setPartnerName(e.target.value)}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={partnerName}
          style={{width: '14vw', marginLeft: 36}}
        />

        <IconButton
          id={'customer_search_button'}
          style={{marginLeft: 36}}
          size={'large'}
          color={'primary'}
          onClick={() => handleSearch(customerID, customerName, partnerID, partnerName)}
        >
          <SearchIcon fontSize={'inherit'}/>
        </IconButton>
      </div>

      <div className={classes.tableArea}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              color: "#577699",
              fontSize: 18,
              fontWeight: 600,
              marginLeft: 18,
              marginTop: 15
            }}
          >
            Customer List
          </div>
        </div>
        <DataGrid
          className={classes.table}
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          onRowClick={(selectedRow) => handleRowClick(selectedRow)}
        />
      </div>
      <EditCustomer
        openDialog={dialogOpen}
        closeDialog={handleEditClose}
        customerInfo={customer}
        setLoading={setLoading}
        partners={partners}
        ecom={ecom}
        merchant={merchant}
        setReloadPage={setReloadPage}
      />
    </div>
  );
}

export default Customer;