import { useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import moment from 'moment';
import { getMAWBStatistic, getBagStatistic } from '../../actions/prealertStatisticActions';
import { showAlertSnackbar } from '../../reducers/uiSlice';

const useStyles = makeStyles((theme)=> ({
  root: {
    display: 'flex',
  },
  button: {
    marginLeft: 10,
    marginBottom: 30
  }
}))


function PrealertStatisticSearchDate(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DDThh:mm'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DDThh:mm'));

  async function handleSearch() {
    props.setLoading(true);
    try {
      let response = [];
      if (props.type === 'MAWB') {
        response = await dispatch(getMAWBStatistic(moment(dateFrom).valueOf() / 1000, moment(dateTo).valueOf() / 1000)).catch(e => {
          throw e.message;
        })
      } else if (props.type === 'BAG') {
        response = await dispatch(getBagStatistic(moment(dateFrom).valueOf() / 1000, moment(dateTo).valueOf() / 1000)).catch(e => {
          throw e.message;
        })
      }
      props.setData(response);
      dispatch(showAlertSnackbar({message: 'Fetch Data Success', type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: e, type: 'error'}));
    } finally {
      props.setLoading(false);
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline'
        }}
      >
        <TextField
          id="start-time"
          label="Start Time"
          type="datetime-local"
          value={dateFrom}
          onChange={(e)=>setDateFrom(e.target.value)}
          className={classes.textField}
          style={{marginLeft: 30}}
        />

        <TextField
          id="end-time"
          label="End Time"
          type="datetime-local"
          value={dateTo}
          onChange={(e)=>setDateTo(e.target.value)}
          className={classes.textField}
          style={{marginLeft: 30}}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 30}}
          onClick={handleSearch}
        >
          {'Fetch'}
        </Button>
      </div>
    </>
  );
}

export default PrealertStatisticSearchDate;