import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DriverTable from '../../components/T4A/DriverTable';
import { shangjiaServerInstance } from '../../axios/instances';
import { Dialog, DialogContent, DialogTitle, LinearProgress, MenuItem, Select, Snackbar } from '@material-ui/core';
import { showAlertSnackbar } from '../../reducers/uiSlice';
import { fetchDriverList, generateSendT4a, searchDriver } from '../../actions/t4aActions';
import FullscreenLoading from '../../components/FullscreenLoading';
import { Alert } from '@mui/material';
import {useHistory} from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

const companyList = [
  {
    name: 'Uni BC',
    code: '1'
  },
  {
    name: 'Uni ON',
    code: '2'
  },
  {
    name: 'Uni QC',
    code: '3'
  }
]

const useStyles = makeStyles((theme)=> ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fafafa',
    height: 78,
    paddingTop: 11
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    marginLeft: 21,
    fontSize: 24,
    fontWeight: 500,
    color: '#262626'
  },
  searchInfo: {
    marginLeft: 80,
    marginRight: 80,
    paddingTop: 16,
    paddingBottom: 18,
    borderBottom: 'solid 1px #e4e4e3',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  infoTitle: {
    fontSize: 16,
    fontWeight: 500
  },
  inputRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
    backgroundColor: 'black'
  },
  tableContent: {
    height: 40,
    marginLeft: 80,
    marginRight: 80,
    verticalAlign: 'center'
  },
  tableTitle: {
    marginTop: 15,
    display: 'flex',
    alignItems: 'baseline'
  },
  tableSelected: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#636463'
  },
  operationTitle: {
    marginLeft: 38,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ff9d00',
    cursor: 'pointer'
  },
  operationTitleHover: {
    marginLeft: 38,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ff9d00',
    cursor: 'pointer',
    paddingLeft: 5,
    paddingRight: 5,
    '&:hover': {
      borderRadius: 4,
      border: '2px solid #ffb74d'
    }
  },
  table: {
    marginTop: 16,
    marginLeft: 80,
    marginRight: 80,
    textAlign: 'center'
  },
  taxGoup: {
    marginLeft: 38,
    display: 'flex',
    alignItems: 'baseline',
    '&:hover': {
      borderRadius: 4,
      border: '2px solid #ffb74d'
    }
  }
}))

function T4AMain(){
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const num = useSelector(state => state.t4a.getT4aTotal);

  const [rowsSelected, setRowsSelected] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [startUpload, setStartUpload] = useState(false);
  const [input, setInput] = useState('');
  const [company, setCompany] = useState(companyList[0].code);
  const [loading, setLoading] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleFailClose = (event, reason) => {
    navigator.clipboard.writeText(errorMsg.toString())
    dispatch(showAlertSnackbar({message: 'Copy error message(s) successfully!', type: 'success'}))
    if (reason === 'clickaway') {
      return;
    }
    setFailOpen(false);
  };

  function CustomizedInputBase() {
    const classes = useStyles();

    function isNumber(n) {
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    }

    function handleDriverSearch() {
      if(!input) {
        dispatch(fetchDriverList(1, 20));
      } else {
        if(isNumber(input)) {
          dispatch(searchDriver('driver_id', input));
        } else {
          dispatch(searchDriver('name', input));
        }
      }
    }

    return (
      <Paper component="form" className={classes.inputRoot}>
        <InputBase
          className={classes.input}
          placeholder="Enter driver name or ID to search"
          value={input}
          onChange={(e)=>setInput(e.target.value)}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          className={classes.iconButton}
          onClick={handleDriverSearch}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }
  function handleSubmit() {
    setStartUpload(true);
    let formData = new FormData();
    formData.append(' t4_file', selectedFile);
    shangjiaServerInstance.post(
      `/account/importdrivert4a`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    )
      .then(() => {
        setProgress(100);
        setSelectedFile(undefined);
        dispatch(showAlertSnackbar({message: 'Imported successfully!', type: 'success'}));
      })
      .catch((err) => {
        setProgress(100);
        setSelectedFile(undefined);
        setErrorMsg(err.message);
        setFailOpen(true);
        // dispatch(showAlertSnackbar({message: 'Failed to import!', type: 'error'}));
      })
  }
  function handleLogOut() {
    setLoading(true);
    history.push('/main');
  }
  function handleClose() {
    setOpenModal(false);
    setStartUpload(false);
  }
  function handleGenerateSend() {
    if(rowsSelected) {
      if(rowsSelected.length > 0) {
        dispatch(generateSendT4a(rowsSelected.join()))
          .then((res) => {
            dispatch(showAlertSnackbar({message: 'Generate and send successfully!', type: 'success'}))
          })
          .catch(() => {
            dispatch(showAlertSnackbar({message: 'Failed to generate and send T4A!', type: 'error'}))
          })
      } else {
        dispatch(showAlertSnackbar({message: 'Please select at least 1 driver!', type: 'error'}))
      }
    } else {
      dispatch(showAlertSnackbar({message: 'Please select at least 1 driver!', type: 'error'}))
    }
  }
  function handleDownload() {
    setLoading(true);
    const axios = require('axios');
    const baseURL = 'https://sj.uniexpress.ca';
    const getXML = () => {
      return axios.get(`${baseURL}/account/driverT4aXml?company=${company}`, {
        responseType: 'blob',
      }).then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Tax-${company}.xml`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
    getXML();
  }

  useEffect(() => {
    if(selectedFile) {
      setOpenModal(true);
    }
  }, [selectedFile])
  useEffect(() => {
    if(!openModal) {
      setSelectedFile(undefined);
    }
    if(openModal) {
      setFailOpen(false);
    }
  }, [openModal])
  useEffect(() => {
    if(!startUpload) {
      setProgress(0)
    }
  }, [startUpload]);
  useEffect(() => {
    if(progress === 100) {
      setTimeout(() => {
        setOpenModal(false);
        window.location.reload();
      }, 5000);
    }
  }, [progress])

  return (
    <div className={classes.root}>
      <Snackbar open={failOpen} autoHideDuration={10000} onClose={handleFailClose}>
        <Alert onClose={handleFailClose} severity="error">
          {
            errorMsg &&
            (
              Array.isArray(errorMsg) ?
                errorMsg.map((err) => (
                  <div>{err}</div>
                )) :
                <div>{errorMsg}</div>
            )
          }
        </Alert>
      </Snackbar>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: 80, marginRight: 80}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img
                src='/images/uniuni-logo-t4a.svg'
                alt=''
                style={{
                  height: 38,
                  width: 161,
                }}
              />
              <Typography noWrap className={classes.title}>
                T4A Management
              </Typography>
            </div>
            <input
              color="primary"
              type="file"
              id="t4a-file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              style={{display: 'none'}}
            />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <label htmlFor="t4a-file">
                <Box style={{cursor: 'pointer'}}>
                  <Button
                    startIcon={<CloudUploadIcon/>}
                    style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                    component="span"
                  >
                    Import data
                  </Button>
                </Box>
              </label>
              <Box style={{cursor: 'pointer', marginLeft: 17}}>
                <CloseIcon
                  style={{
                    margin: '0 0 0 auto'
                  }}
                  onClick={handleLogOut}
                />
              </Box>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <Toolbar />
        <div className={classes.searchInfo}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {
              num &&
              num[0] &&
              <div className={classes.infoTitle} style={{marginRight: 15}}>
                Total drivers: {num[0].total || 0}
              </div>
            }
            <div className={classes.infoTitle}>&#x0007C;</div>
            {
              num &&
              num[0] &&
              <div className={classes.infoTitle} style={{marginLeft: 15}}>
                T4A sent : {num[0].sent || 0}
              </div>
            }
          </div>
          <div style={{border: '1px solid black', borderRadius: 4}}>
            <CustomizedInputBase />
          </div>
        </div>
        <div className={classes.tableContent}>
          <div className={classes.tableTitle}>
            <div className={classes.tableSelected}>
              Selected: {rowsSelected.length}
            </div>
            <div
              className={classes.operationTitleHover}
              onClick={handleGenerateSend}
            >
              Generate and Send T4A
            </div>
            <div className={classes.taxGoup}>
              <div
                className={classes.operationTitle}
                style={{marginLeft: 5}}
                onClick={handleDownload}
              >
                Download Tax File
              </div>
              <div style={{marginLeft: 20}}>
                <Select
                  disableUnderline={true}
                  defaultValue={companyList[0].code}
                  value={company}
                  onChange={(e)=>{
                    setCompany(e.target.value)
                  }}
                >
                  {
                    companyList.map((company) => (
                      <MenuItem value={company.code}>{company.name}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.table}>
          <DriverTable input={input} handleSelectedRows={setRowsSelected}/>
        </div>
      </main>
      <Dialog
        open={openModal}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle
          style={{
            color: '#a3a4a0',
            fontSize: 22,
            fontWeight: 500
          }}
        >
          Importing drivers’ information
        </DialogTitle>
        <DialogContent>
          <div>
            <div>
              {
                selectedFile &&
                <div style={{marginBottom: 13}}>{selectedFile.name}</div>
              }
              <div>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                />
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 42, marginBottom: 10}}>
              <Button
                onClick={handleClose}
                disabled={progress !== 0}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={handleSubmit}
                disabled={progress !== 0}
              >
                Import
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <FullscreenLoading open={loading}/>
    </div>
  );
}

export default T4AMain