import {createContext, useState} from "react";

const defaultValue = {
  "id": null,
  "alias": null,
  "receipt_name": null,
  "address": null,
  "partner_id": null
}

export const CustomerContext = createContext();

export const CustomerContextProvider = ({ children }) => {
  // Define the state variable that holds the global variable
  const customer = JSON.parse(window.localStorage.getItem('customer_data'));
  const [customerData, setCustomerData] = useState(customer ?? defaultValue);

  // Provide the global variable and the update function as the value for the context
  const contextValue = {
    customerData,
    updateCustomerData: setCustomerData
  };

  return (
    <CustomerContext.Provider value={contextValue}>
      {children}
    </CustomerContext.Provider>
  );
};
