import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles} from '@material-ui/core/styles';
import {
  MenuItem, TextField, Button, Typography,
  Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress
} from '@material-ui/core';
import IconButton from "@mui/material/IconButton";
import Backdrop from '@mui/material/Backdrop';
import SearchIcon from '@mui/icons-material/Search';
import { shangjiaServerInstance } from '../../../axios/instances';
import { getRecentBatchList, getOrdersByCondition } from '../../../actions/storeActions';
import { Link } from 'react-router-dom';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ReactToPrint from 'react-to-print';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import {useCustomer} from "../../../utilities/hooks";
import LocalDeliveryDisplay from '../LocalDeliveryDisplay';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex',
  },
  dropDown: {
    width: '19%',
    height: 56,
    margin: "16px 12px",
  },
  datePicker: {
    width: '19%',
    height: 56,
    margin: "16px 12px",
  },
  iconButton: {
    marginLeft: 36
  },
  header: {
    display: "flex",
    marginLeft: 30,
  },
  breadCrumbs: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonText: {
    color: "#1976d2",
    width: 53,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  helperText: {
    fontSize: 10
  }
}));

export default function LocalDeliverySearch(props) {
  const classes = useStyles();
  const { t } = useTranslation('localDelivery');
  const dispatch = useDispatch();
  const componentRef = useRef();

  const { customerData } = useCustomer();
  const [batchList, setBatchList] = useState([]);
  const [batch, setBatch] = useState('');
  const [templateDialog, setTemplateOpen] = useState(false);
  const [uploadDialog, setUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [failOpen, setFailOpen] = useState(false);
  const [batchTyped, setBatchFromTextField] = useState('');
  const [trackingNo, setTrackingNo] = useState('');
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 12096e5).toLocaleDateString());
  const [toDate, setToDate] = useState(new Date().toLocaleDateString());
  const [goodsType, setgoodsType] = useState('');
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [orders,setOrders] = useState([]);


  const store = useSelector(state => state.store);

  const handleBatch = (event) => {
    if (event.target.value === t('create_batch')) {
      setBatch('');
    } else {
      setBatch(event.target.value);
    }
  };

  function handleView() {
    setLoading(true);
    if (!customerData) {
      setLoading(false);
      dispatch(showAlertSnackbar({message: 'Please Select a store', type: 'error'}));
    } else {
      dispatch(getOrdersByCondition(customerData.id, '', batchTyped, trackingNo, fromDate, toDate, goodsType,rows,page))
        .then((res) => {
          setLoading(false);
          setOrders(res);
        })
    }
  }
  function handleUpload(view) {
    setUploadOpen(false);
    setLoading(true);
    let formData = new FormData();
    if (batch === t('create_batch') || batch === '') {
      formData.append('add_batch', 1);
    } else {
      formData.append('add_batch', 0);
      formData.append('add_batch_no', batch);
    }
    formData.append('local_import', 3);
    formData.append('orders', selectedFile);
    formData.append('is_alert', 0);
    formData.append('customer_id', customerData.id);
    formData.append('partner_id', customerData.partner_id);
    formData.append('partner_shop_id', customerData.id);
    formData.append('partner_shop_id', customerData.id);
    formData.append('partner_shop_name', customerData.alias);
    formData.append('start_phone', customerData.phone);
    formData.append('start_address', customerData.address);
    formData.append('start_consignee', customerData.receipt_name);
    formData.append('pay_method', 8);
    formData.append('type', 2);
    shangjiaServerInstance.post(
      `/business/batchbusinessimport`,
      formData,
      { timeout: 60000 * 20 },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then((res) => {
        setSelectedFile(null);
        setBatchFromTextField('');
        setTrackingNo('');
        dispatch(getOrdersByCondition(customerData.id, '', '', '', fromDate, toDate,'',rows,page)).then((res) => {
          setOrders(res);
        })
        dispatch(getRecentBatchList('', customerData.id, 7))
          .catch(() => {
            setBatchList([t('create_batch')]);
            setBatch(t('create_batch'));
          })
        setLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
        setFailOpen(true);
      })
  }

  useEffect(() => {
    if (customerData) {
      if (customerData.id) {
        setLoading(true);
        dispatch(getRecentBatchList('', customerData.id, 7))
          .catch(() => {
            setBatchList([t('create_batch')]);
            setBatch(t('create_batch'));
          })
        dispatch(getOrdersByCondition(customerData.id, '', '', '', fromDate, toDate,'',rows,page))
          .then((res) => {
            setLoading(false);
            setOrders(res);
          })
      }
    } else {
      setBatchList([t('create_batch')]);
      setBatch(t('create_batch'));
    }
  }, [customerData, fromDate, toDate, rows, page, dispatch, t])

  useEffect(() => {
    if (store.getRecentBatchList) {
      let batchListCurr = [t('create_batch')];
      store.getRecentBatchList.forEach((batch) => {
        batchListCurr.push(batch.master_air_waybill);
      })
      setBatchList(batchListCurr);
    } else {
      setBatchList([t('create_batch')]);
      setBatch(t('create_batch'));
    }
  }, [store.getRecentBatchList, t]);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Local Delivery</p>

          {/*<Breadcrumbs aria-label="breadcrumb">*/}
          {/*  <Link*/}
          {/*    underline="hover"*/}
          {/*    color="inherit"*/}
          {/*    to="/main"*/}
          {/*  >*/}
          {/*    Home*/}
          {/*  </Link>*/}
          {/*  <Typography className={classes.breadCrumbText} color="text.primary">Local Delivery</Typography>*/}
          {/*</Breadcrumbs>*/}

        </div>
        <Button
          className={classes.button}
          style={{ marginRight: 36, }}
          startIcon={<InsertDriveFileOutlinedIcon />}
          onClick={() => setTemplateOpen(true)}>TEMPLATE</Button>
        <Button
          className={classes.button}
          style={{ marginRight: 30, }}
          startIcon={<CloudUploadOutlinedIcon />}
          onClick={() => setUploadOpen(true)}>BATCH UPLOAD</Button>

        <Dialog open={templateDialog} onClose={() => setTemplateOpen(false)}>
          <DialogTitle>Template</DialogTitle>
          <DialogContent>
            <br />
            <Typography>
              The template is for placing batch orders. Please download and fill out the excel template. Then Use the BATCH UPLOAD functions to upload the excel file and place orders.
            </Typography>
            <br />
            <br />
            <span style={{ backgroundColor: '#FFEE58', padding: '5px 5px 0px 5px' }}>{t('yellow')}</span> {t('yellow_required')}*
            <br />
            <span style={{ backgroundColor: '#29B6F6', padding: '5px 5px 0px 5px' }}>{t('blue')}</span> {t('blue_optional')}
            <br />
            <br />
          </DialogContent>

          <DialogActions style={{ marginBottom: 24 }}>
            <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setTemplateOpen(false)}>Cancel</Button>
            <Button
              variant='contained'
              className={classes.buttonSystem}
              style={{ marginRight: 24 }}
            >
              {process.env.REACT_APP_COUNTRY === 'US' ?
                <Link
                  to="/documents/upload_template_US.xlsx"
                  target="_blank"
                  download
                  style={{
                    color: 'white',
                    textDecoration: 'none'
                  }}
                  onClick={() => setTemplateOpen(false)}
                >
                  {'Template'}
                </Link>
                :
                <Link
                  to="/documents/upload_template.xlsx"
                  target="_blank"
                  download
                  style={{
                    color: 'white',
                    textDecoration: 'none'
                  }}
                  onClick={() => setTemplateOpen(false)}
                >
                  {'Template'}
                </Link>
              }
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth='md' open={uploadDialog} onClose={() => setUploadOpen(false)}>
          <DialogTitle>Batch Upload</DialogTitle>
          <DialogContent style={{ height: 200 }}>
            <div style={{ display: 'table-cell' }}>
              <TextField
                disabled={true}
                id="batch-upload-stores"
                label="Store"
                className={classes.dropDown}
                style={{ minWidth: 260, float: "left" }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={customerData.alias}
              />
              <TextField
                select
                label="Batch Number"
                className={classes.dropDown}
                InputLabelProps={{ shrink: true }}
                style={{ minWidth: 260 }}
                variant="outlined"
                value={batch ? batch : t('create_batch')}
                onChange={handleBatch}
                helperText="Select from the last 7 days of batches"
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
              >
                {batchList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <TextField
                label="File"
                type="file"
                style={{
                  width: 552,
                  margin: "16px 12px"

                }}
                className={classes.dropDown}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                helperText="Choose an Excel file to upload"
                FormHelperTextProps={{ classes: { root: classes.helperText } }}

              />
            </div>

          </DialogContent>
          <DialogActions style={{ marginBottom: 24 }}>
            <Button autoFocus className={classes.buttonText} style={{ marginRight: 24 }} onClick={() => setUploadOpen(false)}>Cancel</Button>
            <Button
              variant='contained'
              onClick={handleUpload}
              disabled={!customerData}
              className={classes.buttonSystem}
              style={{ marginRight: 24 }}
            >
              UPLOAD
            </Button>
          </DialogActions>
        </Dialog>

      </div>
      <div className={classes.searchBar}>
        <TextField
          label="Batch Number"
          className={classes.dropDown}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={batchTyped}
          onChange={(e) => setBatchFromTextField(e.target.value)}
        />
        <TextField
          label="Tracking Number"
          className={classes.dropDown}
          InputLabelProps={{ shrink: true }}
          value={trackingNo}
          onChange={(e) => setTrackingNo(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Uploading Time From"
          type="date"
          className={classes.datePicker}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <div style={{ marginTop: 20, display: 'inline-block' }}>_</div>
        <TextField
          label="Uploading Time To"
          type="date"
          className={classes.datePicker}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <TextField
          label="Parcel Category"
          className={classes.dropDown}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={goodsType}
          onChange={(e) => setgoodsType(e.target.value)}
        />
        <IconButton
          id={'local-delivery-search'}
          className={classes.iconButton}
          size={'large'}
          color={'primary'}
          onClick={handleView}
        >
          <SearchIcon fontSize={'inherit'}/>
        </IconButton>

      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog maxWidth='sm' fullWidth={true} open={failOpen} onClose={() => setFailOpen(false)}>
        <DialogTitle>Failed Uploading</DialogTitle>
        <DialogContent>
          <br />
          <Typography ref={componentRef}>
            {Array.isArray(errorMessage) ? errorMessage.map(msg => <p>{msg}</p>) : <p>{errorMessage}</p>}
          </Typography>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <ReactToPrint
            pageStyle="@page { size: 8.3in 11.7in }"
            trigger={() => <Button
              className={classes.buttonText} >Download</Button>}
            content={() => componentRef.current}
          />
          <Button className={classes.buttonText} style={{ marginRight: 24, marginLeft: 48 }} autoFocus onClick={() => setFailOpen(false)}>Cancel</Button>
          <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setFailOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      <LocalDeliveryDisplay importRes={orders} page={page} setPage={setPage} rows={rows} setRows={setRows} />
    </div >
  );
}