import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '85vh',
    overflow: 'auto',
  },
  containerStyle: {
    opacity: '1',
  },
  welcomeHeader: {
    fontWeight: 540,
    textAlign: 'left',
    letterSpacing: '0px',
    opacity: '1',
    '&.MuiTypography-root': {
      fontWeight: 540,
    },
  },
  welcomeText: {
    textAlign: 'left',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: '1',
  },
  paper: {
    padding: '20px',
    marginTop: '20px',
    background: '#FFFFFF',
    opacity: '1',
  },
  verticalLine: {
    paddingTop: '30px',
    borderRight: '1px solid #ccc',
    height: '100%',
  },
});

const WelcomePage = () => {

  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Grid container spacing={3}>

        {/* Left Section */}
        <Grid item xs={6} style={{minWidth: 700}}>

          {/* Upper Left: Welcome Message */}
          <Paper className={classes.containerStyle} elevation={0}>
            <div style={{
              backgroundImage: `url('/images/main_hp2.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              padding: '40px 40px 20px',
              boxSizing: 'border-box',
            }}>
              <div>
                <Typography variant={'h5'} className={classes.welcomeHeader} style={{ color: '#55A6FF', margin: '0' }}>
                  Good Day!
                </Typography>
                <Typography variant={'h5'} className={classes.welcomeHeader} style={{ color: '#000000', margin: '0' }}>
                  Start your day feeling calm and in control.
                </Typography>
              </div>
              <div>
                <Typography variant={'body1'} style={{ margin: '18px 0' }}>
                  Get ready to streamline your shipping processes and gain full control over your shipments.
                  Stay informed, stay organized, and enjoy the efficiency and convenience of our comprehensive shipping services.
                </Typography>
              </div>
            </div>
          </Paper>
          
          {/* Lower Left: MUI Calendar */}
          <Paper className={classes.paper} elevation={0} style={{ paddingLeft: 0 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} className={classes.verticalLine} style={{pointerEvents: 'none', cursor: 'default'}}>
                  <CalendarPicker date={dayjs()} style={{ width: '100%', height: 'auto' }}/>
                </Grid>
                <Grid item xs={12} md={6} style={{paddingTop: '40px', pointerEvents: 'none', cursor: 'default'}}>
                  <MonthPicker date={dayjs()} style={{ width: '100%', height: 'auto' }}/>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Paper>
        </Grid>

        {/* Right Section */}
        <Grid item xs={4}>
          {/* Picture on the right */}
          <img src="/images/main_hp1.png" alt="Welcome Pic" />
        </Grid>

      </Grid>
    </div>
  );
};

export default WelcomePage;
