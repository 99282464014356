import { useDispatch } from 'react-redux';
import {
  TextField, makeStyles, Button,
  DialogTitle, DialogContent,
  DialogActions, Dialog, Select, InputLabel, FormControl,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import {createUserAccount, editUserAccount} from "../../../actions/storeActions";
import {MenuItem} from "@mui/material";

const useStyle = makeStyles(() => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex'
  },
  textFields: {
    width: '556px',
  },
  textFieldShort: {
    width: '260px',
  },
  tableCell: {
    marginTop: '20px',
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#1976d2",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#1976d2",
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 640,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const ALERT = {
  SEARCH_SUCCESS  : "FETCH DATA SUCCESS",
  CREATE_SUCCESS  : "CREATE CUSTOMER SUCCESS",
  CREATE_FAILED   : "CREATE CUSTOMER FAILED",
  MODIFY_SUCCESS  : "MODIFY CUSTOMER SUCCESS",
  MODIFY_FAILED   : "MODIFY CUSTOMER FAILED",
  GET_ECOM_MERCHANT_NAMES_FAILED: "GET ECOM MERCHANT NAMES FAILED",
};

function EditCustomer(props) {
  const {
    openDialog, customerInfo, closeDialog,
    setLoading, partners, ecom, merchant,
    setReloadPage
  } = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [newEcom, setNewEcom] = useState(false);
  const [newMerchant, setNewMerchant] = useState(false);
  const [newPartner, setNewPartner] = useState(false);

  const [title, setTitle] = useState('Edit Customer');
  const [customer, setCustomer] = useState([]);

  const handleCreateUser = async () => {
    setLoading(true);
    try {

      const params = {
        name: customer.alias,
        email: customer.email,
        address: customer.address,
        // locality_type: Number(customer.locality_type) || null,
        contact: customer.receipt_name || null,
        phone: customer.phone || null,
        partner_id: Number(customer.partner_id) || null,
        partner_name: customer.partner_name || null,
        merchant: customer.merchant || null,
        ecom_name: customer.ecom_name || null,
      };

      await dispatch(createUserAccount(params));
      dispatch(showAlertSnackbar({ title: ALERT.CREATE_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ title: ALERT.CREATE_FAILED, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    setLoading(true);
    try {
      const params = {
        id: customer.id,
        address: customer.address,
        receipt_name: customer.receipt_name || null,
        phone: customer.phone || null,
        merchant: customer.merchant || null,
        ecom_name: customer.ecom_name || null,
      }
      await dispatch(editUserAccount(params));
      dispatch(showAlertSnackbar({ title: ALERT.MODIFY_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ title: ALERT.MODIFY_FAILED, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitUser = async () => {
    try {
      await (editMode ? handleUpdateUser() : handleCreateUser());
    } catch (e) {
      console.error(e);
    } finally {
      setNewEcom(false);
      setNewMerchant(false);
      setNewPartner(false);
      setReloadPage(true);
      handleClose();
    }
  };

  const handleInputChange = (field) => (e) => {
    const { value } = e.target;

    setCustomer(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSelectChange = (field) => (e) => {
    const { value } = e.target;
    const isAdd = value === 'add';

    switch (field) {
      case 'merchant':
        setNewMerchant(isAdd);
        break;
      case 'ecom_name':
        setNewEcom(isAdd);
        break;
      case 'partner_id':
        setNewPartner(isAdd);

        setCustomer((prev) => ({
          ...prev,
          partner_id: isAdd ? null : value,
        }));
        return;
      default:
        break;
    }

    if (!isAdd) {
      setCustomer((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  function handleClose() {
    setOpen(false);
    closeDialog();
  }

  useEffect(() => {
    setOpen(openDialog);
    setCustomer(customerInfo);
  }, [openDialog, customerInfo])

  useEffect(() => {
    if (customerInfo?.id) {
      setEditMode(true);
      setTitle('Edit Customer');
    } else {
      setEditMode(false);
      setTitle('New Customer');
    }

    if (!open) {
      setCustomer([]);
      setNewEcom(false);
      setNewMerchant(false);
      setNewPartner(false);
    }
  }, [customerInfo, open]);

  // useEffect(() => {
  //   console.log('customer: ', customer);
  // }, [customer])

  return (
    <Dialog maxWidth='lg' open={open} onClose={handleClose}>

      <DialogTitle sx={{ m: 0, p: 2 }} id={'dialog-title'}>
        {title}
      </DialogTitle>

      <DialogContent dividers={true}>

        <div className={classes.tableCell}>
          {editMode && (
            <TextField
              disabled={editMode}
              className={classes.textFields}
              id="dialog-client-secret"
              label="Client Secret"
              InputLabelProps={{shrink: true}}
              variant="outlined"
              value={customer['client_secret']}
            />
          )}
        </div>
        <div className={classes.tableCell}>
          {editMode && (
            <>
              <TextField
                disabled={editMode}
                className={classes.textFieldShort}
                id="dialog-client-id"
                label="Client ID"
                InputLabelProps={{shrink: true}}
                variant="outlined"
                value={customer['client_id']}
              />
              <TextField
                disabled={editMode}
                className={classes.textFieldShort}
                style={{marginLeft: '36px'}}
                id="dialog-customer-id"
                label="Customer ID"
                InputLabelProps={{shrink: true}}
                variant="outlined"
                value={customer['id']}
              />
            </>
          )}
        </div>

        <div className={classes.tableCell}>
          <TextField
            disabled={editMode}
            className={classes.textFields}
            id={'dialog-customer-name'}
            label="Customer Name"
            InputLabelProps={{shrink: true}}
            variant="outlined"
            value={customer['alias']}
            onChange={handleInputChange('alias')}
          />
        </div>
        <div className={classes.tableCell}>
          <TextField
            disabled={editMode}
            className={classes.textFields}
            id={'dialog-contact-email'}
            label="Contact Email"
            InputLabelProps={{shrink: true}}
            variant="outlined"
            value={customer['email']}
            onChange={handleInputChange('email')}
          />
        </div>
        <div className={classes.tableCell}>
          <TextField
            className={classes.textFields}
            id={'dialog-street-address'}
            label="Street Address"
            InputLabelProps={{shrink: true}}
            variant="outlined"
            value={customer['address']}
            onChange={handleInputChange('address')}
          />
        </div>
        <div className={classes.tableCell}>
          <TextField
            className={classes.textFieldShort}
            id={'name-on-invoice'}
            label="Name On Invoice"
            InputLabelProps={{shrink: true}}
            variant="outlined"
            value={customer['receipt_name']}
            onChange={handleInputChange('receipt_name')}
          />
          <TextField
            className={classes.textFieldShort}
            style={{marginLeft: '36px'}}
            id={'dialog-contact-number'}
            label="Contact Number"
            InputLabelProps={{shrink: true}}
            variant="outlined"
            value={customer['phone']}
            onChange={handleInputChange('phone')}
          />
        </div>
        <div className={classes.tableCell}>
          <FormControl variant="outlined" className={classes.textFields}>
            <InputLabel id="dialog-partner-id-label">Partner</InputLabel>
            <Select
              disabled={editMode}
              labelId="dialog-partner-id-label"
              id="dialog-partner-id"
              value={newPartner ? 'add' : customer['partner_id']}
              onChange={handleSelectChange('partner_id')}
              label="Partner"
            >
              <MenuItem value={'add'}>
                Add a new Partner
              </MenuItem>
              {partners.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {`${partner.id}: ${partner.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.tableCell}>
          {newPartner && (
            <TextField
              variant="outlined"
              label="New Partner"
              className={classes.textFields}
              value={customer['partner_name']}
              onChange={handleInputChange('partner_name')}
            />
          )}
        </div>
        <div className={classes.tableCell}>
          <FormControl variant="outlined" className={classes.textFields}>
            <InputLabel id="dialog-merchant-label">Sales Channel</InputLabel>
            <Select
              labelId="dialog-merchant-label"
              id="dialog-merchant"
              value={newMerchant ? 'add' : customer['merchant']}
              onChange={handleSelectChange('merchant')}
              label="Sales Channel"
            >
              <MenuItem value={'add'}>
                Add a new Sales Channel
              </MenuItem>
              {merchant.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.tableCell}>
          {newMerchant && (
            <TextField
              variant="outlined"
              label="New sales channel"
              className={classes.textFields}
              value={customer['merchant']}
              onChange={handleInputChange('merchant')}
            />
          )}
        </div>
        <div className={classes.tableCell}>
          <FormControl variant="outlined" className={classes.textFields}>
            <InputLabel id="dialog-ecom-label">E-commerce Platform</InputLabel>
            <Select
              labelId="dialog-ecom-label"
              id="dialog-ecom"
              value={newEcom ? 'add' : customer['ecom_name']}
              onChange={handleSelectChange('ecom_name')}
              label="E-commerce Platform"
            >
              <MenuItem value={'add'}>
                Add a new E-com platform
              </MenuItem>
              {ecom.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.tableCell}>
          {newEcom && (
            <TextField
              variant="outlined"
              label="New E-com platform"
              className={classes.textFields}
              value={customer['ecom_name']}
              onChange={handleInputChange('ecom_name')}
            />
          )}
        </div>

        <div className={classes.tableCell}>
          {editMode && (
            <>
              <TextField
                disabled={editMode}
                className={classes.textFieldShort}
                id="dialog-updated_at"
                label="Last Edit Time"
                InputLabelProps={{shrink: true}}
                variant="outlined"
                value={customer['updated_at']}
              />
              <TextField
                disabled={editMode}
                className={classes.textFieldShort}
                style={{marginLeft: '36px'}}
                id="dialog-customer-id"
                label="Last Operator"
                InputLabelProps={{shrink: true}}
                variant="outlined"
                value={customer['operator']}
              />
            </>
          )}
        </div>

      </DialogContent>

      <DialogActions style={{marginBottom: 24}}>
        <Button
          id={'cancel-customer-dialog'}
          autoFocus
          className={classes.buttonText}
          style={{marginRight: 24}}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          id={'save-customer'}
          variant='contained'
          onClick={handleSubmitUser}
          className={classes.buttonSystem}
          style={{marginRight: 24}}
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditCustomer;