import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import QuickQuote from '../QuickQuote';
import NewOrder from '../NewOrder';

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    minWidth: 1594,
    marginLeft: 30,
  },
  breadCrumbs: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
}));

export default function PostalOutletPlaceOrder() {
  const classes = useStyles();
  const { t } = useTranslation('postalOutlet');

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "10px 0 1px" }}>{t('place_order')}</p>

          {/*<Breadcrumbs aria-label="breadcrumb">*/}
          {/*  <Link*/}
          {/*    underline="hover"*/}
          {/*    color="inherit"*/}
          {/*    to="/main"*/}
          {/*  >*/}
          {/*    {t('home')}*/}
          {/*  </Link>*/}
          {/*  <Typography className={classes.breadCrumbText}>{t('place_order')}</Typography>*/}
          {/*</Breadcrumbs>*/}

        </div>
      </div>
      <div style={{ display: "flex" }}>
        <QuickQuote/>
        <NewOrder/>
      </div>
    </div >
  );
}