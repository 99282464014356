import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {makeStyles, CssBaseline,} from '@material-ui/core';
import ApiUI from '../../components/SandboxTest/ApiUI';
import { getApiDescription } from '../../actions/sanboxTestActions';
import Profile from '../../components/SandboxTest/Profile';
import Typography from "@material-ui/core/Typography";
import PrmFrame from "../../components/PrmFrame";
import {showAlertSnackbar} from "../../reducers/uiSlice";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";
import FullscreenLoading from '../../components/FullscreenLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 15
  },
  contentMain: {
    paddingLeft: 24,
    paddingRight: 24
  },
  contentTitle: {
    fontSize: 21,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#262626'
  },
  apiUI: {
    marginTop: 16,
    marginBottom: 16
  }
}));

const SANDBOX_ACCESS_ID = 2;

function SandboxTest() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('sandboxTest');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(true);
  const [menuIndex, setMenuIndex] = useState(0);

  function handleToken(accessToken) {
    setToken(accessToken)
  }

  useEffect(() => {
    async function loadSandBox() {
      try {
        setLoading(true);
        await dispatch(getApiDescription());
      } catch (e) {
        dispatch(showAlertSnackbar({title: 'LOAD SANDBOX FAILED'}));
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    loadSandBox()
  }, [dispatch])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={SANDBOX_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />

      <PrmMain
        className={classes.content}
        open={open}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <Typography paragraph>
            <div className={classes.contentMain}>
              <div className={classes.contentTitle}>
                {t('uni_api_sandbox')}
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='retrieve-token' handleToken={handleToken}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='check-service-availability' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='create-batch' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='create-order' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='get-orders' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='update-order-bag-no' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='cancel-order' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='order-verification'/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='print-label' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='push-tracking' token={token}/>
              </div>
              <div className={classes.apiUI}>
                <ApiUI type='estimate-shipping' token={token}/>
              </div>
            </div>
          </Typography>
        </div>
        <div hidden={menuIndex!==1}>
          <Typography paragraph>
            <div className={classes.contentMain}>
              <div>
                <div className={classes.contentTitle}>
                  Account
                </div>
                <div style={{marginTop: 14}}>
                  <Profile type='account'/>
                </div>
              </div>
              <div style={{marginTop: 50}}>
                <div className={classes.contentTitle}>
                  Contact Information
                </div>
                <div style={{marginTop: 14}}>
                  <Profile type='contact'/>
                </div>
              </div>
            </div>
          </Typography>
        </div>
      </PrmMain>
      <FullscreenLoading open={loading} />
    </div>
  );
}

export default SandboxTest;