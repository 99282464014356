import React, {useState} from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import PostalOutletPlaceOrder from '../../components/PostalOutlet/PostalOutletPlaceOrder';
import PostalOutletOrders from '../../components/PostalOutlet/PostalOutletOrders';
import PrmFrame from "../../components/PrmFrame";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    // marginTop: 10
  }
}));

const POSTAL_OUTLET_ACCESS_ID = 5;

function PostalOutletMain() {
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={POSTAL_OUTLET_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
      <PrmMain
        className={classes.content}
        open={open}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <PostalOutletPlaceOrder />
        </div>
        <div hidden={menuIndex!==1}>
          <PostalOutletOrders />
        </div>
      </PrmMain>
    </div>
  );
}

export default PostalOutletMain;