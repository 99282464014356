import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Checkbox,
  // Select,
  // MenuItem,
  // FormControl,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { prmLogin } from '../../actions/userActions';
import FullscreenLoading from '../../components/FullscreenLoading';
import { showAlertSnackbar } from '../../reducers/uiSlice';
import { useAlert } from '../../utilities/hooks';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from "@material-ui/icons/Lock";
// import LanguageIcon from '@mui/icons-material/Language';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Link from '@mui/material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  loginForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginButton: {
    marginTop: '40px',
    backgroundColor: '#252525',
    color: '#ffffff'
  },
  sideImg: {
    height: '100vh',
    width: '42.7%',
    backgroundPosition: "left",
    backgroundSize: "auto",
    backgroundRepeat: 'no-repeat'
  },
  sideLogoImg: {
    position: "absolute",
    width: "170px",
    height: "40px",
    top: "2.3vh",
    left: "2vh"
  },
  logoImg: {
    width: "170px",
    height: "40px",
    marginBottom: '24px',
  },
  textField: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  welcomeMsg: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: "left",
    width: '100%',
    color: '#2a3848',
    fontSize: 32,
    fontWeight: 600,
    marginTop: 40
  },
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
    width: '100%',
    marginBottom: 5,
    color: '#577699',
    fontSize: 48,
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  subTitle: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: "left",
    width: '100%',
    marginBottom: 40,
    color: '#577699',
    fontSize: 24,
    fontWeight: 300
  },
  footer: {
    // position: 'fix',
    // left: 0,
    // bottom: 0,
    // width: '100%',
    color: '#333',
    textAlign: 'center',
    paddingTop: '30px',
    fontSize: '16px',
    margin: 0
  }
}));

export default function Login() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  // const [language, setLanguage] = useState('en');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const alertDialog = useAlert();

  const user = useSelector(state => state.user);
  const common = useSelector(state => state.common);

  // const languageList = [
  //   {
  //     name: "中文",
  //     type: "cn"
  //   },
  //   {
  //     name: "English",
  //     type: "en"
  //   }
  // ]

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (username.length === 0 || password.length === 0) {
      dispatch(showAlertSnackbar({ title: "Username or password cannot be empty!" }));
      return;
    }
    if(checked) {
      // window.localStorage.setItem('username', username);
      console.log('USER REMEMBER ME');
    }
    try {
      await dispatch(prmLogin(username, password));
      window.localStorage.setItem('username', username);
      history.push('/main');
    } catch (e) {
      alertDialog.addAlertDialog("LOGIN", e.message);
      alertDialog.showAlert();
    }
  }

  const handleRememberMe = (event) => {
    setChecked(event.target.checked);
  };

  // TODO
  // function handleForgetPassword() {
  //   console.log('user forgot password');
  // }

  return (
    <div className={classes.root}>
      <Box component='img' sx={{ display:  { xs: 'none', sm: 'none', md: 'block' } }} src={'./images/login_img.png'} className={classes.sideImg} alt='' />
      <Box component='img' sx={{ display:  { xs: 'none', sm: 'none', md: 'block' } }} src={'./images/icons/icon_white.png'} className={classes.sideLogoImg} alt='' />
      <Box sx={{ flex: 1, overflow: 'auto', padding: '24px' }}>
        <Box maxWidth="sm" className={classes.loginForm}>
          <form style={{flexDirection: "column"}}>
            <main style={{flexGrow: 1}}>
              <Box component='img' sx={{ display:  { xs: 'block', sm: 'block', md: 'none' } }} src={'./images/icons/icon.png'} className={classes.logoImg} alt='' />
              <Link
                href='https://docs.uniuni.com/'
                target='_blank'
                sx={{
                  color: '#ff9800',
                  textDecorationColor: '#ff9800',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                UniUni Integration API Docs
                <ArrowForwardIcon
                  fontSize='small'
                  sx={{
                    marginLeft: '6px'
                  }}
                />
              </Link>
              <div className={classes.welcomeMsg}>{"Welcome to"}</div>
              <div className={classes.header}>{"UniUni PRM System"}</div>
              <div className={classes.subTitle}>{"Collaboration · Management · Analysis"}</div>
              <Box style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: 25, fontSize:32, fontWeight:600}}>
                <div>{"Sign In"}</div>
                {/*<div style={{display: 'flex', alignItems: 'center'}}>*/}
                {/*  <LanguageIcon style={{marginRight: 10}}/>*/}
                {/*  <FormControl size={"small"} style={{width: 80}}>*/}
                {/*    <Select*/}
                {/*      value={language}*/}
                {/*      onChange={(e)=>{setLanguage(e.target.value)}}*/}
                {/*    >*/}
                {/*      {languageList.map((language) => (*/}
                {/*        <MenuItem value={language.type} key={language.type}>*/}
                {/*          {language.name}*/}
                {/*        </MenuItem>*/}
                {/*      ))}*/}
                {/*    </Select>*/}
                {/*  </FormControl>*/}
                {/*</div>*/}
              </Box>

              <TextField
                className={classes.textField}
                label={'Account Name'}
                variant={'outlined'}
                fullWidth
                InputProps={{
                 startAdornment: (
                   <InputAdornment position="start">
                     <PersonIcon />
                   </InputAdornment>
                 ),
                }}
                onChange={(e) => setUsername(e.target.value)}
              />

              <TextField
                className={classes.textField}
                id={'login_password_textfield'}
                type={showPassword ? 'text' : 'password'}
                label={'Password'}
                variant={'outlined'}
                fullWidth
                InputProps={{
                 startAdornment: (
                   <InputAdornment position="start">
                     <LockIcon />
                   </InputAdornment>
                 ),
                 endAdornment: (
                   <InputAdornment position="end">
                     <IconButton
                       id={'showpassword_login_button'}
                       aria-label="toggle password visibility"
                       onClick={handleClickShowPassword}
                       onMouseDown={handleMouseDownPassword}
                       edge="end"
                     >
                       {showPassword ? <VisibilityOff /> : <Visibility />}
                     </IconButton>
                   </InputAdornment>
                 )
                }}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize:14}}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox onChange={handleRememberMe}/>} label="Remember me" />
                </FormGroup>
                {/* <Button style={{textTransform: 'none', color: '#1890ff'}} onClick={handleForgetPassword}>{"Forget Password?"}</Button> */}
              </Box>

              <Button
                className={classes.loginButton}
                style={{textTransform: 'none'}}
                type={"submit"}
                variant={"contained"}
                color={'primary'}
                fullWidth
                size={'large'}
                onClick={handleLogin}
              >
                {'Sign In'}
              </Button>

            </main>
            <footer>
              <p className={classes.footer}>
                © UniUni {new Date().getFullYear()}. All rights reserved.
              </p>
            </footer>

          </form>
        </Box>
      </Box>

      <FullscreenLoading open={user.loading || common.loading} />

    </div>
  );

}