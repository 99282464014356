import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormControlLabel, Radio, Button, Typography, RadioGroup, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { checkServiceAvailability,estimateShipping } from '../../../actions/sanboxTestActions';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { showAlertSnackbar } from "../../../reducers/uiSlice";


const useStyles = makeStyles((theme) => ({
  inputArea: {
    height: 56,
    width: "100%",
    margin: "20px 0px 20px",
  },
  iconButton: {
    color: "#1976d2",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.04)",
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.26)",
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  header: {
    display: "flex",
    minWidth: 1594,
    marginLeft: 30,
  },
  breadCrumbs: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  quickQuote: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: 25,
    padding: "25px 25px 25px",
    width: "17%",
    display: "block",
    justifyContent: "center"
  },
  dispayArea: {
    padding: "12px 40px 12px",
    backgroundColor: "#fcf4ed",
    margin: "25px 0px 8px"
  },
  quote: {
    color: "#ff9d00",
    fontSize: 48,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center"
  },
  eta: {
    fontSize: 20,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
  },
  radioButton: {
    color: "rgba(0, 0, 0, 0.6)",
    '&.Mui-checked': {
      color: "#1976d2"
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
  radioTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '0.12px',
    color: "#616161",
    marginRight: 12,
    marginTop: 19,
    width: '20%'
  },
  radioText: {
    margin: '2px 0 1px',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  clearButton: {
    margin: "30px 20px 20px",
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#2a3848"
  },
  quoteButton: {
    height: "36px",
    padding: "6px 16px",
    borderRadius: " 4px",
    margin: "30px 20px 20px",
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    backgroundColor: "#2a3848",
    color: "#fff",
    '&:disabled': {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  errorButton:{
    color:"red"
  },
  correctButton:{
    color:"green"
  }
}));

export default function QuickQuote() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('postalOutlet');

  const [price, setPrice] = useState("$");
  const [eta, setEta] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weightUnit, toggleWeightUnit] = useState('lb');
  const [dimUnit, toggleDimUnit] = useState('in');
  const [unitType, setUnitType] = useState('imperial');
  const [postalCodeError, setError] = useState(false);
  const [postalCodeValidated, setValidated] = useState(false);
  const [errorMessage, setErroMessage] = useState("");
  const [submitEnabled, setEnabled] = useState(false);

  const clearForm = (e) => {
    e.preventDefault();
    setpostalCode("");
    setWeight('');
    setLength("");
    setWidth("");
    setHeight("");
    setErroMessage("");
    setError(false);
    setEnabled(false);
    setValidated(false);
    setPrice("$");
    setEta("");
  }

  useEffect(() => {
    let enable = false;
    if (postalCodeValidated && !postalCodeError && weight && length && height && width) {
      if(!isNaN(weight) && !isNaN(length) && !isNaN(height) && !isNaN(width))
        enable = true;
    }
    setEnabled(enable);
  }, [postalCodeValidated, postalCodeError, weight, length, height, width]);

  const handleUnits = (e) => {
    if (e.target.value === 'imperial') {
      setUnitType(e.target.value);
      toggleWeightUnit('lb');
      toggleDimUnit('in');
    }
    if (e.target.value === 'metric') {
      setUnitType(e.target.value);
      toggleWeightUnit('kg');
      toggleDimUnit('cm');
    }
  }

  function validatePostalCode() {
    dispatch(checkServiceAvailability({ "postal_code": postalCode }))
      .then((res) => {
        setValidated(true);
        setError(false);
        setErroMessage("");
      })
      .catch((err) => {
        setValidated(true);
        setError(true);
        setErroMessage("This area is not in our service");
      })
  }

  function getPrice(){
    let params = {
      "start_postal_code":window.localStorage.getItem("start_postal_code"),
      "postal_code":postalCode,
    };
    if (dimUnit === 'in') {
      params["length"] = length * 2.54;
      params["width"] = width * 2.54;
      params["height"] = height * 2.54;
      params["weight"] = weight / 2.205;
    } else {
      params["length"] = length;
      params["width"] = width;
      params["height"] = height;
      params["weight"] = weight;
    }
    dispatch(estimateShipping(params))
      .then((res) => {
        setPrice("$"+res.data.totalAfterTax);
        setEta("ETA In "+res.data.eta+ " days");
      })
      .catch((err) => {
        setPrice("$");
        setEta("");
        dispatch(showAlertSnackbar({ type: "error", message: err.ret_msg}));
      })
  }

  return (
    <div id="quickQuote" className={classes.quickQuote}>
      <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>{t('quick_quote')}</p>
      <div className={classes.dispayArea}>
        <Typography className={classes.quote}>{price}</Typography>
        <Typography className={classes.eta}>{eta}</Typography>
      </div>
      <p style={{ color: "rgba(0, 0, 0, 0.6)", textAlign: "center", fontSize: 10 }}>{t('quote_message')}</p>
      <TextField
        id="quickQuote.postalCode"
        error={postalCodeError}
        helperText={errorMessage}
        label={t('postal_code')}
        className={classes.inputArea}
        variant="outlined"
        value={postalCode}
        onChange={(e) => { setpostalCode(e.target.value); setError(false); setErroMessage(""); setValidated(false); setPrice("$"); setEta(""); }}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton id="quickQuote.postalCode.validationButton" onClick={validatePostalCode} edge="end">
                {postalCodeValidated ?
                  (postalCodeError ? <CancelIcon className={classes.errorButton} /> :
                    <CheckCircleIcon className={classes.correctButton} />)
                  : <SearchIcon className={classes.iconButton} />}
              </IconButton>
            </InputAdornment>
        }}
      />
      <TextField
        id="quickQuote.parcelWeight"
        label={t('parcel_weight') + "*"}
        className={classes.inputArea}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              {weightUnit}
            </InputAdornment>
        }}
        variant="outlined"
        value={weight}
        onChange={(e) => {setWeight(e.target.value); setPrice("$"); setEta("");}}
        error={weight!=="" && isNaN(weight)}
      />
      <TextField
        id="quickQuote.parcelLength"
        label={t('parcel_length') + "*"}
        className={classes.inputArea}
        variant="outlined"
        value={length}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              {dimUnit}
            </InputAdornment>
        }}
        onChange={(e) => {setLength(e.target.value); setPrice("$"); setEta("");}}
        error={length!=="" && isNaN(length)}
      />
      <TextField
        id="quickQuote.parcelWidth"
        label={t('parcel_width') + "*"}
        className={classes.inputArea}
        variant="outlined"
        value={width}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              {dimUnit}
            </InputAdornment>
        }}
        onChange={(e) => {setWidth(e.target.value); setPrice("$"); setEta("");}}
        error={width!=="" && isNaN(width)}
      />
      <TextField
        id="quickQuote.parcelHeight"
        label={t('parcel_height') + "*"}
        className={classes.inputArea}
        variant="outlined"
        value={height}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              {dimUnit}
            </InputAdornment>
        }}
        onChange={(e) => {setHeight(e.target.value); setPrice("$"); setEta("");}}
        error={height!=="" && isNaN(height)}
      />
      <div style={{ display: "flex", alignItems: "flext-start" }}>
        <p className={classes.radioTitle}>{t('unit')}</p>
        <RadioGroup id="quickQuote.unitToggle" style={{ width: "80%", margin: "5px 0px 0px" }} value={unitType} onChange={handleUnits} >
          <FormControlLabel
            id="quickQuote.unitToggle.imperial"  value="imperial"
            control={<Radio className={classes.radioButton} />}
            label={<Typography className={classes.radioText}>{t('imperial_units')}</Typography>}
          />
          <FormControlLabel
            id="quickQuote.unitToggle.metric" value="metric"
            control={<Radio className={classes.radioButton} />}
            label={<Typography className={classes.radioText}>{t('metric_system')}</Typography>}
          />
        </RadioGroup>

      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.clearButton} onClick={clearForm} >{t('clear')}</Button>
        <Button className={classes.quoteButton} disabled={!submitEnabled} onClick={getPrice}>{t('quote')}</Button>
      </div>
    </div>
  );
}