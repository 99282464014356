import { createTheme } from '@material-ui/core/styles';
import * as Color from '@material-ui/core/colors';

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: Color.orange[500]
        },
        secondary: {
            main: Color.deepOrange[900]
        }
    }
})

export default lightTheme