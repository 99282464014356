import React, { useState, useEffect, useCallback, } from 'react';
import { useToken } from '../../utilities/hooks';
import { useDispatch, useSelector, } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Users from "./Users";
import Roles from "./Roles";

import {
  Box, Typography, Divider, FormControl, TextField, MenuItem,
  FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { getAbilityList, getRoleList, getUserList, } from '../../actions/userManagementActions';
import FullscreenLoading from "../../components/FullscreenLoading";
import { showAlertSnackbar } from '../../reducers/uiSlice';
import { getCustomers } from "../../actions/storeActions";

const useStyles = makeStyles(
  (theme) => ({

    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      height: '100vh'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: 16,
    },
    close: {
      // color: 'white',
      margin: '0 0 0 auto',
    },
    title: {
      // color: '#e1e1e1',
    },
    loading: {
      display: 'block',
      margin: 'auto auto auto auto',
    },

  })
);

export default function UserManagement() {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenContents = useToken();
  const customerList = useSelector(state => state.managementReducer.customers);
  const {t} = useTranslation('userManagement');
  // const authorizedCustomers = tokenContents.customer;
  const [managementType, setManagementType] = useState('users');
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(tokenContents.customer[0]);
  const [abilitiesList, setAbilitiesList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  // const [filteredUsersByCustomer, setFilteredUsersByCustomer] = useState([]);


  const fetchData = useCallback(async () => {
    try {
      const [abilities, roles, users] = await Promise.all([
        dispatch(getAbilityList()),
        dispatch(getRoleList()),
        dispatch(getUserList()),
      ]);

      setAbilitiesList(
        abilities.map(
          (ability) => ({
            id: ability.id,
            name: ability.name,
            title: ability.title,
            type: ability.type,
          })
        )
      );

      setRolesList(
        Object.entries(roles).map(
          (role) => ({
            id: role[1].id,
            name: role[1].name,
            title: role[1].title,
            abilities: role[1].abilities,
          })
        )
      );

      setUsersList(
        Object.entries(users).map(
          (user) => ({
            id: user[1].id,
            username: user[1].username,
            // customer: user[1].customer,
            customer: !user[1].customer ? 0 : user[1].customer.split(',').filter((customer) => (customer !== '')).map(Number),
            roles: user[1].roles,
          })
        )
      );

    } catch (error) {
      // May have to throw error up the stack
      // throw error
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          dispatch(getCustomers()),
          fetchData(),
        ]);
      } catch (error) {
        showSnack('error')("Failed to retrieve data from server.");
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setFilteredUsersByCustomer(
  //     customer === -1 ?
  //       usersList.filter(
  //         (user) => (
  //           user.customer.length === 0 ?
  //             true :
  //             user.customer.some(
  //               (customer) => (authorizedCustomers.includes(customer))
  //             ))
  //       )
  //       :
  //       usersList.filter(
  //         (user) => (user.customer.includes(customer))
  //       )
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [usersList, customer]);

  const snackbarFactory = (duration) => (title) => (type) => (message) => {
    dispatch(showAlertSnackbar({
      // title: t('title_quick_transfer'),
      title: title,
      // message: `${t('import_correct_info')}`,
      message: message,
      type: type,
      duration: duration,
    }));
  };

  const showSnack = snackbarFactory(6000)("Access Management");

  const handleClose = () => {
    setLoading(true);
    // history.goBack();
    history.push('main');
  };

  return (<>
    <FullscreenLoading open={loading} />
    <Box className={classes.root}>
      {/*<UserAuthUpdate />*/}
      <Box className={classes.header}>
        <Typography className={classes.title} variant='h6'>
          {t('title_user_management')}
        </Typography>

        <FormControl>
          <RadioGroup row
                      defaultValue='users'
                      onChange={(e) => { setManagementType(e.target.value) }}
                      style={{ marginLeft: 20 }}
          >
            <FormControlLabel className={classes.title}
                              value='users'
                              control={<Radio />}
                              label="Users"
            />
            <FormControlLabel className={classes.title}
                              value='roles'
                              control={<Radio />}
                              label="Roles"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          select
          variant="outlined"
          size="small"
          onChange={(e) => { setCustomer(e.target.value) }}
          value={customerList.length === 0 ? '' : customer}
        >
          <MenuItem key={-1} value={-1}>
            {t('all_customer')}
          </MenuItem>
          {
            // customerList &&
            // customerList.filter(
            //   (customer) => (authorizedCustomers.includes(customer.id))
            // ).map(
            //   (customer) => (
            //     <MenuItem key={customer.id} value={customer.id}>
            //       {customer.alias}
            //     </MenuItem>
            //   )
            // )
            customerList &&
            customerList.map(
              (customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.alias}
                </MenuItem>
              )
            )
          }
        </TextField>

        <CloseIcon className={classes.close} onClick={handleClose} />
      </Box>

      <Divider />

      {(() => {

        switch (managementType) {
          case 'users':
            return (
              <Users
                rolesList={rolesList}
                abilitiesList={abilitiesList}
                usersList={usersList}
                // usersList={filteredUsersByCustomer}
                customer={customer}
                fetchData={fetchData}
                setLoading={setLoading}
                showSnack={showSnack}
              />
            );
          case 'roles':
            return (
              <Roles
                rolesList={rolesList}
                abilitiesList={abilitiesList}
                usersList={usersList}
                // usersList={filteredUsersByCustomer}
                fetchData={fetchData}
                setLoading={setLoading}
                showSnack={showSnack}
              />
            );
          default:
            return null;
        }

      })()}

    </Box>

  </>);

}