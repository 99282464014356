import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  darkTheme: false,
  openAlertSnackbar: false,
  alertObject: {
    type: 'error',
    title: undefined,
    message: 0,
    position: {
      vertical: "top",
      horizontal: "center"
    },
    duration: 5000
  },
  openEditOrderModal: false,
  editOrderInit: {
    orderIdentifier: null,
    type: 'order_id'
  },
  openPrealertFormDialog: {
    open: false,
  },
  openBusinessFormDialog: {
    open: false,
  }
}

export const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showAlertSnackbar: (state, action) => {
      state.alertObject = {
        ...state.alertObject,
        type: action.payload.type || "error",
        duration: action.payload.duration || 6000,
        title: action.payload.title,
        message: action.payload.message
      }
      state.openAlertSnackbar = true
    },
    hideAlertSnackbar: (state) => {
      state.openAlertSnackbar = false
      // state.alertObject = initialState.alertObject
    },
    handleEditOrderModal: (state, action) => {
      if(action.payload.orderIdentifier){
        state.editOrderInit = {
          orderIdentifier: action.payload.orderIdentifier,
          type: action.payload.type
        }
      }
      if(!action.payload.open){
        state.editOrderInit = initialState.editOrderInit
      }
      state.openEditOrderModal = action.payload.open
    },
    handleOpenPrealertFormDialog: (state, action) => {
      state.openPrealertFormDialog = action.payload
    },
    handleOpenBusinessFormDialog: (state, action) => {
      state.openBusinessFormDialog = action.payload
    },
  }
})

export const {
  hideAlertSnackbar,
  showAlertSnackbar,
  handleEditOrderModal,
  handleOpenPrealertFormDialog,
  handleOpenBusinessFormDialog,
} = slice.actions;

export const currentTheme = state => state.ui.darkTheme;
export const openAlertSnackbar = state => state.ui.openAlertSnackbar;
export const openEditOrderModal = state => state.ui.openEditOrderModal;
export const getOpenPrealertFormDialog = state => state.ui.openPrealertFormDialog;
export const getOpenBusinessFormDialog = state => state.ui.openBusinessFormDialog;

export default slice.reducer;