import { shangjiaServerInstance } from "../axios/instances";
import { 
    FETCH_DRIVER_LIST, 
    FETCH_DRIVER_LIST_SUCCESS, 
    FETCH_DRIVER_LIST_FAIL,
    SEARCH_DRIVER,
    SEARCH_DRIVER_SUCCESS,
    SEARCH_DRIVER_FAIL,
    EDIT_DRIVER,
    EDIT_DRIVER_SUCCESS,
    EDIT_DRIVER_FAIL,
    GENERATE_SEND_T4A,
    GENERATE_SEND_T4A_SUCCESS,
    GENERATE_SEND_T4A_FAIL,
    DOWNLOAD_PDF,
    DOWNLOAD_PDF_SUCCESS,
    DOWNLOAD_PDF_FAIL,
    GET_T4A_TOTAL,
    GET_T4A_TOTAL_SUCCESS,
    GET_T4A_TOTAL_FAIL,
    DELETE_DRIVER,
    DELETE_DRIVER_SUCCESS,
    DELETE_DRIVER_FAIL,
    DOWNLOAD_XML,
    DOWNLOAD_XML_SUCCESS,
    DOWNLOAD_XML_FAIL
} from "../actions/types"

export function fetchDriverList(page, limit, province='ALL', company='ALL', state='ALL'){
    return function(dispatch){
        dispatch({type: FETCH_DRIVER_LIST})
        return shangjiaServerInstance.get(`/account/drivert4as?order_by=id&dir=desc&page=${page}&limit=${limit}&province=${province}&company=${company}&message_sent=${state}`)
        .then(({data}) => {
            dispatch({
                type: FETCH_DRIVER_LIST_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: FETCH_DRIVER_LIST_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function searchDriver(type, input){
    return function(dispatch){
        dispatch({type: SEARCH_DRIVER})
        return shangjiaServerInstance.get(`/account/drivert4as?${type}=${input}`)
        .then(({data}) => {
            dispatch({
                type: SEARCH_DRIVER_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: SEARCH_DRIVER_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function editDriver(id, params){
    return function(dispatch){
        dispatch({type: EDIT_DRIVER})
        return shangjiaServerInstance.post(`/account/drivert4as/${id}`, {...params})
        .then(({data}) => {
            dispatch({
                type: EDIT_DRIVER_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: EDIT_DRIVER_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function generateSendT4a(ids){
    return function(dispatch){
        dispatch({type: GENERATE_SEND_T4A})
        return shangjiaServerInstance.post(`/account/generateandsendt4as?ids=${ids}`)
        .then(({data}) => {
            dispatch({
                type: GENERATE_SEND_T4A_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: GENERATE_SEND_T4A_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function downloadPdf(id){
    return function(dispatch){
        dispatch({type: DOWNLOAD_PDF})
        return shangjiaServerInstance.get(`/account/drivert4apdf?driver_id=${id}`)
        .then(({data}) => {
            dispatch({
                type: DOWNLOAD_PDF_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: DOWNLOAD_PDF_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function getT4aTotal(){
    return function(dispatch){
        dispatch({type: GET_T4A_TOTAL})
        return shangjiaServerInstance.get(`account/drivert4atotal
        `)
        .then(({data}) => {
            dispatch({
                type: GET_T4A_TOTAL_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: GET_T4A_TOTAL_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function deleteDriver(id){
    return function(dispatch){
        dispatch({type: DELETE_DRIVER})
        return shangjiaServerInstance.delete(`account/drivert4as/${id}`)
        .then(({data}) => {
            dispatch({
                type: DELETE_DRIVER_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: DELETE_DRIVER_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function downloadXml(company){
    return function(dispatch){
        dispatch({type: DOWNLOAD_XML})
        return shangjiaServerInstance.get(`account/driverT4aXml?company=${company}`)
        .then(({data}) => {
            dispatch({
                type: DOWNLOAD_XML_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: DOWNLOAD_XML_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}