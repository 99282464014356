import React, {useCallback, useEffect, useState} from 'react';
import {AppBar, FormControl, IconButton, makeStyles, Select, Toolbar} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountIcon from "@mui/icons-material/AccountCircleSharp";
import {Menu, MenuItem} from "@mui/material";
import {userLogout} from "../../actions/userActions";
import {getUserManagedCustomers} from "../../utilities/common";
import {useCustomer} from "../../utilities/hooks";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#273340'
  },
  toolBarLogo:{
    display: 'flex',
    alignItems: 'center'
  },
  toolBar:{
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectIcon: {
    fill: 'white',
  },
  selectRoot: {
    color: 'white',
  },
}));

function PrmAppBar(props) {
  const {
    open,
    setOpen
  } = props;
  const classes = useStyles();
  const { customerData, updateCustomerData } = useCustomer();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectOpen, setSelectOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const accountOpen = Boolean(anchorEl);

  function handleLogout() {
    userLogout();
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setUserDefaultCustomer = useCallback((data) => {
    updateCustomerData(data);
    window.localStorage.setItem('customer_data', JSON.stringify(data));
  }, [updateCustomerData])

  const handleChangeCustomer = (e) => {
    const id = e.target.value;
    const customer_data = customerList.find((x)=>(x.id===id));
    setUserDefaultCustomer(customer_data);
  }

  useEffect(() => {
    const customer_list = getUserManagedCustomers();
    if (customer_list.length) {
      setSelectOpen(true);
      setCustomerList(customer_list);
      if (!customerData.id) {
        setUserDefaultCustomer(customer_list[0]);
      }
    }
  }, [customerData.id, setUserDefaultCustomer])

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <div className={classes.toolBar}>
          <div className={classes.toolBarLogo}>
            <IconButton
              id={'menu-icon'}
              onClick={()=>{setOpen(!open)}}
            >
              <MenuIcon style={{color: '#ff9d00', width: 40, height: 40}}/>
            </IconButton>
            <img
              src='/images/icons/logo.svg'
              alt=''
              style={{
                width: 250,
                height: 40,
                marginLeft: 15
              }}
            />
          </div>
          <div>
            {
              selectOpen ?
                <FormControl variant="outlined" style={{width: '200px', margin: '5px 40px 5px 0px'}}>
                  <Select
                    id={'appbar-customer-select'}
                    inputProps={{
                      classes: {
                        icon: classes.selectIcon,
                        root: classes.selectRoot,
                      },
                    }}
                    value={customerData.id}
                    onChange={handleChangeCustomer}
                  >
                    {customerList.map((value) => (
                      <MenuItem value={value.id} key={value.id}>
                        {value.alias}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                :
                null
            }
            <IconButton
              onClick={handleAccount}
              aria-controls={accountOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={accountOpen ? 'true' : undefined}
              id={'menu-handle-account'}
            >
              <AccountIcon style={{color: '#ffffff', width: 40, height: 40}}/>
            </IconButton>
            <Menu
              open={accountOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleLogout} id={'menu-logout'}> Logout </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default PrmAppBar;