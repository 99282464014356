import { useDispatch } from 'react-redux';
import { Button, TextField, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getDriverDropOffHistory, handleServicePoint } from '../../actions/servicePointActions';
import { showAlertSnackbar } from '../../reducers/uiSlice';

const FixedTextField = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '10px !important',
        },
        marginBottom: 20
    },
}))(TextField);

function ServicePointSearchDate(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation('servicePointStatistics');

    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
    const [failOpen, setFailOpen] = useState(false);
    // const [pointId, setPointId] = useState('');
    const pointId = ''

    const handleFailClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        } 
        setFailOpen(false);
    };

    function handleDateTo(event){
        const dateToTamp = moment(event.target.value).unix();
        const dateFromTamp = moment(dateFrom).unix();
        if (dateToTamp < dateFromTamp)  {
            setDateTo(dateFrom);
            setFailOpen(true);
        } else {
            setDateTo(event.target.value);
        }
    }
    async function fetchStatistics() {
        props.handleLoading(true);
        const warehouse = window.localStorage.getItem("warehouse");
        const dateFromNum = moment(dateFrom).startOf('day').valueOf() / 1000;
        const dateToNum = Math.floor(moment(dateTo).endOf('day').valueOf() / 1000);
        const pointIdTrim = pointId.replaceAll(/\s/g, "");
        try {
            await dispatch(handleServicePoint(pointIdTrim));
            await dispatch(getDriverDropOffHistory(dateFromNum, dateToNum, warehouse));
            props.handleLoading(false);
        } catch {
            dispatch(showAlertSnackbar({message: t('fail_fetch_message'), type: 'error'}));
            props.handleLoading(false);
        }
    }

    return (
        <>
            <Snackbar open={failOpen} autoHideDuration={6000} onClose={handleFailClose}>
                <Alert onClose={handleFailClose} severity="error">
                    End date must be later than start date!
                </Alert>
            </Snackbar>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'baseline'
                }}
            >
                <FixedTextField 
                    size="small"
                    id="searchStartDate"
                    type="date"
                    variant="outlined"
                    value={dateFrom}
                    onChange={(e)=>setDateFrom(e.target.value)}
                    style={{marginRight: 10}}
                />
                <span style={{marginRight: 10}}>{t('to')}</span>
                <FixedTextField 
                    size="small"
                    id="searchStartDate"
                    type="date"
                    variant="outlined"
                    value={dateTo}
                    onChange={handleDateTo}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 10 }}
                    onClick={fetchStatistics}
                >
                    {t('search_all')}
                </Button>
            </div>
        </>
    );
}

export default ServicePointSearchDate;