import { Button, makeStyles, Dialog, DialogTitle, DialogActions, DialogContent, Typography, CircularProgress } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getOrderPrintLabels } from '../../../actions/storeActions';
import BarcodeGeneratorCA from '../../BarcodeGenerator/BarcodeGeneratorCA';
import BarcodeGeneratorUS from '../../BarcodeGenerator/BarcodeGeneratorUS';
import ReactToPrint from 'react-to-print';
import { forEach } from 'lodash';
import * as XLSX from 'xlsx';
import { showAlertSnackbar } from '../../../reducers/uiSlice';

const useStyles = makeStyles((theme) => ({
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
  },
  framedButton: {
    color: " #2a3848",
    borderRadius: 4,
    border: "solid 1px #2a3848",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 600,
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  table: {
    height: 670,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .pathInfo': {
      color: '#D32F2FDE',
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonText: {
    color: "#1976d2",
    width: 53,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  printLabel: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
    float: 'center'
  }
}));


export default function LocalDeliveryDisplay(props) {
  const classes = useStyles();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { importRes, page, setPage, rows, setRows } = props;

  const [time, setTime] = useState('');
  const [orderSns, setOrderSns] = useState([]);
  const [orderRes, setOrderRes] = useState([]);
  const [labelPopup, setLabelsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [failOpen, setFailOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);


  const localDeliveryColumns = [
    {
      field: 'extra_order_sn',
      headerName: "Tracking Number",
      width: 200,
    },
    {
      field: 'referer',
      headerName: "Batch Number",
      width: 200,
    },
    {
      field: 'internal_account_number',
      headerName: "Reference #",
      width: 200,
    },
    {
      field: 'pathInfo',
      headerName: "Status",
      width: 250,
    },
    {
      field: 'consignee',
      headerName: "Consignee",
      width: 200,
    },
    {
      field: 'address',
      headerName: "Address",
      width: 400,
    },
    {
      field: 'city',
      headerName: "City",
      width: 100,
    },
    {
      field: 'mobile',
      headerName: "Phone #",
      width: 143,
    },
    {
      field: 'goods_type',
      headerName: "Parcel Category",
      width: 100,
    },
    {
      field: 'add_time',
      headerName: "Upload Time",
      width: 192,
    },
  ]

  function createDeliveryOrders() {
    let orders = [];
    let temp = importRes.data?importRes.data:importRes
    if (temp.length > 0) {
      temp.forEach(forEach((row) => {
        const { extra_order_sn, ...rest } = row
        orders.push({ id: extra_order_sn, extra_order_sn: extra_order_sn, ...rest })
      })
      )
    }
    return orders;
  }


  function handleDownloadResult() {
    setLoading(true);
    var filtered = importRes.data?importRes.data:importRes;
    if(filtered.length===0){
      showAlertSnackbar({message: 'No Order Information, please refresh and retry', type: 'error'})
    }
    if (orderSns.length !== 0) {
      filtered = filtered.filter((row) => orderSns.includes(row.extra_order_sn));
    }

    filtered = filtered.map(({ internal_account_number, extra_order_sn, pathInfo, referer, add_time, consignee, address, mobile, city, province, goods_type, ...rest }) => ({ "Internal Reference Number": internal_account_number, "Tracking Number": extra_order_sn, "Order Status": pathInfo, "Batch Number": referer, "Create Time": add_time, "Consignee": consignee, "Address": address, "Mobile": mobile, "City": city, "Province": province, "Parcel Category": goods_type }));

    filtered.reverse();
    var workbook = XLSX.utils.book_new();
    var workSheet = XLSX.utils.json_to_sheet(filtered);
    workbook.SheetNames.push("orders");
    workbook.Sheets["orders"] = workSheet;
    setLoading(false);
    XLSX.writeFile(workbook, "Orders.xlsx");
  }

  function fetchOrdersInfo() {
    setLoading(true);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    if (today.getMinutes() < 10) {
      time = today.getHours() + ":0" + today.getMinutes() + ":" + today.getSeconds();
    }
    var dateTime = date + ' ' + time;
    setTime(dateTime);
    var str = [];
    if (orderSns.length === 0) {
      let temp = importRes.data?importRes.data:importRes;
      if(temp.length>0){
        temp.forEach((row) => {
          str.push(row.extra_order_sn);
        })
      }else{
        showAlertSnackbar({message: 'No Order Selected', type: 'error'})
      }
    } else {
      str = orderSns;
    }
    dispatch(getOrderPrintLabels(window.localStorage.getItem('warehouse'), str.toString()))
      .then((res) => {
        setOrderRes(res);
        setLoading(false);
        setLabelsOpen(true);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
        setFailOpen(true);
      })
  }

  return (
    <div className={classes.tableArea}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', color: "#577699", fontSize: 18, fontWeight: 600, }}>Results</div>

        <Button className={classes.framedButton} style={{ marginRight: 36, }} onClick={handleDownloadResult} >DOWNLOAD</Button>
        <Button className={classes.framedButton} onClick={fetchOrdersInfo} >PRODUCE LABELS</Button>
      </div>
      {isNaN(rows) ?
        <div>
          <DataGrid
            className={classes.table}
            rows={createDeliveryOrders() || []}
            columns={localDeliveryColumns}
            checkboxSelection
            onSelectionModelChange={(neworderSns) => {
              setOrderSns(neworderSns);
            }}
            selectionModel={orderSns}
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30]}
            pagination

          />

        </div>
        :
        <div>
          <DataGrid
            className={classes.table}
            rows={createDeliveryOrders() || []}
            columns={localDeliveryColumns}
            checkboxSelection
            onSelectionModelChange={(neworderSns) => {
              setOrderSns(neworderSns);
            }}
            selectionModel={orderSns}
            disableSelectionOnClick
            page={page - 1}
            pageSize={rows}
            rowsPerPageOptions={[10, 20, 30, 'ALL']}
            rowCount={importRes.total ? importRes.total : 0}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => {
              setPage(newPage + 1)
            }}
            onPageSizeChange={(newPageSize) => {
              setRows(newPageSize)
            }}
            getCellClassName={(params) => {
              return params.field;
            }}

          />
        </div>
      }

      <Dialog maxWidth='sm' fullWidth={true} open={labelPopup} onClose={() => setLabelsOpen(false)}>
        <DialogTitle >
          <span>  Labels </span>
          <span style={{ float: "right" }}>
            <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setLabelsOpen(false)}>Cancel</Button>
            <ReactToPrint
              pageStyle="@page { size: 4in 6in }"
              trigger={() => <Button variant='contained'
                className={classes.buttonSystem} disabled={!(orderRes.length > 0)}>Print</Button>}
              content={() => componentRef.current}
            />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={classes.printLabel}>
            <div className='barcode' ref={componentRef}>
              {
                orderRes.length > 0 &&
                orderRes.map((order) => {
                  if (process.env.REACT_APP_COUNTRY === 'US') {
                    return (
                      <BarcodeGeneratorUS key={order.extra_order_sn} order={order} time={time} />
                    )
                  } else {
                    return (
                      <BarcodeGeneratorCA key={order.extra_order_sn} order={order} time={time} />
                    )
                  }

                })
              }
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog maxWidth='sm' fullWidth={true} open={failOpen} onClose={() => setFailOpen(false)}>
        <DialogTitle>Failed</DialogTitle>
        <DialogContent>
          <br />
          <Typography>
            {errorMessage}
          </Typography>
          <br />
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setFailOpen(false)}>Cancel</Button>
          <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setFailOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
