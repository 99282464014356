import { Button, Dialog, DialogTitle, DialogActions, DialogContent, IconButton, makeStyles, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import { ClearRounded } from '@material-ui/icons';
import { downloadInvoiceFiles, downloadQCPostAuditFile, downloadCreditMemoTemplate, uploadCreditMemoFile } from '../../../actions/billingManangementActions';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataGrid } from '@material-ui/data-grid';
import DragDropFileUpload from '../../DragDropFileUpload';
import DocumentDetailDisplay from '../../DocumentDetailDisplay';
import FullscreenLoading from "../../FullscreenLoading";


const useStyle = makeStyles(() => ({
  fillButton: {
    width: "200",
    display: "flex",
    margin: "5px 0px 40px",
    backgroundColor: "#1976D2",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 600,
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }

  },
  buttonText: {
    color: "#1976d2",
    width: 100,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'

    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  dialogTitle: {
    fontSize: '18px',
    marginBottom: '8px',
    paddingBottom: 0,

  },
  dialogContent: {
    padding: "10px"
  },
  hr: {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  button: {
    fontSize: '14px',
    color: '#2196F3'
  },
  redText: {
    color: '#D32F2F',
  },
  invoiceList: {
    background: "#ffffff",
    borderRadius: "4px",
    marginLeft: "5px",
    marginRight: "5px",
    paddingTop: '10px',
  },
  table: {
    width: '100%',
    height: 400,
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      paddingLeft: '20px',
    },
    '& .MuiDataGrid-columnHeader': {
      paddingLeft: '20px', // Adjust this value as needed
    }
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '27px',
    letterSpacing: '0.4px',
    paddingRight: '24px',
  },
  infoTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#616161',
  },
  infoValue: {
    fontSize: '14px'
  },
}));

function GridItem({ title, value }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  function getAction(fileType, filename, invoiceNum) {
    if (fileType === 'invoice')
      return downloadInvoiceFiles({ file_name: filename, invoice_num: invoiceNum });

  }

  async function handleDownload(filename, invoiceNum, fileType) {
    try {
      setLoading(true);
      const action = getAction(fileType, filename, invoiceNum);
      await dispatch(action);
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container item xs={12} spacing={0} style={{ marginBottom: '14px' }}>
      <Grid item xs={5} className={classes.infoTitle}>{title}</Grid>
      <Grid item xs={7} >
        {typeof value === 'string' ? (
          <span className={classes.infoValue}>{value}</span>
        ) : (
          value.text ? (
            <div>
              <Button
                size="small"
                className={classes.button}
                endIcon={<SaveAltIcon />}
                style={{
                  textAlign: 'center',
                  fontSize: '12px',
                  padding: 0,
                  textTransform: 'none',
                }}
                onClick={() => handleDownload(value.text, value.invoiceNum, value.type)}
                disabled={loading}
              >
                <span className={classes.infoValue}>{value.text}</span>
              </Button>
            </div>
          ) : null // Render nothing when value.text is null
        )}
      </Grid>
    </Grid>
  );
}

function DownloadBill(data) {
  const classes = useStyle();
  const dispatch = useDispatch();

  return (
    <Button
      size="small"
      className={classes.button}
      endIcon={<SaveAltIcon />}
      style={{
        textAlign: 'center',
        fontSize: '12px',
        padding: 0,
        textTransform: 'none',
      }}

      onClick={() => dispatch(downloadQCPostAuditFile({ file_name: data.value, billing_num: data.id }))}
    >
      <span className={classes.infoValue}>{data.value}</span>
    </Button>
  );
}

const billingInfoColumns = [
  {
    field: 'billing_num',
    headerName: "Billing ID",
    width: 150,
    sortable: false,
  },
  {
    field: 'billing_start',
    headerName: "Billing Date",
    width: 150,
    sortable: false,
  },
  {
    field: 'operator_username',
    headerName: "Operator",
    width: 180,
    sortable: false,
  },
  {
    field: 'qc_postaudit_file',
    headerName: "Bill Download",
    width: 400,
    sortable: false,
    renderCell: (params) => DownloadBill(params)

  }
]

const creditMemoDetailColumns = [
  {
    field: 'description',
    headerName: "Category",
    width: 180,
    sortable: false,
  },
  {
    field: 'parcel_no',
    headerName: "Tracking No.",
    width: 280,
    sortable: false,
  },
  {
    field: 'fee_adjustment',
    headerName: "Adjusted Amount",
    width: 180,
    sortable: false,
    valueFormatter: (params) => (" $ " + params.value),
  },
  {
    field: 'comments',
    headerName: "Comments",
    width: 300,
    sortable: false,
  }
]


function InvoiceDetail(props) {
  const {
    setDialogOpen, data, refreshDialog, setDetails, refresh
  } = props;
  const dispatch = useDispatch();
  const classes = useStyle();

  const [uploadDialog, openUploadDialog] = useState(false);
  const [creditMemoFile, setCreditMemoFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [failedMsg, setFailedMsg] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);
  const [detailDialog, openDetailDialog] = useState(false);
  const [cancelDialog, openCancelDialog] = useState(false);

  let invoiceInfo = [
    { title: 'Invoice Number:', value: data['invoiceInfo']['invoice_num'] ?? '' },
    { title: 'Invoice Creation Date:', value: data['invoiceInfo']['creation_time'] ?? '' },
    { title: 'Amount', value: "$ " + data['invoiceInfo']['total_amount'] ?? '' },
    { title: 'Invoice', value: { text: data['invoiceInfo']['invoice_file'], type: 'invoice', invoiceNum: data['invoiceInfo']['invoice_num'] } },

  ];

  async function downloadTemplate() {
    try {
      setLoading(true);
      await dispatch(downloadCreditMemoTemplate());
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  async function handleValidateCreditMemoFile() {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', creditMemoFile);
      formData.append('invoice_num', data['invoiceInfo']['invoice_num']);
      formData.append('write', 0);
      await dispatch(uploadCreditMemoFile(formData))
        .then((res) => {
          setLoading(false);
          setFileDetails(res);
          openUploadDialog(false);
          openDetailDialog(true);
        }).catch((err) => {
          setLoading(false);
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
          if(err.additional){
            setFailedMsg(err.additional);
          }else{
            setFailedMsg(err.message);
          }
          
        })

    } catch (error) {
      setLoading(false);
      setFailedMsg(error.additional);
    }
  }

  async function handleUploadCreditMemoFile() {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', creditMemoFile);
      formData.append('invoice_num', data['invoiceInfo']['invoice_num']);
      formData.append('write', 1);
      await dispatch(uploadCreditMemoFile(formData))
        .then((res) => {
          setLoading(false);
          refresh();
          setDetails([]);
          refreshDialog({cr_num:res, slip_type_description:"Credit Memo"});
          openDetailDialog(false);
        })

    } catch (error) {
      setLoading(false);
      setFailedMsg(error.additional);
    }
  }



  return (
    <div>
      <div>
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ position: 'relative', fontWeight: 600, }}>
            <span>Invoice Detail</span>
            <IconButton
              className={classes.button}
              style={{ position: 'absolute', top: 0, right: 0, bottom: 5 }}
              onClick={() => setDialogOpen(false)}>
              <ClearRounded />
            </IconButton>
          </div>
        </DialogTitle>
        <hr className={classes.hr} />
        <DialogContent>
          <div>
            <span className={classes.subtitle}>Invoice Information</span>
            <Grid container spacing={0} className={classes.dialogContent}>
              {invoiceInfo.map((item, index) => (
                <GridItem key={index} title={item.title} value={item.value} />
              ))}
            </Grid>
          </div>
        </DialogContent>
        <hr className={classes.hr} />
        <DialogContent>
          <div>
            <span className={classes.subtitle}>Billing Information</span>
            <div className={classes.invoiceList}>
              <DataGrid
                className={classes.table}
                rows={data['billingInfo'] ?? []}
                columns={billingInfoColumns}
                checkboxSelection={false}
                getRowId={(row) => row.billing_num}
                hideFooter={true}
                disableColumnMenu={true}
              />
            </div>
          </div>
        </DialogContent>
        <hr className={classes.hr} />
        <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
          <Button className={classes.button} style={{ fontWeight: '500' }} autoFocus onClick={() => { setDialogOpen(false) }}>
            CLOSE
          </Button>
          <Button variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '500' }} onClick={() => { openUploadDialog(true) }}>
            ADD CREDIT MEMO
          </Button>
        </DialogActions>
      </div>

      <Dialog maxWidth={"sm"} fullWidth open={uploadDialog}>

        <DialogTitle className={classes.dialogTitle}>
          <div style={{ position: 'relative' }}>
            <span style={{ fontWeight: '600' }}>Upload Credit Memo</span>
          </div>
        </DialogTitle>
        {failedMsg ?
          <DialogContent>
            <div className={classes.redText} style={{ fontSize: 12, display: 'flex', flexDirection: "row", alignItems: "center", }}>
              <InfoOutlinedIcon style={{ fontSize: 15, marginRight: 12, marginBottom: 3 }} />
              <div>Please correct the rows listed below and upload the credit memo again</div>
            </div>
            <div style={{ padding: 10 }}> <DocumentDetailDisplay file={creditMemoFile} state={'Complete'} /> </div>
            {
              Array.isArray(failedMsg)?
              
              failedMsg.map((item) => {
                return <div style={{ paddingRgiht: 15, display: "flex", flexDirection: "row", }}>
                  <div style={{ paddingRight: 15 }}>Row {item.row}: </div>
                  <div>
                    {
                      item.errors &&
                      item.errors.map((item) => {
                        return <div>{item} </div>
                      })
                    }
                  </div>
                </div>

              })
              :
              <div style={{ paddingRgiht: 15, display: "flex", flexDirection: "row", }}>
                 <div style={{ paddingRight: 15 }}>{failedMsg}</div>
                </div>
            }

            <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
          </DialogContent>
          :
          <DialogContent>
            <div style={{ position: 'relative' }}>
              <span>Please utilize the provided template for adding a new credit memo</span>
            </div>
            <Button
              size="small"
              className={classes.button}
              endIcon={<SaveAltIcon />}
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '10px 0px',
                textTransform: 'none',
              }}
              onClick={() => downloadTemplate()}
            >
              <span className={classes.infoValue}>{"template.xlsx"}</span>
            </Button>
            <DragDropFileUpload fileType={".xlsx"} onFileUpload={setCreditMemoFile} file={creditMemoFile} />
            <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
          </DialogContent>
        }
        {
          failedMsg ?
            <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
              <Button className={classes.button} style={{ fontWeight: '00' }} autoFocus color="primary" onClick={() => { setFailedMsg(null); setCreditMemoFile(''); }}>
                Back
              </Button>
            </DialogActions>
            :
            <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
              <Button className={classes.button} style={{ fontWeight: '00' }} autoFocus color="primary" onClick={() => { setCreditMemoFile(''); openUploadDialog(false); }}>
                Back
              </Button>
              <Button variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '500' }} onClick={handleValidateCreditMemoFile}>
                Upload
              </Button>
            </DialogActions>

        }
        <FullscreenLoading open={loading} />
      </Dialog>

      <Dialog maxWidth={'lg'} fullWidth open={detailDialog}>
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ position: 'relative' }}>
            <span style={{ fontWeight: '600' }}>Upload Credit Memo</span>
          </div>
        </DialogTitle>

        <DialogContent>
          <div style={{ paddingBottom: 15 }}> <DocumentDetailDisplay file={creditMemoFile} state={'Complete'} /> </div>
          <div>
            <span className={classes.subtitle}>Credit Memo Information</span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid container spacing={0} className={classes.dialogContent}>
                <Grid item xs={6} className={classes.infoTitle}>Invoice Number:</Grid>
                <Grid item xs={6} className={classes.infoValue}>{fileDetails?.invoice_num}</Grid>
              </Grid>
              <Grid container spacing={0} className={classes.dialogContent}>
                <Grid item xs={6} className={classes.infoTitle}>Total Adjusted Amount:</Grid>
                <Grid item xs={6} className={classes.infoValue}>{"$ " + fileDetails?.total}</Grid>
              </Grid>
            </div>
          </div>
          <div>
            <span className={classes.subtitle}>Credit Memo Details</span>
            <div className={classes.invoiceList}>
              <DataGrid
                className={classes.table}
                rows={fileDetails?.details ?? []}
                columns={creditMemoDetailColumns}
                checkboxSelection={false}
                getRowId={(row) => row.id}
                disableColumnMenu={true}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
          <Button className={classes.button} style={{ fontWeight: '500' }} autoFocus onClick={() => { openCancelDialog(true) }}>
            CANCEL
          </Button>
          <Button variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '500' }} onClick={handleUploadCreditMemoFile}>
            CONFIRM AND SUBMIT
          </Button>
        </DialogActions>
        <FullscreenLoading open={loading} />
      </Dialog>


      <Dialog maxWidth={'sm'} fullWidth open={cancelDialog}>
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ position: 'relative' }}>
            <span style={{ fontWeight: '600' }}>Cancel this credit memo?</span>
          </div>
        </DialogTitle>

        <DialogContent>
          <div style={{ position: 'relative' }}>
            <span style={{ fontWeight: '500' }}>Are you sure you want to cancel this credit memo ?</span>
          </div>
        </DialogContent>
        <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
          <Button className={classes.button} style={{ fontWeight: '500' }} autoFocus onClick={() => { openCancelDialog(false); }}>
            NO
          </Button>
          <Button className={classes.button} style={{ fontWeight: '500' }} autoFocus onClick={() => { openCancelDialog(false); openDetailDialog(false); setCreditMemoFile(''); setFileDetails(null); setDialogOpen(false);}}>
            YES
          </Button>
        </DialogActions>
        <FullscreenLoading open={loading} />
      </Dialog>
    </div>
  );
}


export default InvoiceDetail;