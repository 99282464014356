import { makeStyles } from '@material-ui/core';
import Barcode from 'react-barcode';

const useStyles = makeStyles((theme)=> ({
    container: {
        width: '384px',
        height: '576px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    header_logo: {
        height: '19pt',
        marginTop: '14pt',
        marginLeft: '11pt'
    },
    header_segment: {
        lineHeight: '28pt',
        fontSize: '21pt',
        marginTop: '3pt',
        marginRight: '10pt',
        fontWeight: 600
    },
    to: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10.8pt',
        marginTop: '8pt',
        marginBottom: '8pt'
    },
    header_to: {
        lineHeight: '13pt',
        fontSize: '10pt',
        paddingTop: '1.1pt',
        paddingBottom: '1.2pt',
        paddingRight: '3.6pt',
        width: 'fit-content',
        fontWeight: 700,
        fontStyle: 'italic'
    },
    name_to: {
        lineHeight: '15pt',
        fontSize: '11pt',
        fontWeight: 700,
        marginTop: '4.7pt'
    },
    info_to: {
        lineHeight: '15pt',
        fontSize: '11pt'
    },
    header_intro: {
        lineHeight: '15pt',
        fontSize: '11pt',
        fontStyle: 'italic',
        fontWeight: 700,
        marginTop: '6.5pt'
    },
    intro: {
        lineHeight: '13pt',
        fontSize: '10pt'
    },
    barcode_area: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '6pt',
        marginRight: '6pt',
        borderTop: '1px solid black',
        borderBottom: '1px solid black'
    },
    barcode_group: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    barcode_text: {
        fontSize: '8pt',
        fontStyle: 'italic',
        fontWeight: 700,
        marginTop: '5pt'
    },
    item_info: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10.8pt',
        marginRight: '13.1pt'
    },
    item_detail: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '11.9pt'
    },
    item_title: {
        lineHeight: '13pt',
        fontSize: '10pt',
        fontWeight: 700,
        fontStyle: 'italic',
        minWidth: '126pt'
    },
    item_content: {
        lineHeight: '13pt',
        fontSize: '10pt'
    },
    print_time: {
        position: 'absolute',
        left: '12pt',
        bottom: '12pt',
        display: 'flex',
        lineHeight: '10pt',
        fontSize: '8pt'
    }
}))

function BarcodeGeneratorUS(props) {
    const classes = useStyles();

    return (
        props.order &&
        <div className={classes.container}>
            <div className={classes.header}>
                <div>
                    <img 
                        src='/images/icons/icon_label.png'
                        alt=''
                        style={{ 
                            height: '24pt',
                            marginTop: '11pt',
                            marginLeft: '11pt',
                            opacity: props.order.need_transshipment ? 0 : 1
                        }}
                    />
                </div>
                <div className={classes.header_segment}>{props.order.segment}</div>
            </div>
            <div className={classes.to}>
                <div className={classes.header_to}>
                    Deliver to
                </div>
                <div className={classes.name_to}>
                    {props.order.consignee}
                </div>
                <div className={classes.info_to}>
                    C:{props.order.mobile}
                </div>
                <div className={classes.info_to}>
                    {props.order.address}
                </div>
                <div className={classes.info_to}>
                    {props.order.city} {props.order.province} {props.order.zip}
                </div>
                <div className={classes.header_intro}>
                    Delivery Instruction:
                </div>
                <div className={classes.intro}>
                    {props.order.driver_notes}
                </div>
            </div>
            <div className={classes.barcode_area}>
                <div className={classes.barcode_group}>
                    <div className={classes.barcode_text}>Tracking Number</div>
                    <Barcode 
                        value={props.order.extra_order_sn}
                        height='60pt'
                        fontSize='16pt'
                        width={1.8}
                        textAlign='center'
                        textPosition='top'
                        textMargin={5}
                    />
                </div>
            </div>
            <div className={classes.item_info}>
                <div className={classes.item_detail}>
                    <div className={classes.item_title}>
                        Pieces:
                    </div>
                    <div className={classes.item_content}>
                        {props.order.pieces}
                    </div>
                </div>
                <div className={classes.item_detail}>
                    <div className={classes.item_title}>
                        SKU:
                    </div>
                    <div className={classes.item_content}>
                        {props.order.item_description}
                    </div>
                </div>
                <div className={classes.item_detail}>
                    <div className={classes.item_title}>
                        Weight:
                    </div>
                    <div className={classes.item_content}>
                        {props.order.total_weight}{props.order.weight_uom}
                    </div>
                </div>
                <div className={classes.item_detail}>
                    <div className={classes.item_title}>
                        Customer:
                    </div>
                    <div className={classes.item_content}>
                        {/* {window.localStorage.getItem('store')} */}
                    </div>
                </div>
                <div className={classes.item_detail}>
                    <div className={classes.item_title}>
                        Reference #:
                    </div>
                    <div className={classes.item_content}>
                        {props.order.internal_account_number}
                    </div>
                </div>
            </div>
            <div className={classes.print_time}>
                Print time: {props.time}
            </div> 
        </div>
    )
}

export default BarcodeGeneratorUS;