import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PrealertStatisticSearchDate from "../PrealertStatisticSearchDate";
import ReactExport from "react-export-excel";

const useStyle = makeStyles(() => ({
  root:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px"
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  }
}));

function PrealertReport(props) {
  const {
    setLoading
  } = props;
  const classes = useStyle();
  const [mawbData, setMawbData] = useState([]);
  const [bagData, setBagData] = useState([]);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  return (
    <div>
      <div className={classes.breadCrumbs}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Pre-alert Report</p>
        {/*<Breadcrumbs aria-label="breadcrumb">*/}
        {/*  <Link*/}
        {/*    underline="hover"*/}
        {/*    color="inherit"*/}
        {/*    to="/main"*/}
        {/*  >*/}
        {/*    Home*/}
        {/*  </Link>*/}
        {/*  <Typography className={classes.breadCrumbText} color="text.primary">Pre-alert Report</Typography>*/}
        {/*</Breadcrumbs>*/}
      </div>

      <div className={classes.root}>
        <div
          style={{
            marginLeft: 40
          }}>
          <h2>MAWB Statistic</h2>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            marginLeft: 10,
          }}
        >
          <PrealertStatisticSearchDate type='MAWB' setData={setMawbData} setLoading={setLoading}/>
          {
            (mawbData.length > 0) ?
              <ExcelFile
                filename="MAWB_STATISTICS"
                element={
                  <Button
                    color="primary"
                    variant="contained"
                    id={"prealertreport_mawb_button"}
                    style={{ marginLeft: 30 }}
                  >
                    {'Download'}
                  </Button>
                }
              >
                <ExcelSheet data={mawbData} name="MAWB_STATISTICS">
                  <ExcelColumn label="Client ID" value="client_id"/>
                  <ExcelColumn label="Client Name" value="client_name"/>
                  <ExcelColumn label="ORIGINAL AIRPORT" value="original_airport"/>
                  <ExcelColumn label="DESTINATION AIRPORT" value="warehouse"/>
                  <ExcelColumn label="MAWB" value="mawb"/>
                  <ExcelColumn label="FLIGHT NO" value="flight_no"/>
                  <ExcelColumn label="ETD" value="etd"/>
                  <ExcelColumn label="ETA" value="eta"/>
                  <ExcelColumn label="SHIPMENTS" value="shipments"/>
                  <ExcelColumn label="SHIPMENT WEIGHT" value="shipment_weight"/>
                  <ExcelColumn label="Number of bags or skids" value="total_bags"/>
                  <ExcelColumn label="Express" value="express_count"/>
                  <ExcelColumn label="BG" value="bg_count"/>
                  <ExcelColumn label="WOSP" value="wosp_count"/>
                  <ExcelColumn label="YT" value="yt_count"/>
                  <ExcelColumn label="ZYSF (4px)" value="4px_count"/>
                  <ExcelColumn label="AE (LP)" value="lp_count"/>
                  <ExcelColumn label="SF" value="sf_count"/>
                  <ExcelColumn label="UG/AM" value="ugam_count"/>
                  <ExcelColumn label="WI" value="wi_count"/>
                  <ExcelColumn label="ShouWu (QHX)" value="qhx_count"/>
                  <ExcelColumn label="Winit" value="wo_count"/>
                  <ExcelColumn label="Cider" value="cider_count"/>
                  <ExcelColumn label="PDD" value="pdd_count"/>
                  <ExcelColumn label="TEMU" value="temu_count"/>
                  <ExcelColumn label="KPX" value="kpx_count"/>
                  <ExcelColumn label="SIDLK" value="sidlk_count"/>
                  <ExcelColumn label="No of item weight >=2 and <5" value="less_than_five_kgs"/>
                  <ExcelColumn label="No of item weight >=5" value="greater_than_five_kgs"/>

                </ExcelSheet>
              </ExcelFile>
              :
              <Button
                color="primary"
                variant="contained"
                disabled
                style={{ marginLeft: 30 }}
                id={'prealert_mawb_disabledbutton'}
              >
                {'Download'}
              </Button>
          }

        </div>

        <div
          style={{
            marginTop: 30,
            marginLeft: 40,
          }}>
          <h2>Bag Statistic</h2>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            marginLeft: 10,
          }}
        >
          <PrealertStatisticSearchDate type='BAG' setData={setBagData} setLoading={setLoading}/>
          {
            (bagData.length > 0) ?
              <ExcelFile
                filename="Bag_STATISTICS"
                element={
                  <Button
                    color="primary"
                    variant="contained"
                    id={"prealertreport_bag_button"}
                    style={{ marginLeft: 30 }}
                  >
                    {'Download'}
                  </Button>
                }
              >
                <ExcelSheet data={bagData} name="BAG_STATISTICS">
                  <ExcelColumn label="MAWB" value="master_air_waybill"/>
                  <ExcelColumn label="Warehouse" value="warehouse"/>
                  <ExcelColumn label="Bag No" value="bag_no"/>
                  <ExcelColumn label="Weight/KG" value="weight"/>
                  <ExcelColumn label="YYZ count" value="yyz_count"/>
                  <ExcelColumn label="YUL count" value="yul_count"/>
                  <ExcelColumn label="YOW count" value="yow_count"/>
                  <ExcelColumn label="YWG count" value="ywg_count"/>
                  <ExcelColumn label="YHZ count" value="yhz_count"/>
                  <ExcelColumn label="YQB count" value="yqb_count"/>
                  <ExcelColumn label="YVR count" value="yvr_count"/>
                  <ExcelColumn label="YYJ count" value="yyj_count"/>
                  <ExcelColumn label="YYC count" value="yyc_count"/>
                  <ExcelColumn label="YEG count" value="yeg_count"/>
                  <ExcelColumn label="YQR count" value="yqr_count"/>
                  <ExcelColumn label="YQM count" value="yqm_count"/>
                  <ExcelColumn label="YYT count" value="yyt_count"/>
                  <ExcelColumn label="YXE count" value="yxe_count"/>

                  <ExcelColumn label="Mix" value="mix"/>
                </ExcelSheet>
              </ExcelFile>
              :
              <Button
                color="primary"
                variant="contained"
                disabled
                style={{ marginLeft: 30 }}
                id={'prealert_bag_disabledbutton'}
              >
                {'Download'}
              </Button>
          }

        </div>
      </div>
    </div>

  );
}

export default PrealertReport;