import {shangjiaServerInstance} from "../axios/instances";
import { GV_ORDER_MANAGEMENT as types} from "./types"

export function addMissingGVOrders(params) {
  return function(dispatch) {
    dispatch({type: types.ADD_MISSING_ORDER})
    return shangjiaServerInstance.post("/orders/missingorder",{ ...params })
      .then(({data}) => {
        dispatch({
          type: types.ADD_MISSING_ORDER_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type:  types.ADD_MISSING_ORDER_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
