import { useDispatch } from 'react-redux';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, {useEffect, useState, useCallback} from 'react';
import {downloadWrongAreaOrders, getWrongAreaStatistic} from "../../actions/prealertStatisticActions";
import {DataGrid} from "@material-ui/data-grid";
import {showAlertSnackbar} from "../../reducers/uiSlice";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';

const useStyle = makeStyles(() => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textField: {
    width: 230
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  tableArea: {
    background: "#ffffff",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 740,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
  },
}));

const initialDate = dayjs().subtract(1, 'days').format('YYYY-MM-DD');
const initialPartner = '75'

function PrealertWrongAreaKeyword(props) {
  const {
    setData,
    setLoading
  } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [date, setDate] = useState(initialDate);
  const [partner, setPartner] = useState(initialPartner);
  const [time, setTime] = useState('import');
  const [keyword, setKeyword] = useState();
  const [type, setType] = useState('mawb');
  const [rows, setRows] = useState([]);

  const partnerList = [
    {
      name: "GV",
      type: '75'
    },
    {
      name: "BroadReach",
      type: "158"
    }
  ]

  const timeList = [
    {
      name: "Import Time",
      type: 'import'
    }
  ]

  const typeList = [
    {
      name: "MAWB",
      type: "mawb"
    }
  ]

  const columns = [
    {
      field: 'batch',
      headerName: 'Batch',
      width: 200,
      headerAlign: 'center'
    },
    {
      field: 'wrong_orders_count',
      headerName: 'Count',
      width: 100,
      headerAlign: 'center'
    },
    {
      field: 'wrong_orders',
      headerName: 'Wrong Orders',
      width: 900,
      headerAlign: 'center'
    },
    {
      field: 'err_msg',
      headerName: 'Error Message',
      width: 350,
      headerAlign: 'center'
    }
  ];

  const handleKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleUpdateRow = (res) => {
    const updatedRows = res.map((row, i) => ({
      id: i,
      batch: row.batch,
      wrong_orders: JSON.stringify(row.wrong_orders),
      err_msg: row.err_msg,
    }));
    setRows(updatedRows);
  };

  // not yet applied
  const handleRowClick = (selectedRow) => {}

  const handleSearch = useCallback(async (date, partner) => {
    setLoading(true);
    try {
      const res = await dispatch(getWrongAreaStatistic(date, partner)).catch(e => {
        throw e.message;
      })
      setData(res);
      handleUpdateRow(res);
      dispatch(showAlertSnackbar({message: 'Fetch Data Success', type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: e, type: 'error'}));
    } finally {
      setLoading(false);
    }
  }, [dispatch, setData, setLoading])

  async function handleDownload() {
    try {
      setLoading(true);
      let response = await downloadWrongAreaOrders(keyword);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Wrong-Area.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log('error msg = ', e);
      dispatch(showAlertSnackbar({message: 'Download Failed', type: 'error'}));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSearch(initialDate, initialPartner);
  }, [handleSearch])

  return (
    <div>
      <div className={classes.breadCrumbs}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Wrong Dest. Airport</p>
        {/*<Breadcrumbs aria-label="breadcrumb">*/}
        {/*  <Link*/}
        {/*    underline="hover"*/}
        {/*    color="inherit"*/}
        {/*    to="/main"*/}
        {/*  >*/}
        {/*    Home*/}
        {/*  </Link>*/}
        {/*  <Typography className={classes.breadCrumbText} color="text.primary">Wrong Dest. Airport</Typography>*/}
        {/*</Breadcrumbs>*/}
      </div>

      <div className={classes.searchBar}>
        <FormControl variant="outlined" className={classes.textField}>
          <InputLabel id="partner-label"> Partner </InputLabel>
          <Select
            labelId="partner-label"
            value={partner}
            label={"Partner"}
            onChange={(e)=>{setPartner(e.target.value)}}
          >
            {partnerList.map((partner) => (
              <MenuItem key={partner.id} value={partner.type}>
                {partner.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" className={classes.textField}>
          <Select
            value={time}
            onChange={(e)=>{setTime(e.target.value)}}
          >
            {timeList.map((time) => (
              <MenuItem value={time.type} key={time.type}>
                {time.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          id="prealert_date_text"
          type="date"
          variant="outlined"
          value={date}
          className={classes.textField}
          onChange={(e)=>setDate(e.target.value)}
        />

        <IconButton
          id={'prealert_search_button'}
          size={'large'}
          color={'primary'}
          onClick={() => handleSearch(date, partner)}
        >
          <SearchIcon fontSize={'inherit'}/>
        </IconButton>

        <FormControl variant="outlined" className={classes.textField}>
          <Select
            value={type}
            onChange={(e)=>{
              setType(e.target.value)
            }}
          >
            {typeList.map((type) => (
              <MenuItem value={type.type} key={type.type}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          id={'prealert_keyword_text'}
          variant='outlined'
          onChange={handleKeyword}
          value={keyword}
          className={classes.textField}
        />
        <IconButton
          id={'prealert_download_button'}
          size={'large'}
          color={'primary'}
          onClick={handleDownload}
        >
          <DownloadIcon fontSize={'inherit'}/>
        </IconButton>
      </div>

      <div className={classes.tableArea}>
        <DataGrid
          className={classes.table}
          rows={rows}
          columns={columns}
          alignItems="center"
          pageSize={10}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          onRowClick={(selectedRow) => handleRowClick(selectedRow)}
        />
      </div>
    </div>
  );
}

export default PrealertWrongAreaKeyword;