import { makeStyles, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, Button, Grid, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
// import { useTranslation } from 'react-i18next';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import BillingManagementSettlementDetail from '../BillingManagementSettlementDetail';
import { getInvoicePreview, getInvoice } from '../../../actions/billingManangementActions';
import { isNull } from 'lodash';

const tableWidth = 1400;

const useStyles = makeStyles((theme) => ({
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    minWidth: tableWidth
  },
  framedButton: {
    color: "rgba(33, 150, 243)",
    borderRadius: 4,
    border: "solid 1px rgba(33, 150, 243)",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 600,
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
    '&:disabled': {
      border: "solid 1px rgba(0, 0, 0, 0.12)",
      background: "rgba(0, 0, 0, 0.04)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  table: {
    width: '100%',
    height: 670,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .red_text_cell': {
      color: '#D32F2FDE',
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    fontWeight: 600,
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  cancelButton: {
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    fontWeight: 600,
  },
  buttonText: {
    color: "#1976d2",
    width: 53,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  redText: {
    color: '#D32F2F',
  },
  button: {
    fontSize: '14px',
    color: '#2196F3'
  },
  infoTitle: {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#a1a1a1',
  },
  infoValue: {
    fontSize: '13px'
  },
  invoiceTable: {
    '& th': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#a1a1a1',
      textAlign: 'left'
    },
    '& td': {
      fontSize: '13px',
      textAlign: 'left'
    },
  },
}));

export default function BillingManagementSettlementDisplay(props) {
  const { status, operators, settlementList, search } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { t } = useTranslation('billingManagement');
  const [loading, setLoading] = useState(false);
  const [dialog, setDialogOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [selected, setSelected] = useState([]);
  const [previewFailed, setPreviewFailed] = useState(null);
  const [invoicePreview, setInvoicePreview] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoiceSettlemements, setInvoiceSettlements] = useState([]);

  const settlementColumns = [
    {
      field: 'billing_num',
      headerName: "Billing ID",
      width: 200,
    },
    {
      field: 'settlement_status_code',
      headerName: "Billing Status",
      width: 230,
      valueGetter: (param) => {
        var temp = status.find(obj => {
          return obj.code === param.value;
        });
        return temp.name;

      }
    },
    {
      field: 'billing_start',
      headerName: "Billing Periods",
      width: 260,
      valueGetter: (params) => {
        //formated billing start
        dayjs.extend(weekOfYear);
        var start_date = dayjs(params.row.billing_start).format('YYYY/MM/DD');
        //formated billing end
        var end_date = dayjs(params.row.billing_end).format('YYYY/MM/DD');
        //week
        var week = dayjs(params.row.billing_start).week();

        return start_date + " - " + end_date + " Wk" + week;

      }
    },
    {
      field: 'invoice_ts',
      headerName: "Invoice Date",
      width: 290,
      valueGetter: (params) => {
        if (!params.value) {
          return '';
        }
        else {
          return dayjs(params.value).format('YYYY/MM/DD');
        }
      }
    },
    {
      field: 'invoice_number',
      headerName: "Invoice Number",
      width: 220,
    },
    {
      field: 'operator',
      headerName: "Operator",
      width: 192,
      valueGetter: (param) => {
        if (param.value === null) return '';
        const temp = operators?.find(obj => {
          return obj.id === param.value;
        });
        return temp?.username;
      }
    },
  ]

  function previewInvoice() {
    var billing_num = [];
    settlementList.forEach((row) => {
      if (selected.includes(row.id)) {
        billing_num.push(row.billing_num);
      }
    });
    setInvoiceSettlements(billing_num);
    if (!billing_num.length) {
      showAlertSnackbar({ message: 'No billing statement Selected', type: 'error' })
    }
    dispatch(getInvoicePreview({ billing_num: billing_num.toString() }))
      .then((res) => {
        setInvoicePreview(res);
      })
      .catch((err) => {
        setPreviewFailed(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function generateInvoice() {
    setLoading(true);
    
    dispatch(getInvoice({ file_name: "Invoice.pdf", billing_num: invoiceSettlemements.toString() }))
      .then((res) => {
        setInvoice(res);
      })
      .catch((err) => {
        showAlertSnackbar({ message: err.message, type: 'error' })
      })
      .finally(() => {
        search();
        setLoading(false);
      });

  }
  
  function handleDownloadInvoice(){
    
    const link = document.createElement('a');
    link.href = invoice;
    link.setAttribute('download', 'Invoice_'+invoiceSettlemements.toString()+".pdf");
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(invoice);
    document.body.removeChild(link);

    //open the dialog of the first billing_num
    var rowData = settlementList.find(row => row.billing_num === invoiceSettlemements[0])
    setRowData(rowData);
    setDialogOpen(true);
    
    //reset data & refresh
    setInvoice(null);
    setInvoicePreview(null);
    setInvoiceSettlements([]);
    
  }

  const reformatDate = (dateString) => {
    // Split the date string into month, day, and year
    const parts = dateString.split('/');
    // Rearrange the parts to yyyy-mm-dd format
    const formattedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
    return formattedDate;
  };

  return (
    <div className={classes.tableArea}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', color: "#577699", fontSize: 18, fontWeight: 600, }}>Billing Statements</div>

        <Button className={classes.framedButton} disabled={!selected.length || !settlementList.length} onClick={previewInvoice} >Generate Invoice</Button>
      </div>
      <DataGrid
        className={classes.table}
        loading={loading}
        rows={loading ? [] : settlementList}
        columns={settlementColumns}
        getCellClassName={(params) => {
          if (params.field === 'settlement_status_code' && params.value === 'Pending for Reconciliation') {
            return 'red_text_cell'
          }
        }}
        onRowClick={(params) => {
          const rowData = {};
          settlementColumns.forEach(column => {
            rowData[column.field] = params.getValue(params.id, column.field);
          });
          setDialogOpen(true);
          setRowData(rowData);
        }
        }
        onSelectionModelChange={(selection) => {
          setSelected(selection);
        }}
        selectionModel={selected}
        checkboxSelection
        logger
      />
      <BillingManagementSettlementDetail
        rowData={rowData}
        setDialogOpen={setDialogOpen}
        dialog={dialog}
        status={status}
        search={search}
      />

      <Dialog maxWidth={"sm"} fullWidth open={!isNull(previewFailed)}>

        <DialogTitle className={classes.dialogTitle}>
          <div style={{ position: 'relative' }}>
            <span>Failed to Generate Invoice</span>
          </div>
        </DialogTitle>

        <DialogContent>
          <div>Unable to generate an invoice for the selected bill due to the following reasons:</div>
          <div className={classes.redText} style={{ fontSize: 12, display: 'flex', flexDirection: "row", alignItems: "center", }}>
            <ul>
              {
                previewFailed &&
                previewFailed.map((err) => {
                  return <li>{err} </li>
                })
              }
            </ul>
          </div>

        </DialogContent>
        <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
          <Button className={classes.button} style={{ fontWeight: '00' }} autoFocus onClick={() => { setPreviewFailed(null) }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={"lg"} fullWidth open={!isNull(invoicePreview)}>

        <DialogTitle className={classes.dialogTitle}>
          <div style={{ position: 'relative' }}>
            <span>Generate Invoice</span>
          </div>
        </DialogTitle>

        <DialogContent>
          <div>Review the billing information then generate the invoice.</div>
          <Grid container item spacing={2} style={{ paddingTop: '14px' }}>
            <Grid item xs={3} className={classes.infoTitle}>Selected Billing ID:</Grid>
            <Grid item xs={6} >
              <span className={classes.infoValue}>{invoicePreview?.billing_id}</span>
            </Grid>
          </Grid>
          <Grid container item spacing={2} style={{ paddingTop: '14px' }}>
            <Grid item xs={3} className={classes.infoTitle}>Partner Name:</Grid>
            <Grid item xs={6} >
              <span className={classes.infoValue}>{invoicePreview?.partner}</span>
            </Grid>
          </Grid>

          <Table className={classes.invoiceTable} style={{ marginTop: '20px' }}>
            <TableHead>
              <TableRow>
                <TableCell>Cost Category</TableCell>
                <TableCell>Airport Code</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Net Price</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Currency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicePreview?.fees &&
                invoicePreview.fees.map((fee) => (
                  <TableRow>
                    <TableCell>{fee.description}</TableCell>
                    <TableCell>{fee.airport_code}</TableCell>
                    <TableCell>{reformatDate(fee.service_period.split('-')[0])}</TableCell>
                    <TableCell>{reformatDate(fee.service_period.split('-')[1])}</TableCell>
                    <TableCell>{fee.quantity}</TableCell>
                    <TableCell>{fee.net_price}</TableCell>
                    <TableCell>{fee.total}</TableCell>
                    <TableCell>{invoicePreview?.currency}</TableCell>
                  </TableRow>
                ))
              }
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{ backgroundColor: 'rgba(25, 118, 210, 0.10)' }}>Total Amount:</TableCell>
                <TableCell style={{ backgroundColor: 'rgba(25, 118, 210, 0.10)' }}>{invoicePreview?.total.toFixed(2)}</TableCell>
                <TableCell style={{ backgroundColor: 'rgba(25, 118, 210, 0.10)' }}>{invoicePreview?.currency}</TableCell>
              </TableRow>
            </TableBody>
          </Table>


        </DialogContent>
        <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
          <Button className={classes.button} style={{ marginRight: '20px' }} autoFocus onClick={() => { setInvoicePreview(null) }}>
            Cancel
          </Button>
          <Button variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '500' }} onClick={() => { setInvoicePreview(null); generateInvoice() }}>
            Generate Invoice
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={"md"} fullWidth fullwidth open={invoice} >
        <DialogTitle><b>Invoice</b></DialogTitle>
        <DialogContent>
          <object width="920" height="600" type="application/pdf" data={invoice}>
            <p>Cannot Render Invoice, Please click print to view Invoice</p>
          </object>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button className={classes.button} style={{ marginRight: '20px' }} autoFocus onClick={() => {setInvoice(null)}}>CLOSE</Button>
          <Button  variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '500' }} onClick={(e) => { handleDownloadInvoice()}}>DOWNLOAD INVOICE</Button>
        </DialogActions>
      </Dialog>

      <Backdrop id="loadingBackdrop" sx={{ color: '#fff', zIndex: 3000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  );
}
