import { Button, Checkbox, Grid, makeStyles, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { addServicePoint, editServicePoint } from "../../actions/servicePointActions";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme)=> ({
  root: {
    width: '80%',
    height:'100%',
    marginTop: 20
  },
  header: {
    marginLeft: '10%'
  },
  title: {
    textAlign: 'right',
    padding: 10
  },
  content: {
    padding: 10
  }
}))

const FixedTextValidator = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '10px !important',
    }
  },
}))(TextValidator);

function ServicePointAdd(props) {
  const {
    warehouse
  } = props;

  const classes = useStyles();
  const {t} = useTranslation('servicePointManagement');
  const dispatch = useDispatch();

  const cityList = useSelector(state => state.servicePoint.cityList);

  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] =  useState();
  const [serialNo, setSerialNo] = useState();
  const [companyName, setCompanyName] = useState();
  const [unit, setUnit] = useState();
  const [address, setAddress] = useState();
  // const [postalCode, setPostalCode] = useState();
  const [phone, setPhone] = useState();
  const [maxStorage, setMaxStorage] = useState();
  const [preciseType, setPreciseType] = useState();
  const [businessHours, setBusinessHours] = useState();
  const [deviceUsed, setDeviceUsed] = useState();
  const [remark, setRemark] = useState();
  const [openChecked, setOpenChecked] = useState(true);

  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
  };
  const handleFailClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFailOpen(false);
  };

  useEffect(() => {
    if(props.addOpen || props.editOpen) {
      // const city = window.localStorage.getItem('warehouse');
      const city = warehouse;
      if(city !== null && city !== "" && Array.isArray(cityList)) {
        cityList.forEach((item) => {
          if (item.id + '' === city + '' ) {
            setDistrictList(item.district);
            setDistrict(item.district[0].id);
          }
        });
      }
    }
  }, [props.addOpen, props.editOpen, cityList, warehouse])
  useEffect(() => {
    if(props.editOpen && props.info) {
      if(props.info[0]) {
        setDistrict(props.info[0].district);
        setSerialNo(props.info[0].name);
        setCompanyName(props.info[0].company);
        setUnit(props.info[0].unit_number);
        setAddress(props.info[0].address);
        // setPostalCode(props.info);
        setPhone(props.info[0].phone);
        setMaxStorage(props.info[0].storage_space);
        setPreciseType(props.info[0].premise_type);
        setBusinessHours(props.info[0].business_hours);
        setDeviceUsed(props.info[0].device);
        setRemark(props.info[0].remark);
        setOpenChecked(props.info[0].is_active === 0);
      }
    }
  }, [props.editOpen, props.info])
  useEffect(() => {
    if(props.editOpen || props.addOpen) {
      setDistrict(0);
      setSerialNo('');
      setCompanyName('');
      setUnit('');
      setAddress('');
      setPhone('');
      setMaxStorage(0);
      setPreciseType('');
      setBusinessHours('');
      setDeviceUsed('');
      setRemark('');
      setOpenChecked(true);
    }
  }, [props.editOpen, props.addOpen])

  function handleAddServicePoint() {
    let params = {};
    params.warehouse = window.localStorage.getItem('warehouse');
    params.district = district;
    params.company = companyName;
    params.unit_number = unit;
    params.address = address;
    params.phone = phone;
    params.storage_space = maxStorage;
    params.premise_type = preciseType;
    params.business_hours = businessHours;
    params.device = deviceUsed;
    params.remark = remark;
    params.is_active = openChecked ? 0 : 1;
    if(props.editOpen) {
      dispatch(editServicePoint(serialNo, params))
        .then(() => {
          setSuccessOpen(true)
        })
        .catch(() => {
          setFailOpen(true);
        })
    } else if(props.addOpen) {
      dispatch(addServicePoint(serialNo, params))
        .then(() => {
          setSuccessOpen(true)
        })
        .catch(() => {
          setFailOpen(true);
        })
    }
  }

  return (
    <Paper className={classes.root}>
      <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          {t('edit_add_success_message')}
        </Alert>
      </Snackbar>
      <Snackbar open={failOpen} autoHideDuration={6000} onClose={handleFailClose}>
        <Alert onClose={handleFailClose} severity="error">
          {t('edit_add_fail_message')}
        </Alert>
      </Snackbar>
      <ValidatorForm onSubmit={handleAddServicePoint}>
        <Grid container spacing={6}>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('company_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('serial_no')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                value={serialNo}
                onChange={(e)=>setSerialNo(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('company')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                style={{width: 500}}
                value={companyName}
                onChange={(e)=>setCompanyName(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('address_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('apt_unit')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                style={{width: 100}}
                value={unit}
                onChange={(e)=>setUnit(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('address')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                style={{width: 500}}
                value={address}
                onChange={(e)=>setAddress(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
              <div>{t('address_format')}</div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>
              {t('district')}
            </Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                id="district"
                select
                label={t('list_district')}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={district}
                onChange={(e)=>{setDistrict(parseInt(e.target.value))}}
                SelectProps={{
                  native: true,
                }}
                style={{width: 300}}
                validators={['required']}
                errorMessages={[t('required')]}
              >
                {
                  districtList.map((item,index) => (
                    <option key={index} value={item.id}>
                      {item.text}
                    </option>
                  ))
                }
              </FixedTextValidator>
            </Grid>
            {/* <Grid item xs={12} sm={4} className={classes.title}>Postal Code</Grid>
                        <Grid item xs={12} sm={8} className={classes.content}>
                            <FixedTextValidator
                                variant="outlined"
                                style={{width: 100}}
                                value={postalCode}
                                onChange={(e)=>setPostalCode(e.target.value)}
                                validators={['required', 'matchRegexp:^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$']}
                                errorMessages={[t('required'), t('wrong_postal_format')]}
                            />
                            <div>{t('postal_format')}</div>
                        </Grid> */}
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('contact_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('phone')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                style={{width: 200}}
                value={phone}
                onChange={(e)=>setPhone(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('storage_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('max_store')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                style={{width: 100}}
                value={maxStorage}
                onChange={(e)=>setMaxStorage(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('business_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('premise_type')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                style={{width: 300}}
                value={preciseType}
                onChange={(e)=>setPreciseType(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('business_hours')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                style={{width: 600}}
                value={businessHours}
                onChange={(e)=>setBusinessHours(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
              <div>{t('business_hours_format')}</div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('device_used')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                variant="outlined"
                value={deviceUsed}
                onChange={(e)=>setDeviceUsed(e.target.value)}
                validators={['required']}
                errorMessages={[t('required')]}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('remark_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('remark')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <FixedTextValidator
                multiline
                maxRows={5}
                variant="outlined"
                style={{width: 300}}
                value={remark}
                onChange={(e)=>setRemark(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('service_point_status')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <span>{t('close_service_point')}</span>
              <Checkbox
                color='secondary'
                checked={!openChecked}
                onChange={(e)=>{
                  setOpenChecked(!(e.target.checked));
                }}
              />
              <span style={{marginLeft: 20}}>{t('open_service_point')}</span>
              <Checkbox
                color='secondary'
                checked={openChecked}
                onChange={(e)=>{
                  setOpenChecked(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Button type="submit" variant='contained' color='primary'>{t('submit')}</Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Paper>
  )
}
export default ServicePointAdd;