/*
    *** This is the structure for the menu object ***
    id: unique identifier
    link: link to be opened when clicking on the button
    title: title text of the button (translations are automatically done)
    disabled: if button should be disabled
    password_protection: boolean, to determine if content is locked behind password
    access_ability_id: number, the access id that shows/hides the menu item
*/

export const mainMenuItems = [
  {
    id: "main",
    link: "/main",
    title: 'Home',
    access_ability_id: 0
  },
  {
    id: "local-delivery-main",
    link: "/local-delivery-main",
    title: 'Local Delivery',
    access_ability_id: 1,
    // nav: [
    //   {
    //     title: "Local Delivery",
    //     id: 0
    //   },
    // ]
  },
  {
    id: "sandbox-test-main",
    link: "/sandbox-test-main",
    title: 'Sandbox',
    access_ability_id: 2,
    nav: [
      {
        title: "API Sandbox",
        id: 0
      },
      {
        title: "Account",
        id: 1
      }
    ]
  },
  {
    id: "t4a-main",
    link: "/t4a-main",
    title: 'T4A',
    access_ability_id: 3
  },
  {
    id: "service-point-main",
    link: "/service-point-main",
    title: 'Service Point',
    access_ability_id: 4
  },
  {
    id: "postal-outlet",
    link: "/postal-outlet",
    title: 'Postal Outlet',
    access_ability_id: 5,
    nav: [
      {
        title: "Place Order",
        id: 0
      },
      {
        title: "Orders",
        id: 1
      }
    ]
  },
  {
    id: "prealert-statistic",
    link: "/prealert-statistic",
    title: 'Prealert Statistic',
    access_ability_id: 6,
    nav: [
      {
        title: "Pre-alert Reports",
        id: 0
      },
      {
        title: "Failed Imports",
        id: 1
      },
      {
        title: "Wrong Dest. Airport",
        id: 2
      },
      {
        title: "Partner Incoming Volume",
        id: 3
      },
    ]
  },
  {
    id: "organization-main",
    link: "/organization",
    title: 'Organization',
    access_ability_id: 7,
    nav: [
      {
        title: "Partner",
        id: 0
      },
      {
        title: "Customer",
        id: 1
      }
    ]
  },
  {
    id: "user-management",
    link: "/user-management",
    title: 'User Management',
    access_ability_id: 8,
  },
  {
    id: "delivery-capacity",
    link: "/delivery-capacity",
    title: 'Delivery Capacity',
    access_ability_id: 9,
    nav: [
      {
        title: "Overview",
        id: 0
      },
      {
        title: "Update Capacity",
        id: 1
      },
      {
        title: "Update Threshold",
        id: 2
      }
    ]
  },
  {
    id: "faulty-package",
    link: "/faulty-package",
    title: 'Faulty Package',
    access_ability_id: 10,
  },
  {
    id: "faulty-package-gv",
    link: "/faulty-package-gv",
    title: 'Faulty Package',
    access_ability_id: 11,
  },
  {
    id: "gv-shipping-management",
    link: "/gv-shipping-management",
    title: 'GV Shipping Mngmt',
    access_ability_id: 12,
    nav: [
      {
        title: "Order Replenishement",
        id: 0
      },
    ]
  },
  {
    id: "billing-and-invoice",
    link: "/billing-and-invoice",
    title: 'Billing and Invoice',
    access_ability_id: 13,
    nav: [
      {
        title: "Billing Management",
        id: 0
      },
      {
        title: "Billing Supplementary",
        id: 1
      },
      {

        title: "Invoice Management",
        id: 2
      },
      {
        title: "Invoice Number Generator",
        id: 3
      },
    ]
  },
  {
    id: "address-management",
    link: "/address-management",
    title: 'Address Management',
    access_ability_id: 14,
    nav: [
      // {
      //   title: "Factory Address",
      //   id: 0
      // },
      {
        title: "Blocked Address",
        id: 0
      },
    ]
  },
];