import { shangjiaServerInstance } from "../axios/instances";
import { GET_BRANCH_LIST, GET_BRANCH_LIST_SUCCESS, GET_BRANCH_LIST_FAIL } from "../actions/types"

export function getBranchList(){
    return function(dispatch){
        dispatch({type: GET_BRANCH_LIST})
        return shangjiaServerInstance.get("/business/getbranchlist")
            .then(({data}) => {
                dispatch({
                    type: GET_BRANCH_LIST_SUCCESS,
                    payload: data.data
                })
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: GET_BRANCH_LIST_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            })
    }
}