import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import {createContext} from "react";
import {parseJwt} from "../utilities/common";
import {mainMenuItems} from "../components/Common/NavBarMenu/mainMenu";

export const TokenContext = createContext();

function ProtectedRoute({route}) {
  const token = window.localStorage.getItem("auth_token");

  let uiAbilitiesList,
    getUserFromToken,
    userRoles,
    authorizedUiAbilities,
    authorizedCustomers,
    authorizedRoutes;

  if (token) {
    uiAbilitiesList = [...mainMenuItems].filter(
      (menuItem) => (menuItem.link)
    );
    getUserFromToken = parseJwt(token);
    userRoles = getUserFromToken['roles'] || [];
    authorizedUiAbilities = getUserFromToken['ui_abilities'] || [];
    authorizedCustomers = (getUserFromToken['customer'] || []).map(Number);

    authorizedRoutes = uiAbilitiesList.filter(
      (ability) => authorizedUiAbilities.includes(ability.access_ability_id)
    ).map(
      (ability) => (ability.link)
    );
  }

  return (
    <TokenContext.Provider
      value={{
        roles: userRoles,
        uiAbilities: authorizedUiAbilities,
        customer: authorizedCustomers,
      }}
    >
      <div>
        {
          token ?
            renderRoutes(route.routes.filter(
              (route) => (
                route.path === '/main' ||
                authorizedRoutes.includes(route.path)
              )
            )) :
            <Redirect to='/login' />
        }
      </div>
    </TokenContext.Provider>
  );
}

export default ProtectedRoute