import React, { useState, useEffect, } from 'react';
// import { useToken } from '../../../utilities/hooks';
import { 
  useDispatch, 
  // useSelector, 
} from 'react-redux';
import { makeStyles, } from '@material-ui/core/styles';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box, Divider, Checkbox, TextField, Button, Paper,
  List, ListItem, ListItemText, ListItemIcon,
  Dialog, DialogActions, DialogContent, DialogContentText
} from '@material-ui/core';
import {
  updateUserInformation, updateUserRoles,
  getAbilityListByUser, deleteUser
} from '../../../actions/userManagementActions';
import CreateUser from "../CreateUser/CreateUser";

const useStyles = makeStyles(
  (theme) => ({
    contents: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1 0 auto',
      height: '85vh',
      backgroundColor: theme.palette.background.default,
    },
    mainSection: {
      padding: 16,
      width: '60%',
      overflow: 'auto',
    },
    sideSection: {
      padding: 16,
      width: '20%',
      overflow: 'auto',
    },
    whiteText: {
      // color: '#e1e1e1',
    },
    orangeText: {
      color: '#fa8200',
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '8px 0 8px 0',
    },
    inputLabel: {
      width: 140,
      textAlign: 'right',
      margin: '0 8px 0 0',
    },
    inputField: {
      margin: '0 0 0 8px'
    },
    paper: {
      height: '100%',
      overflow: 'auto',
    },
    denseList: {
      padding: 0,
    },
    listButton: {
      padding: '0 7px 0 7px',
    },
    mainForm: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    transferList: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      margin: '8px 0 16px 0',
    },
    transferListLeft: {
      width: '43%',
      margin: '0 auto 0 0',
    },
    transferListMiddle: {
      width: '11%',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
    },
    transferListRight: {
      width: '43%',
      margin: '0 0 0 auto',
    },
    transferListButton: {
      minWidth: '20px',
    },
    bottomButtonBar: {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto 0 0 0',
    },
    loading: {
      display: 'block',
      margin: 'auto auto auto auto',
    },
    deleteDialog: {
      display: 'flex',
      alignItems: 'center'
    },
  })
);

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

// const SYSTEM_ADMINISTRATOR = 'System Administrator';
const SYSTEM_ADMINISTRATOR_ID = 1;

export default function Users(props) {
  const {
    rolesList, abilitiesList, usersList,
    customer, fetchData, setLoading, showSnack,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation('userManagement');
  const dispatch = useDispatch();
  // const tokenContents = useToken();
  // const customerList = useSelector(state => state.managementReducer.customers);
  // const authorizedCustomers = tokenContents.customer;
  // const userRoles = tokenContents.roles;

  const [selectedId, setSelectedId] = useState(0);
  const [selectedUserCustomer, setSelectedUserCustomer] = useState([]);
  const [selectedUserAbilities, setSelectedUserAbilities] = useState([]);
  const [filteredUserlist, setFilteredUserList] = useState([]);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // const userIsSystemAdministrator = tokenContents.roles.includes(SYSTEM_ADMINISTRATOR);

  useEffect(() => {
    setSelectedId(-1);
  }, [customer]);

  useEffect(() => {
    setFilteredUserList(usersList);
  }, [usersList]);

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  }

  const parseRoleListByUser = (roles, user, invert) => {
    if (!user) return [];

    return roles.filter(
      (role) => {
        let isRoleInUserRoles = user.roles.map(
          (role) => (role.role_id)
        ).includes(role.id);

        return !!(isRoleInUserRoles ^= invert);
      }
    )
  };

  const handleDeleteUser = async (userId) => {
    try {
      setLoading(true);
      await Promise.all([
        dispatch(deleteUser(userId)),
      ]);
      setSelectedId(-1);
      showSnack('success')(t('delete_user_success'));
    } catch (error) {
      showSnack('error')(t('delete_user_failed'));
    } finally {
      await fetchData();
      setDeleteOpen(false);
      setLoading(false);
    }
    /* TODO MVP version don't consider admin yet
    if (userIsSystemAdministrator) {
      try {
        setLoading(true);
        await Promise.all([
          dispatch(deleteUser(roleId)),
        ]);
        setSelectedId(-1);
        showSnack('success')(t('delete_user_success'));
      } catch (error) {
        showSnack('error')(t('delete_user_failed'));
      } finally {
        await fetchData();
        setDeleteOpen(false);
        setLoading(false);
      }
    } else {
      showSnack('error')(t('delete_user_failed'));
    }
    */
  };

  const handleToggle = (value) => () => {
    if (value.id === SYSTEM_ADMINISTRATOR_ID) return;

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleUserListItemClick = async (user) => {
    if (!user) return;
    setSelectedId(user.id);
    setSelectedUserCustomer(user ? user.customer : []);

    setRight(parseRoleListByUser(rolesList, user, false));
    setLeft(parseRoleListByUser(rolesList, user, true));

    try {
      setLoading(true);
      const res = await dispatch(getAbilityListByUser(user.id));
      setSelectedUserAbilities(res.map(
        (permission) => (permission.ability_id)
      ));
    } catch (error) {
      showSnack('error')(t('network_error'));
    } finally {
      setLoading(false);
    }

  };

  // const handleCustomerListClick = (id) => {
  //   if (selectedUserCustomer.indexOf(id) === -1) {
  //     setSelectedUserCustomer([...selectedUserCustomer, id]);
  //   } else {
  //     setSelectedUserCustomer(
  //       selectedUserCustomer.filter(
  //         (customer) => (customer !== id)
  //       )
  //     );
  //   }
  // };

  const findUserById = () => {
    return _.find(usersList, { 'id': selectedId });
  }

  const handleSaveUserClick = async () => {
    if (!findUserById().id) {
      showSnack('error')(t('user_id_not_exist'));
      return;
    }
    try {
      setLoading(true);

      await Promise.all([
        dispatch(updateUserInformation(
          findUserById().id,
          selectedUserCustomer.toString(),
        )),
        dispatch(updateUserRoles(
          findUserById().id,
          right.map((role) => (role.id)).toString(),
        )),
      ]);

      showSnack('success')(t('save_user_permission_success'));
    } catch (error) {
      showSnack('error')(t('save_user_permission_failed'));
    } finally {
      await fetchData();
      setLoading(false);
    }
  };

  const handleFilterUser = (strings) => {
    const filteredList = _.filter(usersList, function(o) {
      return o.username.match(new RegExp(strings,'i'));
    });
    setFilteredUserList(filteredList);
  };

  const customList = (items, section) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {
          items.map(
            (value) => {
              if (value.id === SYSTEM_ADMINISTRATOR_ID && section === "left") {
                return null;
              } else {
                return (
                  <ListItem
                    className={classes.denseList}
                    key={value.id}
                    role="listitem"
                    button
                    onClick={handleToggle(value)}
                  >
                    <ListItemIcon id={`transfer-list-item-${value.id}-icon`}>
                      <Checkbox id={`transfer-list-item-${value.id}-checkbox`}
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        disabled={
                          (value.id === SYSTEM_ADMINISTRATOR_ID && section === "right")
                        }
                      />
                    </ListItemIcon>

                    <ListItemText id={`transfer-list-item-${value.id}-label`}
                      classes={{
                        primary: (
                          (value.id === SYSTEM_ADMINISTRATOR_ID) ?
                            classes.orangeText
                            :
                            classes.whiteText
                        ),
                      }}
                      primary={value.name}
                    />
                  </ListItem>
                );
              }
            }
          )
        }
      </List>
    </Paper>
  );

  return (<>
    {/*<UserManagement fetchData={fetchData} />*/}

    <CreateUser
      open={createOpen}
      setOpen={setCreateOpen}
    />

    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      classes={classes.deleteDialog}
    >
      <DialogContent>
        <DialogContentText>
          {t('sure_to_delete_user')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          id={'cancel-delete-user'}
          onClick={handleDeleteClose}
          color="primary"
        >
          {t('cancel')}
        </Button>

        <Button
          id={'confirm-delete-user'}
          onClick={() => handleDeleteUser(findUserById()?.id)}
          color="primary"
          autoFocus
        >
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>

    <Box className={classes.contents}>
      <Box className={classes.sideSection}>
        <TextField
          id="filled-search"
          label="Search User"
          type="search"
          variant="outlined"
          onChange={(event)=>handleFilterUser(event.target.value)}
          fullWidth
        />
        <List className={classes.denseList} aria-label="user list">
          {
            filteredUserlist.map(
              (user, index) => (
                <ListItem
                  className={classes.listButton}
                  key={user.id}
                  selected={selectedId === user.id}
                  button
                  onClick={() => { handleUserListItemClick(user) }}
                >
                  <ListItemText
                    id={'user-select-'+user.id}
                    primary={user.username}
                  />
                </ListItem>
              )
            )
          }
        </List>
      </Box>

      <Divider orientation='vertical' flexItem />

      <Box className={classes.mainSection}>
        <div className={classes.mainForm}>
          <div className={classes.inputGroup} style={{ marginTop: 0 }}>
            <div className={classes.inputLabel}>
              {t('account_name')}
            </div>

            <TextField
              className={classes.inputField}
              variant="outlined"
              size="small"
              value={
               findUserById()?.username ?? ''
              }
              fullWidth
            />
          </div>

          {/* <Accordion style={{ margin: '8px 0 8px 0' }}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <Typography className={classes.whiteText}>{t('customer_access')}</Typography>
           </AccordionSummary>

           <AccordionDetails>
             <List style={{ display: 'flex', flexFlow: 'row wrap' }}>
               {
                 customerList &&
                 customerList.map(
                   (customer) => (
                     <ListItem className={classes.denseList} style={{ width: '300px' }}
                               key={customer.id}
                               value={customer.id}
                     >
                       <Checkbox
                         checked={selectedUserCustomer.indexOf(customer.id) !== -1}
                         onClick={() => { handleCustomerListClick(customer.id) }}
                         disabled={
                           userRoles.includes("System Administrator") ?
                             false
                             :
                             !authorizedCustomers.includes(customer.id)
                         }
                         tabIndex={-1}
                       />
                       {customer.name}
                     </ListItem>
                   )
                 )
               }
             </List>
           </AccordionDetails>
          </Accordion> */}

          <div className={classes.transferList}>
            <div className={classes.transferListLeft}>{customList(left, "left")}</div>

            <div className={classes.transferListMiddle}>
              <Button
                className={classes.transferListButton}
                id={'add-abilities'}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>

              <Button
                className={classes.transferListButton}
                id={'remove-abilities'}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </div>

            <div className={classes.transferListRight}>{customList(right, "right")}</div>
          </div>

          <div className={classes.bottomButtonBar}>
            <Button style={{ margin: '0 auto 0 0' }}
              variant='contained'
              color='primary'
              onClick={() => setCreateOpen(true)}
            >
              {t('create_user')}
            </Button>

            <Button
              style={{ margin: '0 4px 0 auto' }}
              id={'delete-user-button'}
              variant='contained'
              color='secondary'
              // disabled={!userIsSystemAdministrator}
              onClick={handleDeleteOpen}
            >
              {t('delete_user')}
            </Button>

            <Button
              style={{ margin: '0 0 0 4px', color: '#616161' }}
              id={'save-changes-button'}
              variant='contained'
              disabled={filteredUserlist.length === 0}
              onClick={() => { handleSaveUserClick() }}
            >
              {t('save_change')}
            </Button>
          </div>
        </div>
      </Box>

      <Divider orientation='vertical' flexItem />

      <Box className={classes.sideSection}>
        <List className={classes.denseList} aria-label="abilities list">
          {
            abilitiesList.filter(
              (ability) => (
                selectedUserAbilities.indexOf(ability.id) !== -1 &&
                ability.type === 'ui'
              )
            ).map(
              (ability) => (
                <ListItem className={classes.denseList}
                          key={ability.id}
                >
                  <ListItemText
                    primary={ability.title}
                  />
                </ListItem>
              )
            )
          }
        </List>
      </Box>
    </Box>

  </>);

}
