import React, { useEffect, useState } from 'react';
import { changeLanguagePref, getLanguageString } from '../../utilities/language';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Language } from '@material-ui/icons';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import { useTranslation } from 'react-i18next';
import ServicePointManagement from '../../components/ServicePointManagement';
import ServicePointSearchKeyword from '../../components/ServicePointSearchKeyword';
import ServicePointAdd from '../../components/ServicePointAdd';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import ServicePointPackagesStatistics from '../../components/ServicePointPackagesStatiistics';
import ServicePointSearchDate from '../../components/ServicePointSearchDate';
import { useDispatch } from 'react-redux';
import {getBasicInfo, searchServicePoint} from '../../actions/servicePointActions';
import { useHistory } from 'react-router';
import CloseIcon from "@material-ui/icons/Close";
import {FormControl, Select} from "@material-ui/core";
import {MenuItem} from "@mui/material";
import {getBranchList} from "../../actions/commonActions";

const drawerWidth = 240;

const useStyles = makeStyles((theme)=> ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  selectIcon: {
    fill: 'white',
  },
  selectRoot: {
    color: 'white',
  },
}))

function ServicePointMain(){
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation('servicePoint');
  const history = useHistory();
  // const servicePointLogin = useSelector(state => state.user.servicePointLogin)
  const [menuList, setMenuList] = useState([true, false, false, false]);
  const [menu, setMenu] = useState(0);
  const [keyword, setKeyword] = useState();
  const [servicePointInfo, setServicePointInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [warehouse, setWarehouse] = useState(window.localStorage.getItem('warehouse'));
  const [warehouseList, setWarehouseList] = useState([]);

  const handleChangeWarehouse = (e) => {
    const id = e.target.value;
    setWarehouse(id);
    window.localStorage.setItem('warehouse', id);
  }

  function handleClickMenu(menuIndex) {
    let menuListCopy = JSON.parse(JSON.stringify(menuList));
    // eslint-disable-next-line array-callback-return
    menuListCopy.map((menu, index) => {
      menuListCopy[index] = menuIndex === index;
    })
    setMenu(menuIndex);
    setMenuList(menuListCopy);
  }
  function handleKeyword(word) {
    setKeyword(word);
  }
  function handleServicePointInfo(info) {
    setServicePointInfo(info)
  }
  function handleLoading(loading) {
    setIsLoading(loading);
  }

  function handleLogOut() {
    setIsLoading(true);
    history.push('/main');
  }

  useEffect(() => {
    dispatch(getBasicInfo())
    if(menu === 0) {
      let warehouse = window.localStorage.getItem('warehouse');
      dispatch(searchServicePoint(warehouse, 0))
    }
  }, [menu, warehouse, dispatch])

  useEffect(() => {
    dispatch(getBranchList())
      .then(res => setWarehouseList(res))
  }, [dispatch])

  // useEffect(() => {
  //     if(Object.keys(servicePointLogin).length <= 0) {
  //         history.push('/');
  //         // userLogout();
  //     }
  // }, [servicePointLogin])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img
                src='/images/icons/icon_label.png'
                alt=''
                style={{
                  width: 111,
                  marginRight: 10
                }}
              />
              <Typography variant="h6" noWrap>
                {t('service_point_title')}
              </Typography>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <FormControl variant="outlined" style={{width: '200px', margin: '5px 40px 5px 0px'}}>
                <Select
                  id={'service-point-warehouse-select'}
                  inputProps={{
                    classes: {
                      icon: classes.selectIcon,
                      root: classes.selectRoot,
                    },
                  }}
                  value={warehouse}
                  onChange={handleChangeWarehouse}
                >
                  {warehouseList.map((value) => (
                    <MenuItem value={value.id} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box style={{cursor: 'pointer'}}>
                <Button onClick={handleLanguageChange} startIcon={<Language/>}>{getLanguageString(i18n.language)}</Button>
              </Box>
              <Box style={{cursor: 'pointer', marginLeft: 17}}>
                <CloseIcon
                  style={{
                    margin: '5 0 0 auto'
                  }}
                  onClick={handleLogOut}
                />
              </Box>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {[t('management'), t('add'), t('edit')].map((text, index) => (
              <ListItem
                button
                key={text}
                onClick={() => {
                  handleClickMenu(index);
                }}
                style={
                  menuList[index] ?
                    {backgroundColor: '#e0e0e0'} :
                    {backgroundColor: 'white'}
                }
              >
                {
                  index === 0 ?
                    <ListItemIcon>
                      <AccountTreeIcon />
                    </ListItemIcon> :
                    (
                      index === 1 ?
                        <ListItemIcon>
                          <AddLocationIcon />
                        </ListItemIcon> :
                        <ListItemIcon>
                          <EditLocationIcon />
                        </ListItemIcon>
                    )

                }
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {[t('package_statistics')].map((text, index) => (
              <ListItem
                button
                key={text}
                onClick={() => {
                  handleClickMenu(index + 3);
                }}
                style={
                  menuList[index + 3] ?
                    {backgroundColor: '#e0e0e0'} :
                    {backgroundColor: 'white'}
                }
              >
                <ListItemIcon>{index % 2 === 0 ? <DeveloperBoardIcon /> : <EmojiSymbolsIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <div hidden={menu!==0}>
          <ServicePointSearchKeyword handleKeyword={handleKeyword}  handleServicePointInfo={handleServicePointInfo} type={"uiSearch"}/>
          <ServicePointManagement keyword={keyword}/>
        </div>
        <div hidden={menu!==1}>
          <ServicePointAdd addOpen={menuList[1]} info={servicePointInfo} warehouse={warehouse}/>
        </div>
        <div hidden={menu!==2}>
          <ServicePointSearchKeyword type={"apiSearch"} handleServicePointInfo={handleServicePointInfo} handleKeyword={handleKeyword}/>
          <ServicePointAdd editOpen={menuList[2]} info={servicePointInfo} warehouse={warehouse}/>
        </div>
        <div hidden={menu!==3}>
          <ServicePointSearchDate handleLoading={handleLoading}/>
          <ServicePointPackagesStatistics ratioOpen={menuList[3]} isLoading={isLoading}/>
        </div>
      </main>
    </div>
  );

  function handleLanguageChange(){
    if(i18n.language === "en"){
      changeLanguagePref(i18n, "cn")
    } else {
      changeLanguagePref(i18n, "en")
    }
  }
}

export default ServicePointMain