import React, { useState } from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import PrmFrame from "../../components/PrmFrame";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";
import OrderReplenishment from "../../components/OrderReplenishment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const GVSHIPPINGMANAGEMENT_ACCESS_ID = 12;

function GVShippingManagement(){
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={GVSHIPPINGMANAGEMENT_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />

      <PrmMain
        className={classes.content}
        open={open}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <OrderReplenishment />
        </div>
      </PrmMain>
    </div>
  );
}

export default GVShippingManagement