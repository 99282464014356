import {
  Button, makeStyles, Dialog, DialogTitle, DialogActions,
  DialogContent, Typography, MenuItem,
  TextField, InputAdornment
} from '@material-ui/core';
import IconButton from "@mui/material/IconButton";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  FaultyPackageIssueType, FaultyPackageRecordStatus, FaultyPackageClientDecision,
  FaultyPackageActionTaken, FaultyPackagePackageStatus
} from '../../../constants/general.js';
import { getFileById, deleteRecord, getImagesById, saveImagesById, updateRecordInformation, getInfoByTrackingNumber } from '../../../actions/faultyPackageActions.js';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageIcon from '@mui/icons-material/Image';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
  },
  framedButton: {
    color: " #2a3848",
    borderRadius: 4,
    border: "solid 1px #2a3848",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 600,
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  table: {
    height: 670,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 13,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      color: 'rgba(0, 0, 0, 0.6)'
    },
   '& .MuiDataGrid-columnHeaderTitle' :{
    fontWeight: 600,
   },
    '& .MuiDataGrid-row': {
      height: 56,
      padding: '0 8px 0'
    },
    '& .ActionRequired': {
      color: '#D32F2FDE',
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    minWidth: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonText: {
    backgroundColor: "white",
    boxShadow: " 0 0px 0px 0 rgba(0, 0, 0, 0)",
    color: "#1976d2",
    minWidth: 60,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)',
      boxShadow: " 0 0px 0px 0 rgba(0, 0, 0, 0)",
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  dialogTextBox: {
    flex: 1,
    margin: "16px 12px",
  },
  deleteButton: {
    backgroundColor: "#D32F2F",
    color: " #fff",
    minWidth: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      backgroundColor: '#D32F2F'
    },
  },
  accordionSection: {
    '&.MuiAccordion-root.Mui-disabled': {
      backgroundColor: "#FFFFFF",
    }
  },
  uploadImageButton: {
    color: "#1976d2",
    borderRadius: 4,
    border: "solid 1px #1976d2",
    fontSize: "12px",
    textAlign: "center",
    padding: "10px",
    fontWeight: 600,
    background: "rgba(0, 0, 0, 0)",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
  },
  imageThumbnail: {
    color: "#00000033",
    fontSize: "12px",
  },
  disabledAccordion: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    opacity: 0.6,
    transition: 'opacity 0.3s ease',
  },
  hiddenInput: {
    display: 'none',
  },
  statistics:{
    color:"#55A6FF",
    fontSize: "12px",
    fontWeight:"600"
  }
}));

const RECORD_INFO = {
  record_date: "",
  warehouse: "",
  tracking_number: "",
  sender_reference: "",
  item_number: "",
  issue_type: "",
  uni_notes: "",
  package_status: "",
  verified_tracking_number: "",
  client_decision: "",
  action_taken: "",
  client_notes: "",
  closing_notes: "",
  closed_at: ""
};

const NEW_RECORD_STATUS = 1;
const CLIENT_REVIEWED = 2;
const UNI_CONFIRMED = 3;

export default function FaultyPackageDisplay(props) {
  const {
    records, stats, 
    warehouseDropDown, refresh,setloading
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [editUniRecordOpen, setEditUniRecordOpen] = useState(false);
  const [recordInfo, setRecordInfo] = useState(RECORD_INFO);
  const [editing, startEdit] = useState(false);
  const [confirming, startConfirm] = useState(false);
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [deletConf, openDeleteConf] = useState(false);
  const [file, setFile] = useState(null);
  const [imageDetail, setImageDetail] = useState(null);
  const [dialogStateSettings, setdialogStateSettings] = useState({});

  // useEffect(() => {
  //   console.log('AAA', role);
  // }, [])

  const faultyPackageColumns = [
    {
      field: 'created_at',
      headerName: "Creation Time",
      width: 200,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'warehouse',
      headerName: "Warehouse",
      width: 200,
      valueGetter: (params) => {
        return warehouseDropDown[params.row.warehouse];
      },
    },
    {
      field: 'tracking_number',
      headerName: "Tracking No.",
      width: 200,
    },
    {
      field: 'sender_reference',
      headerName: "Sender Ref",
      width: 200,
    },
    {
      field: 'item_number',
      headerName: "Item No.",
      width: 200,
    },
    {
      field: 'issue_type',
      headerName: "Issue Type",
      width: 200,
      valueGetter: (params) => {
        return FaultyPackageIssueType[params.row.issue_type];
      },
    },
    {
      field: 'package_status',
      headerName: "Package Status",
      width: 200,
      valueGetter: (params) => {
        return FaultyPackagePackageStatus[params.row.package_status];
      },
    },
    {
      field: 'record_status',
      headerName: "Record Status",
      width: 200,
      valueGetter: (params) => {
        if (params.row.record_status === CLIENT_REVIEWED) {
          return "Action Required";
        } else {
          return FaultyPackageRecordStatus[params.row.record_status];
        }
      },
    },
  ]

  const handleOpenRecord = async (e) => {
    setloading(true);
    let temp = e.row;
    //set the states of accordion sections based on role and record state
    if (temp.record_status === NEW_RECORD_STATUS) {
      setdialogStateSettings({
        disabled1: false,
        editable1: true,
        expanded1: true,
        disabled2: true,
        editable2: false,
        expanded2: false,
        disabled3: true,
        editable3: false,
        expanded3: false,
      })
    } else if (temp.record_status === CLIENT_REVIEWED) {
      setdialogStateSettings({
        disabled1: false,
        editable1: false,
        expanded1: true,
        disabled2: false,
        editable2: false,
        expanded2: true,
        disabled3: false,
        editable3: false,
        expanded3: false,
      })
    } else if (temp.record_status === UNI_CONFIRMED) {
      setdialogStateSettings({
        disabled1: false,
        editable1: false,
        expanded1: true,
        disabled2: false,
        editable2: false,
        expanded2: true,
        disabled3: false,
        editable3: true,
        expanded3: true,
      })
    } else {
      setdialogStateSettings({
        disabled1: false,
        editable1: false,
        expanded1: true,
        disabled2: false,
        editable2: false,
        expanded2: true,
        disabled3: false,
        editable3: false,
        expanded3: true,
      })
    }

    Object.keys(temp).forEach(function (key) {
      if (temp[key] === null) {
        temp[key] = '';
      }
    })
    setRecordInfo(temp);
    await dispatch(getImagesById(temp.id)).then((res) => {
      setImages(res);
    }).catch((err) => {
      dispatch(showAlertSnackbar({ message: "Fetch Image Failed: " + err.message, type: 'error' }));
    })

    handleGetFile(e.row);

    setEditUniRecordOpen(true);
    setloading(false);
  }
  const handleGetFile = async (row) => {
    try {
      const res = await dispatch(getFileById(row.id));
      // Decode the Base64 string to binary data
      const binaryData = atob(res);

      // Create a Uint8Array from the binary data
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      // Create a Blob object with the correct MIME type
      const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      // Create a File object (optional, but useful if you want to set a file name)
      const file = new File([blob], "faulty_package_record.xlsx", { type: blob.type });

      setFile(file);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUploadImages = (e) => {
    if (e.target.files.length <= 5) {
      setNewImages([...e.target.files]);
    } else {
      dispatch(showAlertSnackbar({ type: "error", message: "Max 5 images allowed" }));
    }
  }
  function removeImage(index) {
    let temp = [...newImages];
    temp.splice(index, 1);
    setNewImages(temp);
  }

  async function handleDeleteRecord() {
    setEditUniRecordOpen(false);
    openDeleteConf(false);

   await dispatch(deleteRecord(recordInfo.id))
      .then((res) => {
        dispatch(showAlertSnackbar({ message: "Record Deleted", type: 'success' }));
      })
      .catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })

    refresh();

  }
  const handleDownload = () => {
    if (file) {
      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(file);
      downloadLink.href = url;
      downloadLink.download = 'faulty_package_record.xlsx';
      downloadLink.click();
      URL.revokeObjectURL(url);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecordInfo({
      ...recordInfo,
      [name]: value,
    });
  };

  function downloadAllRecords() {
    var filtered =records;
    filtered = filtered.map(({created_at, warehouse, tracking_number,sender_reference,item_number,issue_type,package_status,record_status, ...rest})=>({"Created At": created_at, "Warehouse":warehouseDropDown[warehouse]," Tracking Number":tracking_number, "Sender Reference": sender_reference,"Item Number":item_number, "Issue Type":FaultyPackageIssueType[issue_type],"Package Status":FaultyPackagePackageStatus[package_status],"Record Status":FaultyPackageRecordStatus[record_status]}));

    filtered.reverse();
    var workbook = XLSX.utils.book_new();
    var workSheet = XLSX.utils.json_to_sheet(filtered);
    workbook.SheetNames.push("Faulty_Package_Records");
    workbook.Sheets["Faulty_Package_Records"] = workSheet;
    XLSX.writeFile(workbook, "Faulty_Package_Records.xlsx");
  }


  async function updateRecord(updateStatus) {
    if (newImages.length > 0) {
      let formData = new FormData();
      newImages?.forEach(img => {
        formData.append('images[]', img)
      });

      await dispatch(saveImagesById(recordInfo.id, formData)).then((res) => {
        dispatch(showAlertSnackbar({ message: "Images Saved", type: 'success' }));
      }).catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })
    }
    let params = recordInfo;
    if (updateStatus) {
      params = { ...recordInfo, update_values_only: false };
    }
    await dispatch(updateRecordInformation(recordInfo.id, params)).then((res) => {
      dispatch(showAlertSnackbar({ message: "Record Updated", type: 'success' }));
    }).catch((err) => {
      dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
    })
    refresh();
  }

  async function closeRecord() {
    await dispatch(updateRecordInformation(recordInfo.id, { update_values_only: false })).then((res) => {
      dispatch(showAlertSnackbar({ message: "Record Closed", type: 'success' }));
    }).catch((err) => {
      dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
    })
    refresh();
  }

  function resetDialog() {
    startEdit(false);
    startConfirm(false);
    setImages([]);
    setNewImages([]);
  }

  function getPackageInfo() {
    if (recordInfo.tracking_number) {
      dispatch(getInfoByTrackingNumber(recordInfo.tracking_number)).then((res) => {
        setRecordInfo({
          ...recordInfo,
          package_status: res.state,
          warehouse: res.warehouse,
        });
      }).catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        setRecordInfo({
          ...recordInfo,
          tracking_number: "",
        });
      })
    }
  }


  return (
    <div className={classes.tableArea}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', color: "#577699", fontSize: 18, fontWeight: 600, }}>Faulty Package List</div>
        <div style = {{ paddingTop:"8px", paddingRight:"10px" }}>
          {
            stats && stats.length ?
            <div>
                <span className={classes.statistics} style={{ color:"red" }}>{stats.reduce((count, obj) => obj.record_status === CLIENT_REVIEWED? obj.count: count,0)+" Action Required"}</span>
                <span className={classes.statistics} >{"/ "+stats.reduce((count,obj) => obj.record_status === NEW_RECORD_STATUS? obj.count: count,0) +" Waiting for Client's Review"}</span>
                <span className={classes.statistics} >{"/ "+stats.reduce((count,obj) => obj.record_status === UNI_CONFIRMED? obj.count: count,0) +" UniUni Confirmed"}</span>
            </div>
            :
            <span></span>
          }
        </div>
        <Button className={classes.framedButton} onClick={downloadAllRecords} >DOWNLOAD ALL</Button>
      </div>
      <DataGrid
        className={classes.table}
        rows={records}
        columns={faultyPackageColumns}
        pageSize={pageSize}
        onRowClick={handleOpenRecord}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 30]}
        pagination
        getCellClassName={(params) => {
          if (params.field === 'record_status' && params.value === "Action Required") {
            return 'ActionRequired';
          }
        }}
      />
      {/* uni */}
      <Dialog fullWidth maxWidth='sm' classes={{ paper: classes.dialogPaper }} open={editUniRecordOpen} onClose={() => { setEditUniRecordOpen(false); resetDialog() }}>
        <DialogTitle>Faulty Package Record</DialogTitle>
        <DialogContent style={{ height: 800 }}>
          {/*step 1*/}
          <Accordion elevation={0} className={classes.accordionSection} disabled={dialogStateSettings.disabled1} expanded={dialogStateSettings.expanded1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setdialogStateSettings({ ...dialogStateSettings, expanded1: !dialogStateSettings.expanded1 })}
            >
              <Typography>Basic Information</Typography>
              {
                dialogStateSettings.editable1 &&
                <EditIcon style={{ fontSize: "medium", margin:"2px 0px 0px 5px"}} onClick={(e) => {
                  e.stopPropagation();
                  startEdit(true);
                }} />}
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                {/* row 1 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    label="Record Date *"
                    type="date"
                    className={classes.dialogTextBox}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="created_date"
                    value={recordInfo.created_at}
                    disabled
                    onChange={handleInputChange}
                  />
                  <TextField
                    select
                    name='warehouse'
                    label="Warehouse"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={recordInfo.warehouse}
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    onChange={handleInputChange}
                    required={(dialogStateSettings.editable1 && editing)}
                  >
                    {Object.entries(warehouseDropDown).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>

                </div>
                {/* row 2 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Tracking No."
                    name="tracking_number"
                    className={classes.dialogTextBox}
                    variant="outlined"
                    value={recordInfo.tracking_number}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton onClick={getPackageInfo} edge="end">
                            <SearchIcon className={classes.iconButton} />
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                </div>
                {/* row 3 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Sender Ref"
                    name="sender_reference"
                    className={classes.dialogTextBox}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.sender_reference}
                  />
                </div>
                {/* row 4 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Item No."
                    name="item_number"
                    className={classes.dialogTextBox}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.item_number}
                  />
                </div>
                {/* row 5 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    required={(dialogStateSettings.editable1 && editing)}
                    select
                    label="Issue Type"
                    name="issue_type"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange}
                    variant="outlined"
                    value={recordInfo.issue_type}
                  >
                    {Object.entries(FaultyPackageIssueType).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* row 6  images, need to make pretty*/}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch', flexWrap: 'wrap' }}>
                  {
                    (dialogStateSettings.editable1 && editing) &&
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch', marginLeft: '10px' }}>
                      <label className={classes.uploadImageButton} for="uploadImages">
                        UPLOAD IMAGE
                      </label>
                      <p style={{ color: " #1976d2", fontSize: "10px", paddingLeft: "10px" }}>*Accept only .png .jpeg. Max 5 images</p>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        hidden
                        onChange={handleUploadImages}
                        id="uploadImages"
                      />
                    </div>
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch', marginLeft: '10px' }}>
                  {
                    newImages.length > 0 ?
                      newImages.map((img, index) => (
                        <div className={classes.imageThumbnail} onClick={() => removeImage(index)}>
                          <ImageIcon fontSize='inherit' style={{ marginRight: '2px' }} />
                          {img.name}
                          <CancelIcon fontSize='inherit' style={{ marginLeft: '5px' }} />
                        </div>

                      ))
                      :
                      images?.map((image) => (
                        <img
                          src={`data:image/jpeg;base64,${image}`}
                          alt=''
                          style={{
                            width: 100,
                            height: 100,
                            zIndex: 1000,
                            borderRadius: 4,
                            cursor: 'pointer',
                            margin: '5px'
                          }}
                          onClick={() => setImageDetail(image)}
                        />
                      ))
                  }
                </div>
                {/* row 7 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    name="uni_notes"
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Notes"
                    multiline
                    minRows={4}
                    defaultValue="Add notes here"
                    className={classes.dialogTextBox}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.uni_notes}
                  />
                </div>
                {/* row 8 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <div className={classes.dialogTextBox}>{"Current Package Status: " + FaultyPackagePackageStatus[recordInfo.package_status]}</div>
                </div>

              </div>
            </AccordionDetails>
          </Accordion>

          {/*step 2; always disabled*/}
          <Accordion elevation={0} className={classes.accordionSection} disabled={dialogStateSettings.disabled2} expanded={dialogStateSettings.expanded2}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setdialogStateSettings({ ...dialogStateSettings, expanded2: !dialogStateSettings.expanded2 })}
            >
              <Typography>Client Review</Typography>
              {
                dialogStateSettings.editable2 &&
                <EditIcon style={{ fontSize: "medium", margin:"2px 0px 0px 5px"}} onClick={(e) => {
                  e.stopPropagation();
                  startEdit(true);
                }} />}
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                {/* row 1 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!(dialogStateSettings.editable2 && editing)}
                    label="Verify Tracking No."
                    name="verified_tracking_number"
                    className={classes.dialogTextBox}
                    variant="outlined"
                    value={recordInfo.verified_tracking_number}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                {/* row 2 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!(dialogStateSettings.editable2 && editing)}
                    select
                    label="Decision"
                    name="client_decision"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={recordInfo.client_decision}
                  >
                    {Object.entries(FaultyPackageClientDecision).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* row 3 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!(dialogStateSettings.editable2 && editing)}
                    label="Notes"
                    name="client_notes"
                    multiline
                    minRows={4}
                    className={classes.dialogTextBox}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.client_notes}
                  />
                </div>
                {/* row 4  file, need to make pretty*/}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  {file && file.size > 0 && (
                    <p
                      style={{ color: '#1976d2', fontSize: '10px', paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={handleDownload}
                    >
                      Download File
                    </p>
                  )}
                </div>

              </div>
            </AccordionDetails>
          </Accordion>

          {/*step 3*/}
          <Accordion elevation={0} className={classes.accordionSection} disabled={dialogStateSettings.disabled3} expanded={dialogStateSettings.expanded3}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setdialogStateSettings({ ...dialogStateSettings, expanded3: !dialogStateSettings.expanded3 })}
            >
              <Typography>Confirmation</Typography>
              {
                dialogStateSettings.editable3 &&
                <EditIcon style={{ fontSize: "medium", margin:"2px 0px 0px 5px"}} onClick={(e) => {
                  e.stopPropagation();
                  startEdit(true);
                }} />}
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                {/* row 1 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!((dialogStateSettings.editable3 && editing) || confirming)}
                    requried={((dialogStateSettings.editable3 && editing) || confirming)}
                    label="Close Date"
                    name="closed_at"
                    type="date"
                    className={classes.dialogTextBox}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.closed_at}
                    onChange={handleInputChange}
                  />
                </div>
                {/* row 2 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!((dialogStateSettings.editable3 && editing) || confirming)}
                    requried={((dialogStateSettings.editable3 && editing) || confirming)}
                    select
                    label="Action"
                    name="action_taken"
                    className={classes.dialogTextBox}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={recordInfo.action_taken}
                    onChange={handleInputChange}
                  >
                    {Object.entries(FaultyPackageActionTaken).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* row 3 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <TextField
                    disabled={!((dialogStateSettings.editable3 && editing) || confirming)}
                    label="Notes"
                    name="closing_notes"
                    multiline
                    minRows={4}
                    className={classes.dialogTextBox}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={recordInfo.closing_notes}
                    onChange={handleInputChange}
                  />
                </div>
                {/* row 4 */}
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
                  <div className={classes.dialogTextBox}>{"Current Package Status: " + FaultyPackagePackageStatus[recordInfo.package_status]}</div>
                </div>

              </div>
            </AccordionDetails>
          </Accordion>

        </DialogContent>
        <DialogActions style={{ marginBottom: 24, display: "flex", flex: 1 }}>
          {recordInfo.record_status === NEW_RECORD_STATUS && <Button className={classes.deleteButton} style={{ marginLeft: 30, justifyContent: 'flex-start' }} variant='contained' onClick={() => {openDeleteConf(true) }}>DELETE RECORD</Button>}
          {(recordInfo.record_status === UNI_CONFIRMED && !editing) && <Button className={classes.buttonSystem} style={{ marginLeft: 30, justifyContent: 'flex-start' }} variant='contained' onClick={() => { setEditUniRecordOpen(false); closeRecord() }}>CLOSE RECORD</Button>}
          {
            !editing &&
            <Button autoFocus variant='contained'className={classes.buttonText} style={{justifyContent: 'flex-end'}} onClick={() => { setEditUniRecordOpen(false); resetDialog() }}>CLOSE</Button>
          }

          {(recordInfo.record_status === CLIENT_REVIEWED && !confirming) && <Button className={classes.buttonSystem} style={{ marginRight: 24, justifyContent: 'flex-start' }} variant='contained' onClick={() => { startConfirm(true); startEdit(true); setdialogStateSettings({ ...dialogStateSettings, expanded3: true })}}>START TO CONFIRM</Button>}

          {confirming ?
            <div style={{ display: "flex", justifyContent: 'flex-end', flex: 1, alignItems: "right" }}>
              <Button autoFocus variant='contained' className={classes.buttonText} style={{ marginRight: 24}} onClick={() => { setEditUniRecordOpen(false); resetDialog() }}>CANCEL</Button>

              <Button variant='contained' className={classes.buttonSystem} style={{ marginRight: 24 }} onClick={() => { setEditUniRecordOpen(false); resetDialog(); updateRecord(true) }}>CONFIRM</Button>
            </div>
            :
            editing &&
            <div style={{ display: "flex", justifyContent: 'flex-end', flex: 1, alignItems: "right" }}>
              <Button autoFocus variant='contained' className={classes.buttonText} style={{ marginRight: 24 }} onClick={() => { setEditUniRecordOpen(false); resetDialog() }}>CANCEL</Button>

              <Button variant='contained' className={classes.buttonSystem} style={{ marginRight: 24 }} onClick={() => { setEditUniRecordOpen(false); resetDialog(); updateRecord() }}>SAVE CHANGES</Button>
            </div>
          }
        </DialogActions>
      </Dialog>

      {/* image detail pop up */}
      <Dialog maxWidth='md' fullWidth={true} open={imageDetail !== null} onClose={() => setImageDetail(null)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <img
            src={`data:image/jpeg;base64,${imageDetail}`}
            alt=''
            style={{
              zIndex: 1000,
              borderRadius: 4,
              cursor: 'pointer',
              margin: '5px'
            }}
          />
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button  variant='contained' className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setImageDetail(null)}>OK</Button>
        </DialogActions>
      </Dialog>

      {/* delete conf pop up */}
      <Dialog maxWidth='sm' fullWidth={true} open={deletConf} onClose={() => openDeleteConf(false)}>
        <DialogTitle>Delete Record</DialogTitle>
        <DialogContent>
          <br />
          <Typography>
            {"You are going to delete this record. Do you want to proceed?"}
          </Typography>
          <br />
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button variant='contained' className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => openDeleteConf(false)}>Cancel</Button>
          <Button  variant='contained' className={classes.buttonText} style={{ marginRight: 24, color: "#D32F2F" }} autoFocus onClick={handleDeleteRecord}>DELETE</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}