import React, {useState} from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import PrmFrame from "../../components/PrmFrame";
import FullscreenLoading from "../../components/FullscreenLoading";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";
import InvoiceNumberGenerator from "../../components/InvoiceNumberGenerator";
import BillingManagement from "../../components/BillingManagement/BillingManagement";
import InvoiceManagement from '../../components/InvoiceManagement';
import BillingSupplementary from "../../components/BillingManagement/BillingSupplementary";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const BILLING_ACCESS_ID = 13;

function BillingAndInvoice() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={BILLING_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
      <PrmMain
        open={open}
        className={classes.content}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <BillingManagement setLoading = {setLoading}/>
        </div>
        <div hidden={menuIndex!==1}>
          <BillingSupplementary setLoading = {setLoading}/>
        </div>
        <div hidden={menuIndex!==2}>
          <InvoiceManagement setLoading = {setLoading}/>
        </div>
        <div hidden={menuIndex!==3}>
          <InvoiceNumberGenerator setLoading = {setLoading}/>
        </div>
        <FullscreenLoading open={loading}/>
      </PrmMain>
    </div>
  );
}

export default BillingAndInvoice;