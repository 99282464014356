import React, {useState} from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import PrmFrame from "../../components/PrmFrame";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";
import BlockedAddress from "../../components/AddressManagement/BlockedAddress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const ADDRESS_MANAGEMENT_ACCESS_ID = 14;

function AddressManagement() {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={ADDRESS_MANAGEMENT_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
      <PrmMain
        open={open}
        className={classes.content}
      >
        <Toolbar />
        {/*<div hidden={menuIndex!==0}>*/}
        {/*  <Partner partnerOpen={menuIndex===0}/>*/}
        {/*</div>*/}
        <div hidden={menuIndex!==0}>
          <BlockedAddress />
        </div>
        {/* <FullscreenLoading open={loading}/> */}
      </PrmMain>
    </div>
  );
}

export default AddressManagement;