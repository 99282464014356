import { Button, DialogTitle, DialogActions, IconButton, makeStyles, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import { ClearRounded } from '@material-ui/icons';
import { downloadInvoiceFiles } from '../../../actions/billingManangementActions';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { DataGrid } from '@material-ui/data-grid';
import FullscreenLoading from "../../FullscreenLoading";

const useStyle = makeStyles(() => ({
  fillButton: {
    width: "200",
    display: "flex",
    margin: "5px 0px 40px",
    backgroundColor: "#1976D2",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 600,
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }

  },
  buttonText: {
    color: "#1976d2",
    width: 100,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'

    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  dialogTitle: {
    fontSize: '18px',
    marginBottom: '15px',
    paddingBottom: 0

  },
  dialogContent: {
    paddingRight: '24px',
    paddingLeft: '24PX',
    marginTop: '10px'
  },
  hr: {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  button: {
    fontSize: '14px',
    color: '#2196F3'
  },
  redText: {
    color: '#D32F2F',
  },
  invoiceList: {
    background: "#ffffff",
    borderRadius: "4px",
    margin: "0px 25px 25px 24px",
  },
  table: {
    width: '100%',
    height: 420,
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      paddingLeft: '20px',
    },
    '& .MuiDataGrid-columnHeader': {
      paddingLeft: '20px', // Adjust this value as needed
    }
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '27px',
    letterSpacing: '0.4px',
    paddingRight: '24px',
    paddingLeft: '24PX',
    marginBottom: '20px'
  },
  infoTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#616161',
  },
  infoValue: {
    fontSize: '14px'
  },
}));

function GridItem({ title, value }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  function getAction(fileType, filename, creditNum) {
    if (fileType === 'invoice')
      return downloadInvoiceFiles({ file_name: filename, cr_num: creditNum });

  }

  async function handleDownload(filename, creditNum, fileType) {
    try {
      setLoading(true);
      const action = getAction(fileType, filename, creditNum);
      await dispatch(action);
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container item xs={12} spacing={1} style={{ marginBottom: '14px' }}>
      <Grid item xs={5} className={classes.infoTitle}>{title}</Grid>
      <Grid item xs={7} >
        {typeof value === 'string' ? (
          <span className={classes.infoValue}>{value}</span>
        ) : (
          value.text ? (
            <div>
              <Button
                size="small"
                className={classes.button}
                endIcon={<SaveAltIcon />}
                style={{
                  textAlign: 'center',
                  fontSize: '12px',
                  padding: 0,
                  textTransform: 'none',
                }}
                onClick={() => handleDownload(value.text, value.creditNum, value.type)}
                disabled={loading}
              >
                <span className={classes.infoValue}>{value.text}</span>
              </Button>
            </div>
          ) : null // Render nothing when value.text is null
        )}
      </Grid>
    </Grid>
  );
}

const creditMemoDetailColumns = [
  {
    field: 'description',
    headerName: "Category",
    width: 180,
    sortable: false,
  },
  {
    field: 'ant_parcel_no',
    headerName: "Tracking No.",
    width: 280,
    sortable: false,
  },
  {
    field: 'fee_adjustment',
    headerName: "Adjusted Amount",
    width: 180,
    sortable: false,
  },
  {
    field: 'remark',
    headerName: "Comments",
    width:300,
    sortable: false,
  }
]


function CreditMemoDetail(props) {
  const {
    setDialogOpen, data
  } = props;
  const dispatch = useDispatch();
  const classes = useStyle();

  const [loading, setLoading] = useState(false);

  let creditMemoInfoL = [
    { title: 'Invoice Number:', value: data['creditMemoInfo']['cr_num'] ?? '' },
    { title: 'Invoice Creation Date:', value: data['creditMemoInfo']['create_at'] ?? '' },
    { title: 'Operator:', value: data['creditMemoInfo']['operator_name'] ?? '' },
  ];
  let creditMemoInfoR = [
    { title: 'Master Invoice Number:', value: data['creditMemoInfo']['invoice_num'] ?? '' },
    { title: 'Amount:', value: "$ " + data['creditMemoInfo']['total_adjusted_amount'] ?? '' },
    { title: 'Credit Memo:', value: { text: data['creditMemoInfo']['cr_invoice_file'], type: 'invoice', creditNum: data['creditMemoInfo']['cr_num'] } },

  ];

  async function downloadDetails(filename, creditNum) {
    try {
      setLoading(true);
      await dispatch(downloadInvoiceFiles({ file_name: filename, cr_num: creditNum }))
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ position: 'relative', fontWeight: 600, }}>
          <span>Credit Memo</span>
          <IconButton
            className={classes.button}
            style={{ position: 'absolute', top: 0, right: 0, bottom: 5 }}
            onClick={() => setDialogOpen(false)}>
            <ClearRounded />
          </IconButton>
        </div>

      </DialogTitle>
      <hr className={classes.hr} />
      <div>
        <span className={classes.subtitle}>Credit Memo Information</span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid container spacing={0} className={classes.dialogContent}>
            {creditMemoInfoL.map((item, index) => (
              <GridItem key={index} title={item.title} value={item.value} />
            ))}
          </Grid>
          <Grid container spacing={0} className={classes.dialogContent}>
            {creditMemoInfoR.map((item, index) => (
              <GridItem key={index} title={item.title} value={item.value} />
            ))}
          </Grid>
        </div>
      </div>
      <div>
        <span className={classes.subtitle}>Credit Memo Details</span>
        <div style={{ paddingLeft:'30px', paddingBottom:'10px' }}>
          {data['creditMemoInfo']['cr_detail_file'] &&
            <Button
              size="small"
              className={classes.button}
              endIcon={<SaveAltIcon />}
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: 0,
                textTransform: 'none',
              }}

              onClick={() => downloadDetails(data['creditMemoInfo']['cr_detail_file'],data['creditMemoInfo']['cr_num']) }
            >
              <span className={classes.infoValue}>{data['creditMemoInfo']['cr_detail_file']}</span>
            </Button>}
        </div>
        <div className={classes.invoiceList}>
          <DataGrid
            className={classes.table}
            rows={data['details'] ?? []}
            columns={creditMemoDetailColumns}
            checkboxSelection={false}
            getRowId={(row) => row.id}
            disableColumnMenu={true}
          />
        </div>
      </div>
      <hr className={classes.hr} />
      <DialogActions style={{ marginBottom: '10px', marginRight: '30px' }}>
        <Button className={classes.button} style={{ fontWeight: '00' }} autoFocus onClick={() => { setDialogOpen(false) }}>
          CLOSE
        </Button>
      </DialogActions>
      <FullscreenLoading open={loading} />
    </div>
  );
}

export default CreditMemoDetail;