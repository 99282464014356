import React from 'react';
import PrmAppBar from "../PrmAppBar";
import PrmDrawer from "../PrmDrawer";

function PrmFrame(props) {
  const {
    ACCESS_ID,
    open, setOpen,
    menuIndex, setMenuIndex
  } = props;

  return (
    <>
      <PrmAppBar
        open={open}
        setOpen={setOpen}
      />
      <PrmDrawer
        ACCESS_ID={ACCESS_ID}
        open={open}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
    </>
  );
}

export default PrmFrame;
