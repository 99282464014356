import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UniDialog from '../UniDialog';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ServicePointPackagesStatistics(props) {
  const classes = useStyles();
  const {t} = useTranslation('servicePointStatistics')

  const servicePointPackages = useSelector(state => state.servicePoint.getDriverDropOffHistory);

  const [packageDetailOpen, setPackageDetailOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [servicePointStatistics, setServicePointStatistics] = useState([]);

  const statusList = useMemo(() => {
    return {
      "0": {
        name: t('drop_off'),
        id: 'drop_off'
      },
      "1": {
        name: t('self_pick_up'),
        id: 'self_pick_up'
      },
      "6": {
        name: t('service_point_in_storage'),
        id: 'service_point_in_storage'
      },
      "9": {
        name: t('back_to_warehouse_storage'),
        id: 'back_to_warehouse_storage'
      }
    }
  }, [t])

  const columns = [
    {field: 'id', headerName: t('order_id'), hide: true},
    {field: 'submit_time', headerName: t('submit_time'), width: 170, sortable: true},
    { field: 'driver_id', headerName: t('driver_id'), width: 150, sortable: true},
    {field: 'tno', headerName: t('TNO'), width: 200, sortable: false},
  ]
  const servicePointColumns = [
    {field: 'name', headerName: t('id'), width: 200},
    {
      field: 'drop_off', 
      headerName: t('drop_off'), 
      width: 200, 
      renderCell: (params) => {
        return (
          <div
            style={{cursor: 'pointer'}}
            onClick={(e) => {
                e.stopPropagation();
                setOrders(params.row.drop_off_orders);
                setTimeout(() => {
                  setPackageDetailOpen(true);
                }, 500);
            }}
        >
            {params.value || 0}
          </div>
        );
      }
    },
    {
      field: 'self_pick_up', 
      headerName: t('self_pick_up'), 
      width: 200, 
      renderCell: (params) => {
        return (
          <div
            style={{cursor: 'pointer'}}
            onClick={(e) => {
                e.stopPropagation();
                if(params.value) {
                  setOrders(params.row.self_pick_up_orders);
                  setTimeout(() => {
                    setPackageDetailOpen(true);
                  }, 500);
                }
                
            }}
        >
            {params.value || 0}
          </div>
        );
      }
    },
    {
      field: 'service_point_in_storage', 
      headerName: t('service_point_in_storage'), 
      width: 300, 
      renderCell: (params) => {
        return (
          <div
            style={{cursor: 'pointer'}}
            onClick={(e) => {
                e.stopPropagation();
                if(params.value) {
                  setOrders(params.service_point_in_storage_orders);
                  setTimeout(() => {
                    setPackageDetailOpen(true);
                  }, 500);
                }
                
            }}
        >
            {params.value || 0}
          </div>
        );
      }
    },
    {
      field: 'back_to_warehouse_storage', 
      headerName: t('back_to_warehouse_storage'), 
      width: 300, 
      renderCell: (params) => {
        return (
          <div
            style={{cursor: 'pointer'}}
            onClick={(e) => {
              e.stopPropagation();
              if(params.value) {
                setOrders(params.back_to_warehouse_storage_orders);
                setTimeout(() => {
                  setPackageDetailOpen(true);
                }, 500);
              }
            }}
        >
            {params.value || 0}
          </div>
        );
      }
    }
  ]

  useEffect(() => {
    if(servicePointPackages) {
      if(servicePointPackages.length > 0) {
        let refactorArr = [];
        let exist = false;
        servicePointPackages.forEach((servicePoint) => {
          let servicePointCurr = {};
          refactorArr.forEach((arr) => {
            if(arr.name === servicePoint.service_point_name) {
              servicePointCurr = arr;
              exist = true;
              return;
            }
          })
          let status = statusList[servicePoint.status + ''];
          servicePointCurr[status.id] = servicePoint.orders.length;
          servicePointCurr[`${status.id}_orders`] = servicePoint.orders;
          servicePointCurr.id = servicePoint.service_point_id;
          servicePointCurr.name = servicePoint.service_point_name;
          if(!exist) {
            refactorArr.push(servicePointCurr);
          }
        })
        setServicePointStatistics(refactorArr);
      } else {
        setServicePointStatistics([]);
      }
    } else {
      setServicePointStatistics([]);
    }
  }, [servicePointPackages, statusList])

  function createRows() {
    if(orders.length > 0) {
      return orders.map((row) => {
        row.hideId = row.submit_time + '' + row.order_id
        const {hideId, ...rest} = row
        return {id: hideId, ...rest}
      })
    }
  }
  function createServicePointRows() {
    if(servicePointStatistics.length > 0) {
      return servicePointStatistics.map((row) => {
          const {id, ...rest} = row
          return {id: id, ...rest}
      })
    }
  }

  return (
    <div className={classes.root}>
      <UniDialog
        title={t('order_details')}
        open={packageDetailOpen}
        onClose={() => {
          setPackageDetailOpen(false);
          setOrders([]);
        }}
        buttons={[]}
        disableDefaultButtons={true}
        fullWidth
      >
          <DataGrid 
            rows={createRows() || []}
            columns={columns}
            pageSize={20}
            isRowSelectable={false}
            style={{height: 600}}
          />
      </UniDialog>
      <Paper className={classes.paper}>
        <DataGrid 
          rows={createServicePointRows() || []}
          columns={servicePointColumns}
          pageSize={20}
          isRowSelectable={false}
          style={{height: 600}}
          loading={props.isLoading}
        />
      </Paper>
    </div>
  );
}
