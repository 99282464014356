import React, { useState } from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import FullscreenLoading from "../../components/FullscreenLoading";
import PrmFrame from "../../components/PrmFrame";
import PrealertReport from "../../components/PrealertReport";
import PrealertSearchKeyword from '../../components/PrealertSearchKeyword';
import PrealertWrongAreaKeyword from "../../components/PrealertWrongAreaKeyword";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";
import PrealertPartnerVolume from "../../components/PrealertPartnerVolume";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const PREALERT_ACCESS_ID = 6;

function PrealertStatistic(){
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);

  // const [keyword, setKeyword] = useState();
  const [failedData, setFailedData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleKeyword(word) {
    // setKeyword(word);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={PREALERT_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />

      <PrmMain
        className={classes.content}
        open={open}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <PrealertReport setLoading={setLoading}/>
        </div>
        <div hidden={menuIndex!==1}>
          <PrealertSearchKeyword failedData={failedData} setFailedData={setFailedData} setLoading={setLoading} handleKeyword={handleKeyword}/>
        </div>
        <div hidden={menuIndex!==2}>
          <PrealertWrongAreaKeyword areaData={areaData} setData={setAreaData} setLoading={setLoading} handleKeyword={handleKeyword}/>
        </div>
        <div hidden={menuIndex!==3}>
          <PrealertPartnerVolume setLoading={setLoading} volumeOpen={menuIndex===3}/>
        </div>
        <FullscreenLoading open={loading}/>
      </PrmMain>
    </div>
  );
}

export default PrealertStatistic