import { useDispatch } from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, makeStyles, TextField} from '@material-ui/core';
import React, {useEffect, useState, useCallback} from 'react';
import {getPartners190Stats, getPartners190Warehouse} from "../../actions/prealertStatisticActions";
import {showAlertSnackbar} from "../../reducers/uiSlice";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";

const useStyle = makeStyles((theme) => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 5px 16px",
    display: 'flex'
  },
  textField: {
    width: 220
  },
  button: {
    backgroundColor: "#1976d2",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    marginLeft: theme.spacing(2),
    maxHeight: "36px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  tableArea: {
    background: "#ffffff",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 700,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 10.5,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.25,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: 'rgba(85, 166, 255, 0.2)',
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .column_style': {
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontWeight: 550,
    },
  },
}));

const processData = (res, dates) => {
  const result = [];

  const partnerData = {};

  res.forEach(entry => {
    const { partner_id, partner_name, date, count } = entry;
    if (!partnerData[partner_id]) {
      partnerData[partner_id] = {
        partner_id,
        partner_name,
      };
    }
    partnerData[partner_id][date] = count;
  });

  for (const partnerId in partnerData) {
    const partnerInfo = partnerData[partnerId];
    const partnerEntry = { ...partnerInfo };

    dates.forEach(date => {
      if (partnerInfo[date]) {
        partnerEntry[date] = partnerInfo[date];
      } else {
        partnerEntry[date] = 0;
      }
    });

    result.push(partnerEntry);
  }

  return result;
};

const initialDateFrom = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
const initialDateTo = dayjs().format('YYYY-MM-DD');

function PrealertPartnerVolume(props) {
  const {
    setLoading,
    volumeOpen
  } = props;

  const classes = useStyle();
  const dispatch = useDispatch();
  const [partner, setPartner] = useState('');
  const [date, setDate] = useState('');
  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [detailRow, setDetailRow] = useState([]);
  const [openView, setOpenView] = useState(false);

  const detailCol = [
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex: 1
    },
    {
      field: 'count',
      headerName: 'Order Volume',
      flex: 1
    }
  ];

  const handleUpdateCol = (dates) => {
    const newColumns = [
      {
        field: 'partner_name',
        headerName: 'Partner',
        minWidth: 280,
        cellClassName: 'column_style',
        flex: 1
      },
      ...dates.map(date => ({
        field: date,
        headerName: date,
        minWidth: 200,
        flex: 1
      })),
    ];

    setColumns(newColumns);
  };

  const handleUpdateRow = (res, dates) => {
    const updatedRows = processData(res, dates);
    setRows(updatedRows);
  };

  const handleUpdateDetailRow = (res) => {
    const updatedRows = res.map((row) => ({
      id: row.warehouse_id,
      warehouse: row.warehouse_name,
      count: row.count.toLocaleString(),
    }));
    setDetailRow(updatedRows);
  };

  const handleSearch = useCallback(async (dateFrom, dateTo) => {
    setLoading(true);
    try {
      const res = await dispatch(getPartners190Stats(dateFrom, dateTo)).catch(e => {
        throw e.message;
      })

      const dates = [];
      let currentDate = dayjs(dateFrom);

      while (currentDate.isBefore(dateTo) || currentDate.isSame(dateTo)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }
      
      handleUpdateCol(dates);
      handleUpdateRow(res, dates);

      dispatch(showAlertSnackbar({message: 'Fetch Data Success', type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: e, type: 'error'}));
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading])

  const handleCellClick = async (params) => {
    if (params.field !== 'partner_name') {
      setLoading(true);
      try {
        const { id, field: date, row } = params;

        setPartner(row.partner_name);
        setDate(date);

        const res = await dispatch(getPartners190Warehouse(id, date));

        handleUpdateDetailRow(res);
        setOpenView(true);

        dispatch(showAlertSnackbar({ message: 'Fetch Data Success', type: 'success' }));
      } catch (e) {
        dispatch(showAlertSnackbar({ message: e, type: 'error' }));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if(!openView) {
      setDetailRow([]);
      setPartner('');
      setDate('');
    }
  }, [openView])

  useEffect(() => {
    if (volumeOpen) {
      handleSearch(initialDateFrom, initialDateTo);
    }
  }, [volumeOpen, handleSearch])

  return (
    <div>
      <div className={classes.breadCrumbs}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Partner Incoming Volume (Vancouver time)</p>
      </div>

      <div className={classes.searchBar}>
        <TextField
          className={classes.textField}
          style={{marginLeft: 16}}
          id="prealert_startdate_text"
          type="date"
          variant="outlined"
          value={dateFrom}
          onChange={(e)=>setDateFrom(e.target.value)}
        />

        <TextField
          className={classes.textField}
          style={{marginLeft: 36}}
          id="prealert_enddate_text"
          type="date"
          variant="outlined"
          value={dateTo}
          helperText="Select within 7 days"
          onChange={(e)=>setDateTo(e.target.value)}
        />

        <IconButton
          sx={{height:"50px",width:"50px"}}
          style={{marginLeft: 36}}
          size={'large'}
          color={'primary'}
          onClick={() => handleSearch(dateFrom, dateTo)}
          id={'prealert_search_button'}
        >
          <SearchIcon fontSize={'inherit'}/>
        </IconButton>
      </div>

      <div className={classes.tableArea}>
        <DataGrid
          autoHeight
          pagination
          pageSize={10}
          className={classes.table}
          rows={rows}
          getRowId={(row) => row.partner_id}
          columns={columns}
          // components={{
          //   Footer: () => null,
          // }}
          onCellClick={handleCellClick}
        />
      </div>

      <Dialog maxWidth='xl' open={openView} onClose={() => setOpenView(false)}>
        <DialogContent style={{ width: 530 }}>

          <div style={{color: "#707070", fontSize: '16px', marginTop: '5px'}}>Incoming Volume Details</div>
          <div style={{color: "#577699", fontWeight: 600, fontSize: '24px', marginTop: '5px'}}>{partner}</div>
          <div style={{color: "#000000DE", fontSize: '16px', marginTop: '5px'}}>{date}</div>
          
          <DataGrid
              autoHeight
              className={classes.table}
              style={{marginTop: '42px'}}
              rows={detailRow}
              columns={detailCol}
              components={{
                Footer: () => null,
              }}
          />
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button
              id={'close-dialog'}
              autoFocus
              className={classes.button}
              style={{ marginRight: 24 }}
              onClick={() => setOpenView(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PrealertPartnerVolume;