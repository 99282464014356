import { Button, IconButton, TextField, Typography, makeStyles, } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {InputAdornment, Link} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid } from '@mui/x-data-grid';
import { showAlertSnackbar } from '../../reducers/uiSlice';
import { addMissingGVOrders } from '../../actions/gvOrderManagement';

const useStyle = makeStyles(() => ({
  header:{
    fontWeight: 800,
    fontSize : 16,
    margin: "10px 5px 20px"
  },
  trackingNo: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 25px 0px",
    padding: "25px 25px 25px",
    width: "27%",
    display: "block",
    justifyContent: "center",
  },
  result: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 0px 25px",
    padding: "25px 25px 25px",
    width: "70%",
    display: "block",
    justifyContent: "center",
  },
  resultTable: {
    '& .MuiDataGrid-columnHeaders': {
      height: 56,
      backgroundColor: '#55A6FF1A',
      fontSize: 13,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      color: '#00000066'
    },
    '& .MuiDataGrid-columnHeaderTitle':{
      fontWeight: "600"
    },
    '& .MuiDataGrid-row': {
      height: 56,
      color: "#000000DE",

    },
    '& .failed': {
      backgroundColor: '#D32F2F1A',
    },
    '& .failed-cell': {
      color: '#D32F2FDE',
    },
    '& .success-cell': {
      color: '#2E7D32DE',
    },
  },
  tableFooter :{
    color: '#00000066',
    margin: "10px 20px 20px",
    fontSize: 12
  },
  noRowsPrompt : {
    color: '#00000066',
    textAlign : 'center',
    margin: "33px"
  },
  textBox :{
    width : "100%",
    margin: "5px 0px 40px",
  },
  fillButton :{
    width : "200",
    margin: 50,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#1976D2",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 600,
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }

  }

}));

function OrderReplenishment() {
  const dispatch = useDispatch();
  const classes = useStyle();
  const { t } = useTranslation('orderReplenishment');

  const [trackingNos, setTrackingNos] = useState("");
  const [mawb, setmawb] = useState("");
  const [loading, setLoading] = useState(false);
  const [addOrderResult, setAddOrderResult] = useState([]);

  const ordersAddedColumns = [
    {
      field: 'tno',
      headerName: "Tracking No.",
      width: 240,
    },
    {
      field: 'mawb',
      headerName: "Batch No.",
      width: 200,
    },
    {
      field: 'port',
      headerName: "Port",
      width: 120,
    },
    {
      field: 'status',
      headerName: "Status",
      width: 120,
    },
    {
      field: 'failed_reason',
      headerName: "Failed Reason",
      width: 320,
    },
  ]

  const orderReplenishmentFooter = () => {

    return (
      addOrderResult.length > 0 ?
        <div className={classes.tableFooter}>
          <span display="inline">{t('orders_added_prompt_part1')}</span>
          <Link href = "#" display="inline">{t('orders_added_failed_import')}</Link>
          <span display="inline">{t('orders_added_prompt_part2')}</span>
        </div>
        :
        <div></div>
    );
  }

  const noOrdersAddedPrompt = () => {
    return (
      <div className={classes.noRowsPrompt}>
        <Typography>{t('no_orders_added_prompt')}</Typography>
      </div>
    );
  }

  async function addMissingOrders() {
    setLoading(true);
    
    //validate number of tno is less than 10;
    let tempArr = [];
    if (trackingNos.includes(',')) {
      tempArr = trackingNos.trim().split(',').map(i => i.trim());
    } else {
      tempArr = trackingNos.trim().split(/\s+/).map(i => i.trim());
    }
    if (!trackingNos || tempArr.length === 0) {
      dispatch(showAlertSnackbar({ message: 'Please provide a tracking number', type: 'error' }));
    } else if (tempArr.length > 10) {
      dispatch(showAlertSnackbar({ message: 'More than 10 tracking numbers provided', type: 'error' }));
    } else {
      await dispatch(addMissingGVOrders({ tno: tempArr.join(), mawb: mawb }))
        .then((res) => {
          setAddOrderResult(res);
        })
        .catch((err) => {
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        })
    }

    setLoading(false);
  }


  return (
    <div>
      <div className={classes.breadCrumbs}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>{t('order_replenishment')}</p>
      </div>

      <div style={{ display: "flex" }}>
        <div className={classes.trackingNo}>
          <Typography className={classes.header}>{t('enter_tracking_header')}</Typography>
          <TextField
            multiline
            required
            minRows={10}
            placeholder={t('enter_tracking_prompt')}
            className={classes.textBox}
            label={t('enter_tracking_textbox')}
            value={trackingNos}
            onChange={(e) => setTrackingNos(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={classes.textBox}
            label={mawb ? t('batch_no') : t('enter_batch_textbox')}
            value={mawb}
            onChange={(e) => setmawb(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton onClick={() => setmawb("")}>
                    {
                      mawb && <ClearIcon className={classes.iconButton} />
                    }
                  </IconButton>
                </InputAdornment>
            }}
          />
          <Button className={classes.fillButton} variant="contained" onClick={() => addMissingOrders()}>
            {t('add_order_btn')}
          </Button>
        </div>
        <div className={classes.result}>
          <Typography className={classes.header}>{t('orders_add_header')}</Typography>
          <div>
          <DataGrid
            style={{ minHeight: 630, border:"none"}}
            loading={loading}
            className={classes.resultTable}
            rows={addOrderResult}
            getRowId={(row) => row.tno}
            columns={ordersAddedColumns}
            getRowClassName={(params) => params.row.status === "FAILED" ? 'failed' : ''}
            getCellClassName={(params) => {
              if (params.field === 'status') {
                if (params.value === "FAILED") {
                  return 'failed-cell';
                }
                return 'success-cell'
              }
            }}
            components={{
              Footer: orderReplenishmentFooter,
              NoRowsOverlay: noOrdersAddedPrompt
            }}
            componentsProps={{
              Footer: { addOrderResult }
            }}
          />
          </div>
        </div>
      </div>
    </div>

  );
}

export default OrderReplenishment;