import { shangjiaServerInstance, UniDWServerInstance } from "../axios/instances";
import { BILLING_MANAGEMENT as types } from "./types"
import axios from "axios";
import { getUserPreference } from "../utilities/common";

export function generateInvoiceNumber(params) {
  return function (dispatch) {
    dispatch({ type: types.GENERATE_INVOICE_NUMBER })
    return shangjiaServerInstance.post("/invoice", { ...params })
      .then(({ data }) => {
        dispatch({
          type: types.GENERATE_INVOICE_NUMBER_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.GENERATE_INVOICE_NUMBER_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function searchSettlementProcedures(params) {
  return function (dispatch) {
    dispatch({ type: types.SEARCH_SETTLEMENT_PROCEDURES })
    return UniDWServerInstance.get("/settlement/settlementprocedure", { params: params })
      .then(({ data }) => {
        dispatch({
          type: types.SEARCH_SETTLEMENT_PROCEDURES_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.SEARCH_SETTLEMENT_PROCEDURES_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getSettlementCustomers() {
  return function (dispatch) {
    dispatch({ type: types.GET_SETTLEMENT_CUSTOMERS })
    return UniDWServerInstance.get("/settlement/getallcustomer")
      .then(({ data }) => {
        dispatch({
          type: types.GET_SETTLEMENT_CUSTOMERS_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.GET_SETTLEMENT_CUSTOMERS_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getSettlementStatus() {
  return function (dispatch) {
    dispatch({ type: types.GET_SETTLEMENT_STATUS })
    return UniDWServerInstance.get("/settlement/status")
      .then(({ data }) => {
        dispatch({
          type: types.GET_SETTLEMENT_STATUS_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.GET_SETTLEMENT_STATUS_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function downloadPreauditFile(params) {
  return function (dispatch) {
    dispatch({ type: types.DOWNLOAD_PREAUDIT_FILE });

    return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/downloadpreauditfile`, { ...params }, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const contentType = response.headers['content-type'];

        if (contentType !== 'application/json') {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', params.file_name);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(fileUrl);
          document.body.removeChild(link);

          dispatch({
            type: types.DOWNLOAD_PREAUDIT_FILE_SUCCESS,
            payload: fileUrl,
          });

          return Promise.resolve(fileUrl);

        } else {
          throw new Error('File not found or failed downloading');
        }
      })
      .catch(error => {
        dispatch({
          type: types.DOWNLOAD_PREAUDIT_FILE_FAIL,
          payload: error,
        });

        return Promise.reject(error);
      });
  };
}


export function downloadPriceCardFile(params) {
  return function (dispatch) {
    dispatch({ type: types.DOWNLOAD_PRICE_CARD_FILE });

    return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/downloadpricecard`, { file_name: params.file_name }, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const contentType = response.headers['content-type'];

        if (contentType !== 'application/json') {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', params.file_name);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(fileUrl);
          document.body.removeChild(link);

          dispatch({
            type: types.DOWNLOAD_PRICE_CARD_FILE_SUCCESS,
            payload: fileUrl,
          });

          return Promise.resolve(fileUrl);

        } else {
          throw new Error('File not found or failed downloading');
        }
      })
      .catch(error => {
        dispatch({
          type: types.DOWNLOAD_PRICE_CARD_FILE_FAIL,
          payload: error,
        });

        return Promise.reject(error);
      });
  };
}


export function downloadAdjustFile(params) {
  return function (dispatch) {
    dispatch({ type: types.DOWNLOAD_ADJUST_FILE });

    return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/downloadadjustfile`, { billing_num: params.billing_num }, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const contentType = response.headers['content-type'];

        if (contentType !== 'application/json') {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', params.file_name);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(fileUrl);
          document.body.removeChild(link);

          dispatch({
            type: types.DOWNLOAD_ADJUST_FILE_SUCCESS,
            payload: fileUrl,
          });

          return Promise.resolve(fileUrl);

        } else {
          throw new Error('File not found or failed downloading');
        }
      })
      .catch(error => {
        dispatch({
          type: types.DOWNLOAD_ADJUST_FILE_FAIL,
          payload: error,
        });

        return Promise.reject(error);
      });
  };
}

export function deleteAdjustFile(params) {
  return function (dispatch) {
    dispatch({ type: types.DELETE_ADJUST_FILE })
    return UniDWServerInstance.post("/settlement/deleteadjustfile", { ...params })
      .then(({ data }) => {
        dispatch({
          type: types.DELETE_ADJUST_FILE_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.DELETE_ADJUST_FILE_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function downloadAdjustTemplate(params) {
  return function (dispatch) {
    dispatch({ type: types.DOWNLOAD_ADJUST_TEMPLATE });

    return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/downloadtemplate`, { params }, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const contentType = response.headers['content-type'];

        if (contentType !== 'application/json') {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', "template.xlsx");
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(fileUrl);
          document.body.removeChild(link);

          dispatch({
            type: types.DOWNLOAD_ADJUST_TEMPLATE_SUCCESS,
            payload: fileUrl,
          });

          return Promise.resolve(fileUrl);

        } else {
          throw new Error('File not found or failed downloading');
        }
      })
      .catch(error => {
        dispatch({
          type: types.DOWNLOAD_ADJUST_TEMPLATE_FAIL,
          payload: error,
        });

        return Promise.reject(error);
      });
  };
}

export function uploadAdjustFile(params) {
  return function (dispatch) {
    dispatch({ type: types.UPLOAD_ADJUST_FILE })
    return UniDWServerInstance.post("/settlement/uploadadjustfile", params, { timeout: 60000 * 5 })
      .then(({ data }) => {
        dispatch({
          type: types.UPLOAD_ADJUST_FILE_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.UPLOAD_ADJUST_FILE_FAIL,
          payload: error,
        })
        return Promise.reject(error);
      })
  };
}

export function qcApproval(params) {
  return function (dispatch) {
    dispatch({ type: types.QC_APPROVAL })
    return UniDWServerInstance.post("/settlement/qcapprovestatement", params)
      .then(({ data }) => {
        dispatch({
          type: types.QC_APPROVAL_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.QC_APPROVAL_FAIL,
          payload: error,
        })
        return Promise.reject(error);
      })
    }
  }
  
export function downloadQCPostAuditFile(params) {
  return function (dispatch) {
    dispatch({ type: types.DOWNLOAD_QCPOSTAUDIT_FILE });

    return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/downloadqcpostauditfile`, { billing_num: params.billing_num }, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const contentType = response.headers['content-type'];

        if (contentType !== 'application/json') {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', params.file_name);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(fileUrl);
          document.body.removeChild(link);

          dispatch({
            type: types.DOWNLOAD_QCPOSTAUDIT_FILE_SUCCESS,
            payload: fileUrl,
          });

          return Promise.resolve(fileUrl);

        } else {
          throw new Error('File not found or failed downloading');
        }
      })
      .catch(error => {
        dispatch({
          type: types.DOWNLOAD_QCPOSTAUDIT_FILE_FAIL,
          payload: error,
        });

        return Promise.reject(error);
      });
  };
}

export function getInvoicePreview(params) {
  return function (dispatch) {
    dispatch({ type: types.INVOICE_PREVIEW })
    return UniDWServerInstance.post("/settlement/invoicepreview", {billing_num: params.billing_num})
      .then(({ data }) => {
        dispatch({
          type: types.INVOICE_PREVIEW_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.INVOICE_PREVIEW_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getInvoice(params) {
  return function (dispatch) {
    dispatch({ type: types.DOWNLOAD_INVOICE_FILE });

    return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/getinvoice`, { billing_num: params.billing_num }, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const contentType = response.headers['content-type'];

        if (contentType !== 'application/json') {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf;base64' }));

          dispatch({
            type: types.DOWNLOAD_INVOICE_FILE_SUCCESS,
            payload: fileUrl,
          });

          return Promise.resolve(fileUrl);

        } else {
          throw new Error('File not found or failed downloading');
        }
      })
      .catch(error => {
        dispatch({
          type: types.DOWNLOAD_INVOICE_FILE_FAIL,
          payload: error,
        });

        return Promise.reject(error);
      });
  };
}

export function resetSettlementStatus(params) {
  return function (dispatch) {
    dispatch({ type: types.RESET_SETTLEMENT_STATUS })
    return UniDWServerInstance.post("/settlement/resetstatus", params)
      .then(({ data }) => {
        dispatch({
          type: types.RESET_SETTLEMENT_STATUS_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.RESET_SETTLEMENT_STATUS_FAIL,
          payload: error,
        })
        return Promise.reject(error);
      })
    }
  }

  export function searchInvoiceList(params) {
    return function (dispatch) {
      dispatch({ type: types.SEARCH_INVOICE_LIST })
      return UniDWServerInstance.post("/settlement/invoicelist", params)
        .then(({ data }) => {
          dispatch({
            type: types.SEARCH_INVOICE_LIST_SUCCESS,
            payload: data.data,
          })
          return Promise.resolve(data.data);
        }).catch(error => {
          dispatch({

            type: types.SEARCH_INVOICE_LIST_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    }
  }

    export function uploadPickupTranshipementFile(params) {
      return function (dispatch) {
        dispatch({ type: types.UPLOAD_PICKUP_TRANSSHIPMENT_FILE })
        return UniDWServerInstance.post("/settlement/pickuptranshipmentdetail", params)
          .then(({ data }) => {
            dispatch({
              type: types.UPLOAD_PICKUP_TRANSSHIPMENT_FILE_SUCCESS,
              payload: data.data
            })
            return Promise.resolve(data.data);
          }).catch(error => {
            dispatch({
              type: types.UPLOAD_PICKUP_TRANSSHIPMENT_FILE_FAIL,
              payload: error,
            })
            return Promise.reject(error);
          })
      };
    }

    export function uploadClaimPackages(params) {
      return function (dispatch) {
        dispatch({ type: types.UPLOAD_CLAIM_PACKAGES })
        return shangjiaServerInstance.post("/orders/claimpackages", params)
          .then(({ data }) => {
            dispatch({
              type: types.UPLOAD_CLAIM_PACKAGES_SUCCESS,
              payload: data.data
            })
            return Promise.resolve(data.data);
          }).catch(error => {
            dispatch({
              type: types.UPLOAD_CLAIM_PACKAGES_FAIL,
              payload: error,
            })
            return Promise.reject(error);
          })
      };
    }

    export function getInvoiceDetails(params) {
      return function (dispatch) {
        dispatch({ type: types.GET_INVOICE_DETAIL })
        return UniDWServerInstance.post("/settlement/invoice", params)
          .then(({ data }) => {
            dispatch({
              type: types.GET_INVOICE_DETAIL_SUCCESS,
              payload: data.data,
            })
            return Promise.resolve(data.data);
          }).catch(error => {
            dispatch({
  
              type: types.GET_INVOICE_DETAIL_FAIL,
              payload: error
            })
            return Promise.reject(error);
          })
      }
    }
    export function downloadInvoiceFiles(params) {
      return function (dispatch) {
        dispatch({ type: types.DOWNLOAD_INVOICE_FILE });
    
        return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/downloadinvoicefiles`, params, {
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${getUserPreference().authToken}`,
            "Content-Type": "application/json"
          }
        })
          .then(async response => {
            const contentType = response.headers['content-type'];
    
            if (contentType !== 'application/json') {
              const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = fileUrl;
              link.setAttribute('download', params.file_name);
              document.body.appendChild(link);
              link.click();
    
              window.URL.revokeObjectURL(fileUrl);
              document.body.removeChild(link);
    
              dispatch({
                type: types.DOWNLOAD_INVOICE_FILE_SUCCESS,
                payload: fileUrl,
              });
    
              return Promise.resolve(fileUrl);
    
            } else {
             const res = JSON.parse(await response.data.text());
              throw new Error(res.ret_msg);
            }
          })
          .catch(error => {
            dispatch({
              type: types.DOWNLOAD_INVOICE_FILE_FAIL,
              payload: error,
            });
    
            return Promise.reject(error);
          });
      };
    }

    export function downloadCreditMemoTemplate(params) {
      return function (dispatch) {
        dispatch({ type: types.DOWNLOAD_CREDIT_MEMO_TEMPLATE });
    
        return axios.post(`${process.env.REACT_APP_UNI_DW_SERVER_URL}/settlement/downloadtemplate`, {type: 'Credit Memo'}, {
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${getUserPreference().authToken}`,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            const contentType = response.headers['content-type'];
    
            if (contentType !== 'application/json') {
              const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = fileUrl;
              link.setAttribute('download', "template.xlsx");
              document.body.appendChild(link);
              link.click();
    
              window.URL.revokeObjectURL(fileUrl);
              document.body.removeChild(link);
    
              dispatch({
                type: types.DOWNLOAD_CREDIT_MEMO_TEMPLATE_SUCCESS,
                payload: fileUrl,
              });
    
              return Promise.resolve(fileUrl);
    
            } else {
              throw new Error('File not found or failed downloading');
            }
          })
          .catch(error => {
            dispatch({
              type: types.DOWNLOAD_CREDIT_MEMO_TEMPLATE_FAIL,
              payload: error,
            });
    
            return Promise.reject(error);
          });
      };
    }

    export function uploadCreditMemoFile(params) {
      return function (dispatch) {
        dispatch({ type: types.UPLOAD_CREDIT_MEMO_FILE })
        return UniDWServerInstance.post("/settlement/uploadcreditmemo", params)
          .then(({ data }) => {
            dispatch({
              type: types.UPLOAD_CREDIT_MEMO_FILE_SUCCESS,
              payload: data.data
            })
            return Promise.resolve(data.data);
          }).catch(error => {
            dispatch({
              type: types.UPLOAD_CREDIT_MEMO_FILE_FAIL,
              payload: error,
            })
            return Promise.reject(error);
          })
      };
    }