import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, MenuItem } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from '@material-ui/icons/Search';

const componentWidth = 1400;

const useStyles = makeStyles((theme) => ({
    searchBar: {
        background: "#ffffff",
        borderRadius: "4px",
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        margin: "25px 25px 24px",
        padding: "27px 16px 27px 16px",
        display: 'flex',
        minWidth: componentWidth,
    },
    inputField: {
        width: '17%',
        height: 56,
        margin: "16px 14px",
    },
    datePicker: {
        width: '17%',
        height: 56,
        margin: "16px 11px",
    },
    iconButton: {
        maxWidth: 35,
        height: 35,
        margin: "28px 8px",
        color: "#1976d2",
        '&:hover': {
            background: "rgba(25, 118, 210, 0.5)",
        },
        '&:disabled': {
            color: "rgba(0, 0, 0, 0.26)",
            background: "rgba(0, 0, 0, 0.12)"
        }
    },
    header: {
        display: "flex",
        minWidth: componentWidth,
        marginLeft: 30,
    },
    breadCrumbs: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    breadCrumbText: {
        fontSize: 16,
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: ' #000000',
        opacity: 0.8,
    },
    button: {
        backgroundColor: "#2a3848",
        color: " #fff",
        borderRadius: 4,
        padding: "15px 20px",
        fontSize: "12px",
        maxHeight: "36px",
        '&:hover': {
            background: "#3f556f",
        },
        '&:disabled': {
            background: "rgba(0, 0, 0, 0.12)"
        }
    }
}));


export default function BillingManagementSettlementSearch(props) {
    const { status, customers, operators, search, params, setParams } = props;
    const classes = useStyles();

    return (
        <div>
            <div className={classes.header}>
                <div className={classes.breadCrumbs}>
                    <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "10px 0 1px" }}>Billing Management</p>
                </div>

            </div>
            <div className={classes.searchBar} style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Autocomplete
                        className={classes.inputField}
                        value={params.partner || null}
                        onChange={(event, value) => {
                            setParams("partner", value);
                        }}
                        disableClearable={params.customer}
                        variant="outlined"
                        getOptionSelected={(option, value) => option.id === value.id || value.id === 0}
                        options={[...customers]}
                        getOptionLabel={(option) => (option?.id === 0 ? '' : option.partner_id + ":" + option?.partner_name)}
                        renderInput={(params) => (
                            <TextField {...params} label='Partner' variant="outlined" InputLabelProps={{ shrink: true }} />
                        )}
                        limitTags={1}
                    />
                    <Autocomplete
                        className={classes.inputField}
                        value={params.customer || null}
                        onChange={(event, value) => {
                            setParams("customer", value);
                        }}
                        disabled={!params.partner || !params.partner.customer}
                        variant="outlined"
                        getOptionSelected={(option, value) => option.id === value.id || value.id === 0}
                        options={params.partner?.customer ? [...params.partner.customer] : null}
                        getOptionLabel={(option) => (option?.id === 0 ? '' : option.customer_id + ":" + option?.customer_name)}
                        renderInput={(params) => (
                            <TextField {...params} label='Customer' variant="outlined" InputLabelProps={{ shrink: true }} />
                        )}
                        limitTags={1}
                    />
                    <TextField
                        select
                        label="Billing Status"
                        className={classes.inputField}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={params.billingStatus}
                        onChange={(event) => {
                            setParams("billingStatus", event.target.value);
                        }}
                    >
                        {status.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Billing Periods From"
                        type="date"
                        className={classes.datePicker}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={params.billingPeriodFrom}
                        onChange={(event) => {
                            setParams("billingPeriodFrom", event.target.value);
                        }}
                    />
                    <div style={{ marginTop: 20 }}>_</div>
                    <TextField
                        label="Billing Periods To"
                        type="date"
                        className={classes.datePicker}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={params.billingPeriodTo}
                        onChange={(event, value) => {
                            setParams("billingPeriodTo", event.target.value);
                        }}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                        label="Invoice Date From"
                        type="date"
                        className={classes.datePicker}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={params.invoiceConfFrom}
                        onChange={(event) => {
                            setParams("invoiceConfFrom", event.target.value);
                        }}
                    />
                    <div style={{ marginTop: 20 }}>_</div>
                    <TextField
                        label="Invoice Date To"
                        type="date"
                        className={classes.datePicker}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={params.invoiceConfTo}
                        onChange={(event) => {
                            setParams("invoiceConfTo", event.target.value);
                        }}
                    />
                    <TextField
                        label="Invoice Number"
                        className={classes.inputField}
                        InputLabelProps={{ shrink: true }}
                        value={params.invoiceNumber}
                        onChange={(event) => {
                            setParams("invoiceNumber", event.target.value);
                        }}
                        variant="outlined"
                    />
                    <TextField
                        label="Billing Number"
                        className={classes.inputField}
                        InputLabelProps={{ shrink: true }}
                        value={params.billingNumber}
                        onChange={(event) => {
                            setParams("billingNumber", event.target.value);
                        }}
                        variant="outlined"
                    />
                     <Autocomplete
                        className={classes.inputField}
                        value={params.operator || null}
                        onChange={(event, value) => {
                            setParams("operator", value);
                        }}
                        disableClearable={params.customer}
                        variant="outlined"
                        getOptionSelected={(option, value) => option.username === value.username || ''}
                        options={[...operators]}
                        getOptionLabel={(option) => (option? option.username : '')}
                        renderInput={(params) => (
                            <TextField {...params} label='Operator' variant="outlined" InputLabelProps={{ shrink: true }} />
                        )}
                        limitTags={1}
                    />

                    <IconButton className={classes.iconButton} component="span" onClick={search}>
                        <SearchIcon />
                    </IconButton>

                </div>
            </div >
        </div>
    );
}