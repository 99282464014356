import React, {useEffect, useState} from 'react';
import {Drawer, List, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import {Collapse} from "@mui/material";
import ExpandableItem from "../../models/expandableItem";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {getUserUiAbilities} from "../../utilities/common";
import {useHistory} from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import {useToken} from "../../utilities/hooks";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiBackdrop-root": {
      display: "none"
    }
  },
  drawerPaper: {
    width: drawerWidth,
    border: 'none'
  },
  drawerContainer: {
    overflow: "auto"
  }
}));

const SYSTEM_ADMINISTRATOR = 'System Administrator';

function PrmDrawer(props) {
  const {
    ACCESS_ID,
    open,
    menuIndex,
    setMenuIndex
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [menu, setMenu] = useState([]);
  const tokenContents = useToken();

  const userIsSystemAdministrator = tokenContents.roles.includes(SYSTEM_ADMINISTRATOR);

  function handleSelectPage(value) {
    if (value.access_ability_id !== ACCESS_ID) {
      history.push(value.link);
    }
  }

  useEffect(() => {
    let menuData = getUserUiAbilities(userIsSystemAdministrator);
    setMenu(menuData);
  }, [userIsSystemAdministrator])

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <Toolbar />
        <List>
          <div style={{width: '96%', marginLeft: '2%'}}>
            {menu && menu.map((value) => ( value ?
                <ExpandableItem
                  key={value.id}
                  render={xprops => (
                    <>
                      <ListItem
                        button
                        onClick={() => handleSelectPage(value)}
                        style={
                          (value.access_ability_id===ACCESS_ID) ?
                            {color: '#55a6ff', backgroundColor: 'rgba(85, 166, 255, 0.2)'} :
                            {color: '#2a3848', backgroundColor: '#ffffff'}
                        }
                      >
                        <ListItemText
                          id={value?.title}
                          primary={value?.title}
                          primaryTypographyProps={(value.access_ability_id===ACCESS_ID) ?
                            {style: {fontWeight: 600}} :
                            {style: {}}}
                        />
                        {value.access_ability_id===ACCESS_ID ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>

                      <Collapse in={value.access_ability_id===ACCESS_ID} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {value?.nav && value?.nav.map((sub) => (
                            <ListItem
                              key={sub.id}
                              button
                              className={classes.nested}
                              onClick={()=>{setMenuIndex(sub.id)}}
                              style={
                                (sub.id===menuIndex) ?
                                  {color: '#55a6ff', backgroundColor: 'rgba(85, 166, 255, 0.1)'} :
                                  {color: '#2a3848', backgroundColor: 'rgba(85, 166, 255, 0.1)'}
                              }
                            >
                              <ListItemText
                                id={sub.id}
                                primary={sub.title}
                                primaryTypographyProps={(sub.id===menuIndex) ?
                                  {style: {paddingLeft: 20, fontWeight: 600, fontSize: 'small'}} :
                                  {style: {paddingLeft: 20, fontSize: 'small'}}}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  )}
                /> : <></>
            ))}
          </div>
        </List>
      </div>
    </Drawer>
  );
}

export default PrmDrawer;