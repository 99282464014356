import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Switch,
  TextField
} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import {useAlert} from "../../../utilities/hooks";
import {createUser} from "../../../actions/userManagementActions";

const useStyle = makeStyles(()=>({
  dialogButton: {
    width: '260px',
    height: '56px'
  },
  dialogButtonLong: {
    width: '556px',
    height: '56px'
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#1976d2",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#1976d2",
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
}))

const INIT_USER = {
  username: '',
  password: '',
  email: '',
  partner_id: '',
  customer: '',
  is_active: 1
}

const ALERT = {
  SEARCH_SUCCESS  : "Fetch Data Success",
  CREATE_SUCCESS  : "CREATE USER SUCCESS",
  CREATE_FAILED   : "CREATE USER FAILED"
};

function CreateUser(props) {
  const {
    open,
    setOpen
  } = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const alertDialog = useAlert();

  const [user, setUser] = useState(INIT_USER);

  const handleActiveStatus = (e) => {
    let status = user['is_active'] ? 0 : 1;
    setUser((e) => setUser(prev => ({...prev, is_active: status})));
  }

  const handleCreateUser = async (event) => {
    event.preventDefault();
    try {
      const params = Object.fromEntries(Object.entries(user).map(([k, v]) => [k, v === null ? '' : v]));
      await dispatch(createUser(params));
      dispatch(showAlertSnackbar({ title: ALERT.CREATE_SUCCESS, type: 'success' }));
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    }
  }

  return (
    <Dialog maxWidth='lg' open={open} onClose={()=>setOpen(false)}>
      <DialogTitle style={{fontWeight: 600}}>Create User Account</DialogTitle>
      <DialogContent>
        <div style={{ display: 'table-cell' }}>
          <TextField
            className={classes.dialogButton}
            id={'dialog_username'}
            label="User Name"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => setUser(prev => ({...prev, username: e.target.value}))}
          />
          <TextField
            className={classes.dialogButton}
            style={{marginLeft: '36px'}}
            id={'dialog_password'}
            label="Password"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => setUser(prev => ({...prev, password: e.target.value}))}
          />
        </div>
        <div style={{marginTop: '36px'}}>
          <TextField
            className={classes.dialogButtonLong}
            id={'dialog_email'}
            label="Email"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => setUser(prev => ({...prev, email: e.target.value}))}
          />
        </div>
        <div style={{marginTop: '36px'}}>
          <TextField
            className={classes.dialogButton}
            id={'dialog_customer'}
            label="Customer"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => setUser(prev => ({...prev, customer: e.target.value}))}
          />
          <TextField
            className={classes.dialogButton}
            style={{marginLeft: '36px'}}
            id={'dialog_partner_id'}
            label="Partner Id"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => setUser(prev => ({...prev, partner_id: e.target.value}))}
          />
        </div>

        <div>
          <Grid container alignItems="center" spacing={1} >
            <Grid item style={{ color: 'rgba(0, 0, 0, 0.4)'}}>Customer Status</Grid>
            <Grid item>
              <Switch
                id={'is-active-switch'}
                className={classes.switch}
                checked={user['is_active']}
                onChange={handleActiveStatus}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item>Active</Grid>
          </Grid>
        </div>

      </DialogContent>
      <DialogActions style={{ marginBottom: 24 }}>
        <Button
          id={'cancel-create-user'}
          autoFocus
          style={{ marginRight: 24 }}
          onClick={()=>setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          id={'create-user'}
          variant='contained'
          onClick={handleCreateUser}
          className={classes.buttonSystem}
          style={{ marginRight: 24 }}
        >
          create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUser;