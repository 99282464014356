import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Typography, makeStyles, } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showAlertSnackbar } from '../../reducers/uiSlice';
import { generateInvoiceNumber } from '../../actions/billingManangementActions';
import { getCustomers } from '../../actions/storeActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyle = makeStyles(() => ({
  form: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 25px 0px",
    padding: "25px 25px 25px",
    width: "50%",
    display: "block",
    justifyContent: "center",
  },
  header: {
    fontWeight: 600,
    fontSize: 25,
    color: "#000000DE",
    margin: "10px 5px 5px"
  },
  prompt: {
    fontWeight: 800,
    fontSize: 12,
    color: "#000000A3",
    margin: "0px 5px 20px"
  },
  fieldName: {
    margin: "5px 0px 40px",
    width: "100px"
  },
  fieldValue: {
    margin: "5px 24px 40px",
    color: '#666666',
  },
  textBox: {
    width: "50%",
    margin: "5px 0px 40px",
  },
  fillButton: {
    width: "200",
    display: "flex",
    margin: "5px 0px 40px",
    backgroundColor: "#1976D2",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 600,
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }

  },
  buttonText: {
    color: "#1976d2",
    width: 100,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'

    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  }


}));

function InvoiceNumberGenerator(props) {
  const {
    setLoading
  } = props;
  const dispatch = useDispatch();
  const classes = useStyle();
  const { t } = useTranslation('billingManagement');

  const DEFAULT_OPTION = { id: 0, alias: 'Other' };

  const [invoiceTo, setInvoiceTo] = useState(null);
  const [description, setDescription] = useState("");
  const [generateInvoiceResult, setgenerateInvoiceResult] = useState("");
  const [generateResultDialog, setGenerateResultDialog] = useState(false);
  const [customers, setCustomersList] = useState([]);
  const [otherCustomer, setOtherCustomer] = useState("");

  const _filterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state);
    results.unshift(DEFAULT_OPTION);
    return results;
  };

  async function generateInvoice() {
    setLoading(true);
    var data = { 
      operator: window.localStorage.getItem('username'), 
      description: description };
    
    if(invoiceTo && invoiceTo.id !== 0){
      data.customer_id = invoiceTo.id;
    }else{
      data.custom_company = otherCustomer;
    }

    await dispatch(generateInvoiceNumber(data))
      .then((res) => {
        setgenerateInvoiceResult(res);
        setGenerateResultDialog(true);
      })
      .catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })


    setLoading(false);

  }

  function copyInvoiceNumber() {
    navigator.clipboard.writeText(generateInvoiceResult);
    dispatch(showAlertSnackbar({ message: "Copied to Clipboard!", type: 'success' }));
  }
  useEffect(() => {
    dispatch(getCustomers()).then((res) => {
      setCustomersList(res);
    })
      .catch(() => {
        dispatch(showAlertSnackbar({ message: 'Get Customer failed, please reload page', type: 'error' }));
      })
  }, [dispatch]);

  return (
    <div>
      <div className={classes.breadCrumbs}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>{t('invoice_number_generator')}</p>
      </div>

      <div style={{ display: "flex" }}>
        <div className={classes.form}>
          <Typography className={classes.header}>{t('generate_invoice_number')}</Typography>
          <Typography className={classes.prompt}>{t('generate_inovice_prompt')}</Typography>
          <div style={{ display: "flex" }}>
            <Typography className={classes.fieldName}>{t('operator')}:  </Typography>
            <Typography className={classes.fieldValue}>{window.localStorage.getItem('username')}</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: 'row' }}>
            <Autocomplete
              className={classes.textBox}
              style={{ marginRight: "10px" }}
              value={invoiceTo}
              onChange={(event, value) => {
                setInvoiceTo(value);
                setOtherCustomer("");
              }}
              variant="outlined"
              getOptionSelected={(option, value) => option.id === value.id}
              filterOptions={filterOptions}
              options={[...customers,DEFAULT_OPTION]}
              getOptionLabel={(option) => (option ? option.id + ":" + option.alias : '')}
              renderInput={(params) => (
                <TextField {...params} label={t('invoice_to')} variant="outlined" InputLabelProps={{ shrink: true }} required />
              )}
              limitTags={1}
            />
            {
              (invoiceTo && !invoiceTo.id !== 0) &&
              <TextField
                hidden
                required
                style={{ marginLeft: "10px" }}
                className={classes.textBox}
                label={t('other_customer')}
                value={otherCustomer}
                onChange={(e) => setOtherCustomer(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            }
          </div>
          <TextField
            multiline
            style={{ width: '100%' }}
            minRows={10}
            placeholder={t('description_prompt')}
            className={classes.textBox}
            label={t('description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Button className={classes.fillButton} variant="contained" disabled={invoiceTo === null || (invoiceTo?.id === 0 && !otherCustomer)} onClick={() => generateInvoice()}>
            {t('generate')}
          </Button>
        </div>
      </div>

      <Dialog maxWidth='sm' fullWidth={true} open={generateResultDialog} onClose={() => setGenerateResultDialog(false)}>
        <DialogTitle >
          <Typography className={classes.header}>{t('generate_result')}</Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex" }}>
            <Typography className={classes.fieldName}>{t('operator')}:  </Typography>
            <Typography className={classes.fieldValue}>{window.localStorage.getItem('username')}</Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography className={classes.fieldName}>{t('invoice_to')}:  </Typography>
            { (invoiceTo && invoiceTo.id!==0) ?
                <Typography className={classes.fieldValue}>{invoiceTo?.id + "." + invoiceTo?.alias}</Typography>
              :
              <Typography className={classes.fieldValue}>{otherCustomer}</Typography>
            }
          </div>
          <div style={{ display: "flex" }}>
            <Typography className={classes.fieldName}>{t('description')}:  </Typography>
            <Typography className={classes.fieldValue}>{description}</Typography>
          </div>
          <div style={{ display: "flex", backgroundColor: '#EDF6FF' }} onClick={() => copyInvoiceNumber()}>
            <Typography style={{ fontWeight: 700, margin: "10px 24px 10px", width: "155px" }} className={classes.fieldName}>{t('invoice_number')}:  </Typography>
            <Typography style={{ color: "#000000DE", margin: "10px 5px 10px", }} className={classes.fieldValue}>{generateInvoiceResult}</Typography>
            <ContentCopyIcon style={{ color: "#1976D2", fontSize: 'medium', margin: "10px 8px 10px" }} onClick={() => copyInvoiceNumber()} />
          </div>
        </DialogContent>
        <DialogActions style={{ marginBottom: 24 }}>
          <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => setGenerateResultDialog(false)}>{t('ok')}</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}


export default InvoiceNumberGenerator;