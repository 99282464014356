import React, {useState} from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import PrmFrame from "../../components/PrmFrame";
// import FullscreenLoading from "../../components/FullscreenLoading";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";
import DeliveryCapacityDisplay from "../../components/DeliveryCapacity/DeliveryCapacityDisplay";
import DeliveryCapacityUpdate from "../../components/DeliveryCapacity/DeliveryCapacityUpdate";
import DeliveryThresholdUpdate from "../../components/DeliveryCapacity/DeliveryThresholdUpdate";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const DELIVERY_CAPACITY_ACCESS_ID = 9;

function DeliveryCapacityMain() {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={DELIVERY_CAPACITY_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
      <PrmMain
        className={classes.content}
        open={open}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <DeliveryCapacityDisplay displayOpen={menuIndex===0}/>
        </div>
        <div hidden={menuIndex!==1}>
          <DeliveryCapacityUpdate updateOpen={menuIndex===1}/>
        </div>
        <div hidden={menuIndex!==2}>
          <DeliveryThresholdUpdate displayOpen={menuIndex===2}/>
        </div>
        {/* <FullscreenLoading open={loading}/> */}
      </PrmMain>
    </div>
  );
}

export default DeliveryCapacityMain;