import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, Divider, Dialog } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import { searchInvoiceList, getInvoiceDetails } from '../../actions/billingManangementActions';
import { useDispatch } from 'react-redux';
import { showAlertSnackbar } from '../../reducers/uiSlice';
import InvoiceDetail from './InvoiceDetail';
import CreditMemoDetail from './CreditMemoDetail';
import FullscreenLoading from "../FullscreenLoading";

const componentWidth = 1300;

const useStyles = makeStyles((theme) => ({
  searchBar: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 0px 24px",
    display: 'flex',
    minWidth: componentWidth,
  },
  table: {
    width: '100%',
    height: 670,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      paddingLeft: '20px',
    },
    '& .MuiDataGrid-columnHeader': {
      paddingLeft: '20px', // Adjust this value as needed
    }
  },
  iconButton: {
    maxWidth: 30,
    height: 30,
    marginTop: "15px",
    marginLeft: "20px",
    color: "#1976d2",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.5)",
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.26)",
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  header: {
    display: "flex",
    minWidth: componentWidth,
    marginLeft: 30,
  },
  breadCrumbs: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  divider: {
    height: 112,
    border: "1px  rgba(0, 0, 0, 0.12)"
  },
  invoiceList: {
    minWidth: componentWidth,
    minHeight: "745px",
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "0px 25px 25px 24px",
  },
  button: {
    fontSize: '14px',
    color: '#2196F3'
  },
}));


export default function InvoiceManagement(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [invoiceNum, setInvoiceNum] = useState("");
  const [invoiceTo, setInvoiceTo] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [displayInfo, setDisplayInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dialog, setDialogOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [fullscreenLoad, setFullScreenLoad] = useState(false);
  const hasBeenRendered = useRef(false);

  const invoiceListColumns = [
    {
      field: 'cr_num',
      headerName: "Invoice Number",
      width: 260,
    },
    {
      field: 'invoice_num',
      headerName: "Master Invoice Number",
      width: 260,
    },
    {
      field: 'create_at',
      headerName: "Invoice Creation Date",
      width: 260,
    },
    {
      field: 'total_amount',
      headerName: "Amount",
      width: 260,
      valueGetter: (param) => {
        return "$" + param.value;

      }
    },
    {
      field: 'slip_type_description',
      headerName: "Invoice Type",
      width: 260,
    }
  ]

  function getInvoiceList() {
    setLoading(true);
    const params = {
      invoice_num: invoiceNum,
    };

    dispatch(searchInvoiceList(params))
      .then((res) => {
        setDisplayInfo(true);
        setInvoiceList(res.slip_list);
        setInvoiceTo(res.invoice_to);
        setTotalAmount("$" + res.total_amount);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setDisplayInfo(false);
        setInvoiceList([]);
        setInvoiceTo("");
        setTotalAmount("");
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })
  }


  useEffect(() => {
    if (hasBeenRendered.current) {
      dispatch(getInvoiceDetails(rowData))
        .then((res) => {
          setDetails(res);
          setFullScreenLoad(false);
          setDialogOpen(true);
        })
        .catch((err) => {
          setFullScreenLoad(false);
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        })
    }
    hasBeenRendered.current = true;
  }, [rowData, dispatch]);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "10px 0 1px" }}>Invoice Management</p>
        </div>
      </div>
      <div className={classes.searchBar} style={{ display: "flex" }}>
        <div style={{ display: 'flex', alignItems: 'center', width: "650px", marginLeft: "20px" }}>
          <TextField
            label="Invoice Number"
            variant="outlined"
            placeholder="Enter your invoice number here"
            value={invoiceNum}
            onChange={(e) => setInvoiceNum(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ width: '360px' }}
          />
          <IconButton className={classes.iconButton} component="span" onClick={() => getInvoiceList()}>
            <SearchIcon />
          </IconButton>
        </div>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        {displayInfo && (
          <div style={{ width: "650px" }}>
            <div style={{ marginLeft: "20px", marginTop: "20px" }}>
              <div style={{ fontWeight: "bold", marginBottom: "20px" }}>Invoice Combined Information</div>
              <div style={{ display: "inline-block", width: "300px" }}>
                <span style={{ color: "#616161", marginRight: "10px" }}>Invoice To: </span>{invoiceTo}
              </div>
              <div style={{ display: "inline-block", width: "300px" }}>
                <span style={{ color: "#616161", marginRight: "10px" }}>Total Amount: </span>{totalAmount}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.invoiceList}>
        <div style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "10px 10px 10px 10px" }}>Invoice</div>
        <DataGrid
          className={classes.table}
          loading={loading}
          rows={loading ? [] : invoiceList}
          columns={invoiceListColumns}
          checkboxSelection={false}
          onRowClick={(params) => {
            setFullScreenLoad(true);
            const rowData = {};
            invoiceListColumns.forEach(column => {
              rowData[column.field] = params.getValue(params.id, column.field);
            });
            setRowData(rowData);
          }}
        />
      </div>

      <Dialog maxWidth={"lg"} fullWidth fullwidth open={dialog} >
        {
          details?.type &&
          <div>{
            details['type'] === 'Invoice' ?
              <InvoiceDetail
                setDialogOpen={setDialogOpen}
                refreshDialog={setRowData}
                setDetails={setDetails}
                refresh={getInvoiceList}
                data={details} />
              :
              <CreditMemoDetail
                setDialogOpen={setDialogOpen}
                data={details} />
          }
          </div>
        }
      </Dialog>
      <FullscreenLoading open={fullscreenLoad || !details} />
    </div>
  );
}