import {shangjiaServerInstance} from "../axios/instances";
import {
  GET_MAWB_STATISTIC,
  GET_MAWB_STATISTIC_SUCCESS,
  GET_MAWB_STATISTIC_FAIL,
  GET_BAG_STATISTIC,
  GET_BAG_STATISTIC_SUCCESS,
  GET_BAG_STATISTIC_FAIL,
  GET_FAILED_STATISTIC,
  GET_FAILED_STATISTIC_SUCCESS,
  GET_FAILED_STATISTIC_FAIL,
  GET_WRONG_AREA_STATISTIC,
  GET_WRONG_AREA_SUCCESS,
  GET_WRONG_AREA_FAIL,
  EDIT_ORDER,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAIL,
  HANDLE_ROW_VALUES,
  PRE_ALERT as types
} from "./types"
import {execute} from "../network/executor";

export function getMAWBStatistic(startTime, endTime) {
  return function(dispatch) {
    dispatch({type: GET_MAWB_STATISTIC})
    return shangjiaServerInstance.get(
      `/business/getmawbstatistic?start=${startTime}&end=${endTime}`,
      {timeout: 60000 * 4}
    )
      .then(({data}) => {
        dispatch({
          type: GET_MAWB_STATISTIC_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type:  GET_MAWB_STATISTIC_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getBagStatistic(startTime, endTime) {
  return function(dispatch) {
    dispatch({type: GET_BAG_STATISTIC})
    return shangjiaServerInstance.get(
      `/business/getbagstatistic?start=${startTime}&end=${endTime}`,
      {timeout: 60000 * 2}
    )
      .then(({data}) => {
        dispatch({
          type: GET_BAG_STATISTIC_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type:  GET_BAG_STATISTIC_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

/**
 *
 * @param {Object} params - combined object to send for server processing
 * @param {string} params.start_time - start time
 * @param {string} params.end_time - end time
 * @param {string} params.tno - tracking number
 * @param {string} params.mawb - MAWB
 * @param {string} params.reference - reference number
 * @param {string} params.error_message - error_message
 */

export function getFailedStatistic(params) {
  return function(dispatch) {
    dispatch({type: GET_FAILED_STATISTIC})
    return shangjiaServerInstance.get(
      `/orders/getfailedprealertorder`, {params: params}, {timeout: 60000 * 4}
    )
      .then(({data}) => {
        dispatch({
          type: GET_FAILED_STATISTIC_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type:  GET_FAILED_STATISTIC_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function createPrealertOrder(params, b2b) {
  return function (dispatch) {
    if(b2b) {
      dispatch({type: EDIT_ORDER})
      return shangjiaServerInstance.post(`/orders/createprealertb2border`, params)
        .then(({data}) => {
          dispatch({
            type: EDIT_ORDER_SUCCESS,
            payload: data.data,
          })
          return Promise.resolve(data.data);
        }).catch(error => {
          dispatch({
            type:  EDIT_ORDER_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    } else {
      dispatch({type: EDIT_ORDER})
      return shangjiaServerInstance.post(`/orders/createprealertorder`, params)
        .then(({data}) => {
          dispatch({
            type: EDIT_ORDER_SUCCESS,
            payload: data.data,
          })
          return Promise.resolve(data.data);
        }).catch(error => {
          dispatch({
            type:  EDIT_ORDER_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    }
  }
}

export function getWrongAreaStatistic(date, partner_id) {
  return function(dispatch) {
    dispatch({type: GET_WRONG_AREA_STATISTIC})
    return shangjiaServerInstance.get(
      `/orders/checkwrongareaorders?date=${date}&partner_id=${partner_id}`,
      {timeout: 60000 * 4}
    )
      .then(({data}) => {
        dispatch({
          type: GET_WRONG_AREA_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type:  GET_WRONG_AREA_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export const getPartners190Stats = (start, end) => (d) => execute(d)(
  shangjiaServerInstance.get(`/orders/getpartners190stats?start=${start}&end=${end}`),
  {
    type: types.GET_PARTNERS_190_STATS,
    success: types.GET_PARTNERS_190_STATS_SUCCESS,
    fail: types.GET_PARTNERS_190_STATS_FAIL
  }
);

export const getPartners190Warehouse = (id, date) => (d) => execute(d)(
  shangjiaServerInstance.get(`/orders/getpartners190warehouse?partner_id=${id}&date=${date}`),
  {
    type: types.GET_PARTNERS_190_WAREHOUSE_SUCCESS,
    success: types.GET_PARTNERS_190_WAREHOUSE_SUCCESS,
    fail: types.GET_PARTNERS_190_WAREHOUSE_FAIL
  }
);

export function downloadWrongAreaOrders(keyword) {
  const axios = require('axios');
  return axios.get(`${process.env.REACT_APP_SJ_SERVER_URL}/orders/downloadwrongareaorders?mawb=${keyword}`,
    {responseType: 'blob'}
  )
}

export function handleRowValues(params) {
  return function(dispatch){
    dispatch({type: HANDLE_ROW_VALUES})
    return (
      dispatch({
        type: HANDLE_ROW_VALUES,
        payload: params
      })
    )}
}