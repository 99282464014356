import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { currentTheme } from './reducers/uiSlice';
import darkTheme from './themes/darkTheme';
import lightTheme from './themes/lightTheme';
import UniSnackbarAlert from './components/UniSnackbarAlert';
import { BrowserRouter as Router } from "react-router-dom";
import { renderRoutes } from 'react-router-config'
import { routes } from "./route/index";
import AlertContextProvider from './components/UniAlert/context';
import UniAlert from './components/UniAlert';
import { CustomerContextProvider } from "./components/PrmAppBar/CustomerContext";
import { userLogout } from "./actions/userActions";
import { parseJwt } from "./utilities/common";

function App() {
	const isDarkTheme = useSelector(currentTheme);
	const theme = isDarkTheme ? darkTheme : lightTheme;
	const token = window.localStorage.getItem("auth_token");
	const lastActivityTime = useRef(new Date());

	useEffect(() => {
		const checkUserActivity = () => {
			const currentTime = new Date();
			const inactiveTime = currentTime - lastActivityTime.current;

			// Check token expiration
			if (token) {
				const exp = parseJwt(token).exp;
				const timeLeft = exp - Math.floor(currentTime / 1000);
				if (timeLeft < 0) {
					userLogout();
				}
			}

			// Check if 30 minutes have passed
			if (inactiveTime >= 30 * 60 * 1000) {
				userLogout();
			}
		};
		checkUserActivity();

		const activityTimer = setInterval(checkUserActivity, 60000);
		return () => clearInterval(activityTimer);
	}, [token]);

	useEffect(() => {
		function handleUserActivity() {
			lastActivityTime.current = new Date();
		};
		window.addEventListener('mousemove', handleUserActivity);
		window.addEventListener('keydown', handleUserActivity);

		return () => {
			window.removeEventListener('mousemove', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
		};
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<AlertContextProvider>
				<CustomerContextProvider>
					<Router>
						{renderRoutes(routes)}
					</Router>
					<UniSnackbarAlert />
					<UniAlert />
				</CustomerContextProvider>
			</AlertContextProvider>
		</ThemeProvider>
	);
}

export default App;