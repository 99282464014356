import { useDispatch } from 'react-redux';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { showAlertSnackbar } from "../../../reducers/uiSlice";
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { getPartnerThresholdByWarehouse,updatePartnerThresholdByWarehouse } from "../../../actions/deliveryCapacityActions";
import { getBranchList } from "../../../actions/commonActions";
import FullscreenLoading from "../../FullscreenLoading";

const useStyle = makeStyles((theme) => ({
  header: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  button: {
    backgroundColor: "#1976d2",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    marginLeft: theme.spacing(2),
    maxHeight: "36px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },

  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 700,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 10.5,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.25,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .partner_column_style': {
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontWeight: 550,
    },
    '& .notSet': {
      color: '#D32F2FDE',
    },
  },
  framedButton: {
    color: "#1976D2",
    borderRadius: 4,
    border: "solid 1px #1976D2",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 550,
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  footerButtonContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 80,
    padding: theme.spacing(2),
  },
  footerSummaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 80,
    padding: theme.spacing(2),
  },
  footerSumCells: {
    margin: '10px 0 0',
    minWidth: 200,
  },
  buttonText: {
    color: "#1976d2",
    width: 100,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'

    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }


  },
}));

const ALERT = {
  SUCCESS: "FETCH THRESHOLD DATA SUCCESS",
  FAILED: "FETCH THRESHOLD DATA FAILED",
  UPDATE_SUCCESS: "UPDATE DATA SUCCESS",
  UPDATE_FAILED:"UPDATE DATA FAILED",
};

function DeliveryThresholdUpdate(props) {
  const { displayOpen } = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [year, setYear] = useState('');
  const [week, setWeek] = useState(0);
  const [clearConf, setClearconf] = useState(true);
  const [errPopup, setErrorPopup] = useState(true);
  const [errMsg,setErrMsg] = useState('');
  const [fullscreenLoading, setFullScreenLoading] = useState(false);

  const getWarehousePairs = useCallback(async () => {
    try {
      let res = await dispatch(getBranchList());
      let branches = res.filter(x => x.id===x.parent).map(x => (
        { 
          field: x.name, 
          headerName: x.name, 
          minWidth: 200, 
          valueFormatter: ({value}) =>{return (isNaN(value)) ? value : value+'%' }, 
          valueGetter: (params) => { return (isNaN(params.value)) ? "N/A" : params.value }, 
          editable: true }));
      setColumns([
        {
          field: 'partner_id',
          headerName: 'Partner ID',
          minWidth: 120,
          cellClassName: 'partner_column_style',
        },
        {
          field: 'partner_name',
          headerName: 'Partner',
          minWidth: 280,
          cellClassName: 'partner_column_style',
        },
      ].concat(branches));
    } catch (e) {
      console.log('Fetch warehouse failed', e);
    }
  }, [dispatch])

  function clearThresholds() {
    let newRows = [];
    for (let row of rows) {
      let temp = {};
      Object.keys(row).forEach((k) => {
        if (k.includes("partner")) {
          temp[k] = row[k];
        } else {
          temp[k] = 0;
        }
      });
      newRows.push(temp);
    }
    setRows(newRows);
  }

  async function updateThreshold() {
    setFullScreenLoading(true);
    try {
      const params = {
        week: week,
        year: year,
        data: rows
      };
     await dispatch(updatePartnerThresholdByWarehouse(params));

      dispatch(showAlertSnackbar({ message: ALERT.UPDATE_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.UPDATE_FAILED+": "+e.message, type: 'error'}));
      setErrMsg(e.message);
      setErrorPopup(false);
    } finally {
      setFullScreenLoading(false);
      await fetchRowData();
    }
  }

  const fetchRowData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dispatch(getPartnerThresholdByWarehouse(year, week));
      setRows(res.sort((a, b) => a.partner_id - b.partner_id));
      dispatch(showAlertSnackbar({ message: ALERT.SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.FAILED+": "+e.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch,year,week])

  const thresHoldFooter = () => {
    return (
      <div className={classes.footerButtonContainer}>
        <Button
          className={classes.framedButton}
          id={'refresh'}
          style={{ cursor: 'pointer' }}
          onClick={() => { setClearconf(false) }}
        >
          CLEAR ALL
        </Button>
        <Button
          className={classes.button}
          id={'refresh'}
          style={{ cursor: 'pointer' }}
          onClick={updateThreshold}
        >
          update
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (displayOpen) {
      dayjs.extend(isoWeek);
      const date = dayjs();
      setYear(date.format('YYYY'));
      setWeek(date.isoWeek());
    }
  }, [displayOpen])

  useEffect(() => {
    if (displayOpen && year && week) {
      getWarehousePairs();
      fetchRowData();
    }
  }, [displayOpen,year,week,getWarehousePairs,fetchRowData])


  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      const updatedRows = rows.map((row) => {
        if (row.partner_id === id) {
          row[field] = Number(value);
          return row;
        }
        return row;
      });
      setRows(updatedRows);
    },
    [rows],
  );

  return (
    <div>
      <div className={classes.header}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: 600, margin: "2px 0 1px" }}>Update Partner Threshold</p>
      </div>

      <div className={classes.tableArea}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              color: "#577699",
              fontSize: 18,
              fontWeight: 600,
              marginLeft: 18,
              marginTop: 15
            }}
          >
            Week: {week}, {year}
          </div>
        </div>

        <DataGrid
          autoHeight
          className={classes.table}
          rows={rows}
          getRowId={(row) => row.partner_id}
          columns={columns}
          loading={loading}
          pagination={false}
          components={{
            Footer: thresHoldFooter,
          }}
          onCellEditCommit={handleCellEditCommit}
          getCellClassName={(params) => {
            if (params.value === "N/A") {
              return 'notSet';
            }
          }}
        />
      </div>

      <Dialog maxWidth='sm' fullWidth open={!clearConf} onClose={() => setClearconf(false)}>
        <DialogTitle><b>Clear All Data</b></DialogTitle>
        <DialogContent>
          <p>You are going to clear all the data. Do you want to proceed</p>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button className={classes.buttonText} autoFocus onClick={(e) => { setClearconf(true) }}>CANCEL</Button>
          <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => { clearThresholds(); setClearconf(true) }}>CLEAR ALL</Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth='sm' fullWidth open={!errPopup} onClose={() => setErrorPopup(false)}>
        <DialogTitle><b>Update Threshold Error</b></DialogTitle>
        <DialogContent>
          <p>{errMsg +" Thresholds not Updated"}</p>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button className={classes.buttonText} style={{ marginRight: 24 }} autoFocus onClick={() => { setErrorPopup(true) }}>Ok</Button>
        </DialogActions>
      </Dialog>
      <FullscreenLoading open={fullscreenLoading}/>
    </div>
  );
}

export default DeliveryThresholdUpdate;