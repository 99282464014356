import {makeStyles} from "@material-ui/core";
import {useCustomer} from "../../../utilities/hooks";
import {getCustomers} from "../../../actions/storeActions";
import {useDispatch} from "react-redux";
import {useEffect, useState, useCallback} from "react";

const useStyles = makeStyles((theme) => ({
  borderBackground: {
    padding: '14px 20px 19px',
    borderRadius: 4,
    border: 'solid 1px #e4e4e3',
    width: 940,
    backgroundColor: '#f7f8f9'
  },
  info: {
    display: 'flex',
    marginTop: 18,
    marginBottom: 18,
    marginLeft: 50,
    fontSize: 17
  }
}))

function Profile(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState();
  const { customerData } = useCustomer();

  const accountList = [
    {
      name: 'Customer Name:',
      apiName: 'alias'
    },
    {
      name: 'Customer Number:',
      apiName: 'id'
    },
    {
      name: 'Secret Key:',
      apiName: 'client_secret'
    },
    {
      name: 'Client ID:',
      apiName: 'client_id'
    }
  ]
  const contactList = [
    {
      name: 'Contact Name:',
      apiName: 'receipt_name'
    },
    {
      name: 'Email Address:',
      apiName: 'email'
    },
    {
      name: 'Cell Number:',
      apiName: 'phone'
    },
    {
      name: 'Company Address:',
      apiName: 'address'
    }
  ]

  const getCustomerInfo = useCallback(async (id) => {
    try {
      let data = await dispatch(getCustomers(id));
      if (data && data.length > 0) {
        setCustomer(data[0]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    getCustomerInfo(customerData.id);
  }, [getCustomerInfo, customerData.id]);


  return (
    <div className={classes.borderBackground}>
      {customer && (
        <div>
          {props.type === 'account' && accountList.map((info) => (
            <div className={classes.info}>
              <div style={{marginRight: 20}}>
                {info.name}
              </div>
              <div style={{color: '#636463'}}>
                {customer[info.apiName] ?? window.localStorage.getItem(info.apiName)}
              </div>
            </div>
          ))}
          {props.type === 'contact' && contactList.map((info) => (
            <div className={classes.info}>
              <div style={{marginRight: 20}}>
                {info.name}
              </div>
              <div style={{color: '#636463'}}>
                {customer[info.apiName]}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Profile;