import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Typography, makeStyles, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import { uploadPickupTranshipementFile, uploadClaimPackages } from '../../../actions/billingManangementActions';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import DragDropFileUpload from '../../DragDropFileUpload';
import DocumentDetailDisplay from '../../DocumentDetailDisplay';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';


const useStyle = makeStyles(() => ({
    form: {
        background: "#ffffff",
        borderRadius: "4px",
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        margin: "25px 25px 25px 0px",
        padding: "25px 25px 25px",
        width: "50%",
        display: "block",
        justifyContent: "center",
    },
    header: {
        fontWeight: 600,
        fontSize: 25,
        color: "#000000DE",
        margin: "10px 5px 5px"
    },
    prompt: {
        fontWeight: 800,
        fontSize: 12,
        color: "#000000A3",
        margin: "0px 5px 20px"
    },
    fieldName: {
        margin: "5px 0px 40px",
        width: "120px"
    },
    fieldValue: {
        margin: "5px 24px 40px",
        color: '#666666',
    },
    textBox: {
        width: "50%",
        margin: "5px 0px 40px",
    },
    fillButton: {
        width: "200",
        display: "flex",
        margin: "5px 0px 40px",
        backgroundColor: "#1976D2",
        color: "#FFFFFF",
        fontSize: 12,
        fontWeight: 600,
        '&:hover': {
            background: "#1565c0",
        },
        '&:disabled': {
            background: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)"
        }

    },
    buttonText: {
        color: "#1976d2",
        width: 100,
        height: 19,
        margin: '2px 0 3px',
        fontSize: "14px",
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        '&:hover': {
            borderRadius: 4,
            backgroundColor: 'rgba(2, 136, 209, 0.04)'

        },
        '&:disabled': {
            color: "rgba(0, 0, 0, 0.38)"
        }
    }


}));

function BillingSupplementary(props) {
    const {
        setLoading
    } = props;
    const dispatch = useDispatch();
    const classes = useStyle();
    const { t } = useTranslation('billingManagement');

    const FILE_TYPES = [
        { id: 0, type: 'Transhipment/Pickup' },
        { id: 1, type: 'Claim' },
    ];
    dayjs.extend(weekOfYear);
    const startDate = dayjs().day(1).format("YYYY-MM-DD");
    const endDate = dayjs().day(+7).format("YYYY-MM-DD");
    const week = dayjs(startDate).week();
    const [fileType, setFileType] = useState(FILE_TYPES[0].type);
    const [upload, setUpload] = useState(false);
    const [file, setFile] = useState('');
    const [uploadFailed, setUploadFailed] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [ptfailedMsg, setPTFailedMsg] = useState([]);
    const [claimFailedMsg, setClaimFailedMsg] = useState([]);

    async function handleUploadFile() {
        try {
            setLoading(true);
            setUpload(false);
            //transhipment/pickup
            if (fileType === FILE_TYPES[0].type) {

                let formData = new FormData();
                formData.append('file', file);
                await dispatch(uploadPickupTranshipementFile(formData));

            } else if (fileType === FILE_TYPES[1].type) {

                let formData = new FormData();
                formData.append('claim_packages', file);
                await dispatch(uploadClaimPackages(formData));
            }
            setFile('');
            setUploadSuccess(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch(showAlertSnackbar({ message: fileType + " Upload Failed", type: 'error' }));
            if (fileType === FILE_TYPES[0].type) {

                if (!error.additional) {
                    setPTFailedMsg(error.message);
                } else {
                    setPTFailedMsg(error.additional);
                }

            } else if (fileType === FILE_TYPES[1].type) {

                setClaimFailedMsg(error.message);

            }
            setUploadFailed(true);
        }
    }

    return (
        <div>
            <div className={classes.breadCrumbs}>
                <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>{t('billing_supplementary')}</p>
            </div>

            <div style={{ display: "flex", margin:"0px"}}>
                <div className={classes.form}>
                    <Typography className={classes.header}>{t('billing_supplementary_title_prompt')}</Typography>
                    <Typography className={classes.prompt}>{t('billing_supplementary_prompt_detail')}</Typography>
                    <div style={{ display: "flex" }}>
                        <Typography className={classes.fieldName} style={{marginBottom:"5px"}}>{t('billing_period')}:  </Typography>
                        <Typography className={classes.fieldValue} style={{marginBottom:"5px"}}>{startDate + " - " + endDate + " Wk" + week}</Typography>
                    </div>
                    <div style={{margin:"5px 0px 10px 0px"}}>
                        <Button
                            className={classes.buttonText}
                            style={{ marginRight: 24 }}
                        >
                            {fileType === FILE_TYPES[0].type ?
                                <Link
                                    to="/documents/pickup_transshipment_detail_template.xlsx"
                                    target="_blank"
                                    download
                                    style={{
                                        textDecoration: 'none',
                                        textTransform: 'none',
                                        color: "#1976d2",
                                        width: 100,
                                        height: 19,
                                        margin: '2px 0 3px',
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontStretch: 'normal',
                                        fontStyle: 'normal',
                                    }}
                                >
                                    {'Pickup_transhipment_template'}
                                </Link>
                                :
                                <Link
                                    to="/documents/claim_package_template.xlsx"
                                    target="_blank"
                                    download
                                    style={{
                                        textDecoration: 'none',
                                        textTransform: 'none',
                                        color: "#1976d2",
                                        width: 100,
                                        height: 19,
                                        margin: '2px 0 3px',
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontStretch: 'normal',
                                        fontStyle: 'normal',
                                    }}
                                >
                                    {'Claim_template'}
                                </Link>
                            }
                        </Button>
                    </div>
                    <div style={{ display: "flex", flexDirection: 'row' }}>
                        <TextField
                            select
                            name='file'
                            label="file"
                            className={classes.textBox}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                        >
                            {Object.entries(FILE_TYPES).map(([k, v]) => (
                                <MenuItem key={v.id} value={v.type}>
                                    {v.type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <Button className={classes.fillButton} variant="contained" onClick={() => setUpload(true)}>
                        {t('upload')}
                    </Button>
                </div>
            </div>

            <Dialog maxWidth={"sm"} fullWidth open={upload}>

                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ position: 'relative' }}>
                        <span>{"Upload " + fileType + " File"}</span>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <DragDropFileUpload fileType={".xlsx"} onFileUpload={setFile} file={file} />
                    <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </DialogContent>
                <DialogActions style={{ marginBottom: '15px', marginRight: '20px' }}>
                    <Button className={classes.buttonText} autoFocus onClick={() => { setUpload(false); setFile('') }}>
                        close
                    </Button>
                    <Button variant='contained' style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '600' }} onClick={() => handleUploadFile(true)}>
                        {t('upload')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth={"sm"} fullWidth open={uploadFailed}>

                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ position: 'relative' }}>
                        <span>Upload Failed</span>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <div style={{ color: '#D32F2F', fontSize: 12, display: 'flex', flexDirection: "row", alignItems: "center", }}>
                        <InfoOutlinedIcon style={{ fontSize: 15, marginRight: 12, marginBottom: 3 }} />
                        <div>Please correct the rows listed below and upload the adjustment file again</div>
                    </div>
                    <div style={{ padding: 10 }}> <DocumentDetailDisplay file={file} state={'Complete'} /> </div>
                    {
                        ptfailedMsg && (typeof ptfailedMsg === 'string' || ptfailedMsg instanceof String) &&
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>{ptfailedMsg}</div>
                    }
                    {
                        ptfailedMsg && !(typeof ptfailedMsg === 'string' || ptfailedMsg instanceof String) &&
                        Object.keys(ptfailedMsg).map((key) => {
                            return <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                                {isNaN(key) && <div style={{ paddingRight: 15, paddingBottom: 10 }}>{key}: </div>}
                                {ptfailedMsg[key] && Array.isArray(ptfailedMsg[key]) && ptfailedMsg[key].map((item) => {
                                    return <div style={{ paddingRgiht: 15, display: "flex", flexDirection: "row", }}>
                                        <div style={{ paddingRight: 15 }}>Row {item.row}: </div>
                                        <div>
                                            {
                                                item.errors &&
                                                item.errors.map((item) => {
                                                    return <div>{item} </div>
                                                })
                                            }
                                        </div>
                                    </div>

                                })}
                                {ptfailedMsg[key] && !Array.isArray(ptfailedMsg[key]) &&
                                    <div style={{ paddingRgiht: 15, display: "flex", flexDirection: "row", }}>
                                        {ptfailedMsg[key]}
                                    </div>

                                }
                            </div>
                        })
                    }

                    {
                        claimFailedMsg &&
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            {claimFailedMsg}
                        </div>

                    }

                    <hr style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </DialogContent>
                <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
                    <Button className={classes.buttonText} style={{ fontWeight: '500' }} autoFocus color="primary" onClick={() => { setUploadFailed(false); setUpload(true); setFile(''); setClaimFailedMsg(''); setPTFailedMsg([]); }}>
                        Back
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth={"sm"} fullWidth open={uploadSuccess}>

                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ position: 'relative' }}>
                        <span>{"Upload " + fileType + " Success!"}</span>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <div style={{ position: 'relative' }}>
                        <span>{"Upload " + fileType + " Success!"}</span>
                    </div>
                </DialogContent>
                <DialogActions style={{ marginBottom: '30px', marginRight: '20px' }}>
                    <Button className={classes.buttonText} autoFocus onClick={() => { setUploadSuccess(false) }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
}


export default BillingSupplementary;