import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem, TextField, Button,
  Dialog, DialogTitle, DialogActions,
  DialogContent, InputAdornment
} from '@material-ui/core';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import {
  getInfoByTrackingNumber, createNewFPRecord,
} from '../../../actions/faultyPackageActions';
import AddIcon from '@mui/icons-material/Add';
import { showAlertSnackbar } from '../../../reducers/uiSlice';
import {
  FaultyPackageIssueType, FaultyPackageRecordStatus,
  FaultyPackagePackageStatus
} from '../../../constants/general.js';
import dayjs from "dayjs";
import ImageIcon from '@mui/icons-material/Image';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex',
  },
  dropDown: {
    width: '18%',
    height: 56,
    margin: "16px 12px",
  },
  header: {
    display: "flex",
    marginLeft: 30,
  },
  breadCrumbs: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonText: {
    color: "#1976d2",
    width: 53,
    height: 19,
    margin: '2px 0 3px',
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'
    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  helperText: {
    fontSize: 10
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  dialogTextBox: {
    flex: 1,
    margin: "16px 12px",
  },
  iconButton: {
    margin: '16px 0 12px 0',
    color: "#1976d2",
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.26)",
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  framedButton: {
    color: " #1976d2",
    borderRadius: 4,
    border: "solid 1px #1976d2",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 600,
    background: "rgba(0, 0, 0, 0)",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
  },
  uploadImageButton: {
    color: " #1976d2",
    borderRadius: 4,
    border: "solid 1px #1976d2",
    fontSize: "12px",
    textAlign: "center",
    padding: "10px",
    fontWeight: 600,
    background: "rgba(0, 0, 0, 0)",
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
  },
  imageThumbnail: {
    color: "#00000033",
    fontSize: "12px",
  }
}));

const RECORD_INFO = {
  record_date: dayjs().format('YYYY-MM-DD'),
  warehouse: "",
  tracking_number: "",
  sender_reference: "",
  item_number: "",
  issue_type: "",
  uni_notes: "",
  package_status: "",
};

export default function FaultyPackageSearch(props) {
  const {
    refresh, role,
    warehouseDropDown
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newRecordDialog, setCreateNewOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [warehouse, setWarehouse] = useState('');
  const [trackingNo, setTrackingNo] = useState('');
  const [senderRef, setSenderRef] = useState('');
  const [issueType, setIssueType] = useState('');
  const [packageStatus, setPackageStatus] = useState('');
  const [recordStatus, setRecordStatus] = useState('');
  const [fromDate, setFromDate] = useState(dayjs().subtract(14, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [newRecord, setNewRecordInfo] = useState(RECORD_INFO);

  function handleView() {
    let params = {
      "startTime": fromDate,
      "endTime": toDate,
      "warehouse": warehouse,
      "tracking_number": trackingNo,
      "sender_reference": senderRef,
      "issue_type": issueType,
      "package_status": packageStatus,
      "record_status": recordStatus,
    };
    refresh(params);
  }

  async function createNewRecord() {
    try {
      let formData = new FormData();

      Object.keys(newRecord).forEach(key => formData.append(key, newRecord[key]));
      selectedFiles?.forEach(img => {
        formData.append('images[]', img)
      });
      await dispatch(createNewFPRecord(formData)).then((res) => {
        handleView();
        dispatch(showAlertSnackbar({ message: "Record Created", type: 'success' }));
      }).catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })
    } catch (error) {
      dispatch(showAlertSnackbar({ message: "Failed to create new record: " + error, type: 'error' }));
    } finally {
      setCreateNewOpen(false);
      resetnewRecord();
    }
  }

  function getPackageInfo() {
    if (newRecord.tracking_number) {
      dispatch(getInfoByTrackingNumber(newRecord.tracking_number)).then((res) => {
        setNewRecordInfo({
          ...newRecord,
          package_status: res.state,
          warehouse: res.warehouse,
        });
      }).catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        setNewRecordInfo({
          ...newRecord,
          tracking_number: "",
        });
      })
    }
  }

  function resetnewRecord() {
    setNewRecordInfo({
      record_date: dayjs().format('YYYY-MM-DD'),
      warehouse: "",
      tracking_number: "",
      sender_reference: "",
      item_number: "",
      issue_type: "",
      uni_notes: "",
      package_status: "",
    });
    setSelectedFiles(null);
  }

  const handleNewRecordChange = (e) => {
    const { name, value } = e.target;
    setNewRecordInfo({
      ...newRecord,
      [name]: value,
    });
  };

  const handleUploadImages = (e) => {
    if (e.target.files.length <= 5) {
      setSelectedFiles([...e.target.files]);
    } else {
      dispatch(showAlertSnackbar({ type: "error", message: "Max 5 images allowed" }));
    }
  }

  function removeImage(index) {
    let temp = [...selectedFiles];
    temp.splice(index, 1);
    setSelectedFiles(temp);
  }

  // useEffect(() => {
  //   handleView();
  // }, []);

  return (
    <div>

      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Faulty Package Record</p>
        </div>

        {role === 'uni' && (
          <Button
            className={classes.button}
            style={{ marginRight: 36 }}
            startIcon={<AddIcon />}
            onClick={() => setCreateNewOpen(true)}
          >
            NEW RECORD
          </Button>
        )}

      </div>

      <div className={classes.searchBar} style={{ display: 'flex', flexDirection: "column" }}>
        {/* row 1 */}
        <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
          <TextField
            label="Record Time From"
            type="date"
            className={classes.dropDown}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <TextField
            label="Record Time To"
            type="date"
            className={classes.dropDown}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />

          <TextField
            select
            label="Warehouse"
            className={classes.dropDown}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={warehouse ? warehouse : 'Select'}
            onChange={(e) => (e.target.value === 'Select') ? setWarehouse('') : setWarehouse(e.target.value)}
          >
            <MenuItem key="" value="Select">
              {"Select"}
            </MenuItem>
            {Object.entries(warehouseDropDown).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Tracking Number"
            className={classes.dropDown}
            InputLabelProps={{ shrink: true }}
            value={trackingNo}
            onChange={(e) => setTrackingNo(e.target.value)}
            variant="outlined"
          />
          <TextField
            label="Sender Ref."
            className={classes.dropDown}
            InputLabelProps={{ shrink: true }}
            value={senderRef}
            onChange={(e) => setSenderRef(e.target.value)}
            variant="outlined"
          />
        </div>
        {/* row 2 */}
        <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
          <TextField
            select
            label="Issue Type"
            className={classes.dropDown}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={issueType ? issueType : 'Select'}
            onChange={(e) => (e.target.value === 'Select') ? setIssueType('') : setIssueType(e.target.value)}
          >
            <MenuItem key="" value="Select">
              {"Select"}
            </MenuItem>
            {Object.entries(FaultyPackageIssueType).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Package Status"
            className={classes.dropDown}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={packageStatus ? packageStatus : 'Select'}
            onChange={(e) => (e.target.value === 'Select') ? setPackageStatus('') : setPackageStatus(e.target.value)}
          >
            <MenuItem key="" value="Select">
              {"Select"}
            </MenuItem>
            {Object.entries(FaultyPackagePackageStatus).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Record Status"
            className={classes.dropDown}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={recordStatus ? recordStatus : 'Select'}
            onChange={(e) => (e.target.value === 'Select') ? setRecordStatus('') : setRecordStatus(e.target.value)}
          >
            <MenuItem key="" value="Select">
              {"Select"}
            </MenuItem>
            {Object.entries(FaultyPackageRecordStatus).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.iconButton}>
            <IconButton
              id={'local-delivery-search'}
              size={'large'}
              color={'primary'}
              onClick={handleView}
            >
              <SearchIcon fontSize={'inherit'} />
            </IconButton>

          </div>
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" classes={{ paper: classes.dialogPaper }} open={newRecordDialog} onClose={() => setCreateNewOpen(false)}>
        <DialogTitle>Faulty Package Record</DialogTitle>
        <DialogContent style={{ height: 200 }}>
          <div style={{ display: 'flex', flexDirection: "column" }}>
            {/* row 1 */}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
              <TextField
                label="Record Date"
                type="date"
                className={classes.dialogTextBox}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="record_date"
                value={newRecord.record_date}
                onChange={handleNewRecordChange}
                required
              />
              <TextField
                select
                name='warehouse'
                label="Warehouse"
                className={classes.dialogTextBox}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={newRecord.warehouse}
                onChange={handleNewRecordChange}
                required
              >
                {Object.entries(warehouseDropDown).map(([k, v]) => (
                  <MenuItem key={k} value={k}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>

            </div>
            {/* row 2 */}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
              <TextField
                label="Tracking No."
                name="tracking_number"
                className={classes.dialogTextBox}
                variant="outlined"
                value={newRecord.tracking_number}
                onChange={handleNewRecordChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={getPackageInfo} edge="end">
                        <SearchIcon className={classes.iconButton} />
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </div>
            {/* row 3 */}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
              <TextField
                label="Sender Ref"
                name="sender_reference"
                className={classes.dialogTextBox}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={newRecord.sender_reference}
                onChange={handleNewRecordChange}
              />
            </div>
            {/* row 4 */}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
              <TextField
                label="Item No."
                name="item_number"
                className={classes.dialogTextBox}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={newRecord.item_number}
                onChange={handleNewRecordChange}
              />
            </div>
            {/* row 5 */}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
              <TextField
                select
                label="Issue Type"
                name="issue_type"
                className={classes.dialogTextBox}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={newRecord.issue_type}
                onChange={handleNewRecordChange}
                required
              >
                {Object.entries(FaultyPackageIssueType).map(([k, v]) => (
                  <MenuItem key={k} value={k}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {/* row 6*/}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch', marginLeft: '10px' }}>
              <label className={classes.uploadImageButton} for="uploadImages">
                UPLOAD IMAGE
              </label>
              <p style={{ color: " #1976d2", fontSize: "10px", paddingLeft: "10px" }}>*Accept only .png .jpeg. Max 5 images</p>
              <input
                type="file"
                multiple
                accept="image/*"
                hidden
                onChange={handleUploadImages}
                id="uploadImages"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'stretch', margin: '5px 10px' }}>
              {
                selectedFiles?.map((img, index) => (
                  <div className={classes.imageThumbnail} onClick={() => removeImage(index)}>
                    <ImageIcon fontSize='inherit' style={{ marginRight: '2px' }} />
                    {img.name}
                    <CancelIcon fontSize='inherit' style={{ marginLeft: '5px' }} />
                  </div>

                ))
              }
            </div>
            {/* row 7 */}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
              <TextField
                label="Notes"
                name="uni_notes"
                multiline
                minRows={4}
                className={classes.dialogTextBox}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={newRecord.uni_notes}
                onChange={handleNewRecordChange}
              />
            </div>
            {/* row 8 */}
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'stretch' }}>
              <div className={classes.dialogTextBox}>{"Current Package Status: " + FaultyPackagePackageStatus[newRecord.package_status]}</div>
            </div>

          </div>
        </DialogContent>
        <DialogActions style={{ marginBottom: 24 }}>
          <Button autoFocus className={classes.buttonText} style={{ marginRight: 24 }} onClick={() => { resetnewRecord(); setCreateNewOpen(false) }}>Cancel</Button>
          <Button
            variant='contained'
            onClick={createNewRecord}
            className={classes.buttonSystem}
            style={{ marginRight: 24 }}
          >
            create
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
}