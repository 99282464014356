import React, {useEffect, useState, useCallback} from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import PrmFrame from "../../components/PrmFrame";
import FullscreenLoading from "../../components/FullscreenLoading";
import FaultyPackageSearch from '../../components/FaultyPackage/FaultyPackageSearch';
import FaultyPackageDisplay from '../../components/FaultyPackage/FaultyPackageDisplay';
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";
import {getBranchList} from "../../actions/commonActions";
import {searchFPRecords,getRecordStatistics} from "../../actions/faultyPackageActions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const FAULTY_PACKAGE_ACCESS_ID = 10;

function FaultyPackageMain() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);
  const [records, setRecords] = useState([]);
  const [warehouseDropDown, setWarehouseDropDown] = useState([]);
  const [statistics, setStats] = useState([]);
  const role = "uni"

  const getAllDropDowns = useCallback(async () => {
    try {
      setLoading(true);
      const res = await dispatch(getBranchList());
      const warehouseMap = res.reduce((map, warehouse) => {
        if(warehouse.id !== 12 && warehouse.id !== 13 && warehouse.id !== 27 && warehouse.id !== 28){
          map[warehouse.id] = warehouse.id+". "+warehouse.name;
        }
        return map;
      }, {});
      setWarehouseDropDown(warehouseMap);
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const fetchFaultyPackages = useCallback(async (params) => {
    try {
      setLoading(true);
      const res = await dispatch(searchFPRecords(params));
      const stats = await dispatch(getRecordStatistics());
      setRecords(res);
      setStats(stats);
    } catch (err) {
      setRecords([]);
      setStats([]);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getAllDropDowns();
    fetchFaultyPackages(null);
  }, [getAllDropDowns, fetchFaultyPackages])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={FAULTY_PACKAGE_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
      <PrmMain
        className={classes.content}
        open={open}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <FaultyPackageSearch
            warehouseDropDown={warehouseDropDown}
            refresh={fetchFaultyPackages}
            role={role}
          />
          <FaultyPackageDisplay
            records={records}
            stats = {statistics}
            role={role}
            warehouseDropDown={warehouseDropDown}
            refresh={fetchFaultyPackages}
            setloading={setLoading}
            
          />
        </div>
        <FullscreenLoading open={loading}/>
      </PrmMain>
    </div>
  );
}

export default FaultyPackageMain;