import {combineReducers} from 'redux';

import uiSlice from './reducers/uiSlice';
import userReducer from './reducers/userReducer';
import commonReducer from './reducers/commonReducer';
import storeReducer from './reducers/storeReducer';
import servicePointReducer from './reducers/servicePointReducer';
import sandboxTestReducer from './reducers/sandboxTestReducer';
import t4aReducer from './reducers/t4aReducer';
import prealertReducer from './reducers/prealertReducer';
import managementReducer from "./reducers/managementReducer";

const rootReducer = combineReducers({
    user: userReducer,
    common: commonReducer,
    store: storeReducer,
    ui: uiSlice,
    servicePoint: servicePointReducer,
    sandboxTest: sandboxTestReducer,
    t4a: t4aReducer,
    prealertReducer: prealertReducer,
    managementReducer: managementReducer
});

export default rootReducer;
